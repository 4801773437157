import * as yup from "yup";

export const LoginValidate = yup.object().shape({
    username: yup.string().trim().required("Email is required."),
    password: yup.string().trim().required("Password is required.")
});

export const SignUpValidate = yup.object().shape({
    email: yup.string().trim().required("Email is required."),
    password: yup.string().trim().required("Password is required.").min(8, "Minimum password length must be 8 characters"),
    confirmPassword: yup.string().trim().required("Confirm password is required.").
        oneOf([yup.ref('password'), null], 'Passwords must match'),
    name: yup.string().trim().required("Name is required."),
    lastname: yup.string().trim().required("Lastname is required."),
    birthdate: yup.string().trim().required("Birthdate is required.")
});

export const CreateMainAccountValidate = yup.object().shape({
    password: yup.string().trim().required("Password is required."),
    confirmPassword: yup.string().trim().required("Confirm password is required.").
        oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const AddAccountMemberValidate = yup.object().shape({
    email: yup.string().trim().required("Email is required."),
    password: yup.string().trim().required("Password is required."),
    confirmPassword: yup.string().trim().required("Confirm password is required.").
        oneOf([yup.ref('password'), null], 'Passwords must match'),
    name: yup.string().trim().required("Name is required."),
    lastname: yup.string().trim().required("Lastname is required."),
    type: yup.number().integer().min(1, "Member type is required.").required("Member type is required.")
});

export const UpdateAccountMemberValidate = yup.object().shape({
    email: yup.string().trim().required("Email is required."),
    password: yup.string().trim(),
    confirmPassword: yup.string().trim().oneOf([yup.ref('password'), null], 'Passwords must match'),
    name: yup.string().trim().required("Name is required."),
    lastname: yup.string().trim().required("Lastname is required."),
    type: yup.number().integer().min(1, "Member type is required.").required("Member type is required.")
});

export const CompleteAccountInfo = yup.object().shape({
    // email: yup.string().trim().required("Email is required."),
    password: yup.string().trim().required("Password is required.").
        matches(/^\d+$/, 'The password should have digits only'),
    confirmPassword: yup.string().trim().required("Confirm password is required.").
        matches(/^\d+$/, 'The password should have digits only').
        oneOf([yup.ref('password'), null], 'Passwords must match'),
    name: yup.string().trim().required("Name is required."),
    lastname: yup.string().trim().required("Lastname is required."),
    birthdate: yup.string().trim().required("Birthdate is required.")
});

export const CompleteBusinessInfo = yup.object().shape({
    name: yup.string().trim().required("Name is required."),
    address: yup.string().trim().required("Address is required."),
    phone: yup.number().integer().min(1, "Phone number is required.").required("Phone number is required."),
    postalCode: yup.string().trim().required("Postal code is required."),
    type: yup.number().integer().min(1, "Business type is required.").required("Business type is required."),
});


export const CreateCustomCategoryValidate = yup.object().shape({
    name: yup.string().trim().required("Name is required."),
});

export const CreateCustomProductValidate = yup.object().shape({
    name: yup.string().trim().required("Name is required."),
});

export const CreateCustomProductVariantValidate = yup.object().shape({
    name: yup.string().trim().required("Name is required."),
});

export const CreateCustomToppingValidate = yup.object().shape({
    name: yup.string().trim().required("Name is required."),
});

export const CreateCustomPromotionValidate = yup.object().shape({
    name: yup.string().trim().required("Name is required."),
    promotionType: yup.number().integer().min(1, "Promotion type is required.").required("Promotion type is required."),
    starts: yup.string().trim().required("Starts date is required."),
    ends: yup.string().trim().required("Ends date is required."),
    daysOfTheWeek: yup.array().min(1, "Select days of the week."),
    hoursOfTheDay: yup.array().min(1, "Select hours of the day."),
    toApplyId: yup.number().integer().min(1, "Apply to type is required.").required("Apply to type is required."),
});

export const EditCustomPromotionValidate = yup.object().shape({
    name: yup.string().trim().required("Name is required."),
    promotionType: yup.number().integer().min(1, "Promotion type is required.").required("Promotion type is required."),
    starts: yup.string().trim().required("Starts date is required."),
    ends: yup.string().trim().required("Ends date is required."),
    daysOfTheWeek: yup.array().min(1, "Select days of the week."),
    hoursOfTheDay: yup.array().min(1, "Select hours of the day."),
    toApplyId: yup.number().integer().min(1, "Apply to type is required.").required("Apply to type is required."),
});

export const UpdateAccounInfoValidate = yup.object().shape({
    name: yup.string().trim().required("Name is required."),
    lastname: yup.string().trim().required("Lastname is required."),
    birthdate: yup.string().trim().required("Birthdate is required.")
});

export const AddMainerAccountFormValidate = yup.object().shape({
    password: yup.string().trim().required("Password is required.").
        matches(/^\d+$/, 'The password should have digits only'),
    confirmPassword: yup.string().trim().required("Confirm password is required.").
        matches(/^\d+$/, 'The password should have digits only').
        oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export const ResetPasswordValidate = yup.object().shape({
    email: yup.string().trim().required("Email is required."),
    password: yup.string().trim().required("Password is required.").min(8, "Minimum password length must be 8 characters"),
    confirmPassword: yup.string().trim().required("Confirm password is required.").
        oneOf([yup.ref('password'), null], 'Passwords must match'),
    confrimationCode: yup.string().trim().required("Confrimation code is required."),
});