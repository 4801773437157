import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Divider, FormControl, Grid, MenuItem, Modal, Pagination, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { accountMember } from "../../api/accountMember";
import { MemberModel } from "../../api/models/AccountMember.Model";
import { CardComponent, HeaderComponent } from "../../components";
import { AuthValidate, getAccessToken, SignOut } from "../../utils/authHelper";
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import { useNotification } from "../../context/notification.context";
import { AddAccountMemberValidate, UpdateAccountMemberValidate } from "../../utils/formValidation";
import { ModalComponent } from "../../components/Modal";
import { ConfirmCancelDialogComponent, ConfirmCancelDialogProps } from "../../components/ConfirmCancelDialog";
import { useNavigate } from "react-router-dom";

interface AccountMemberProp {

}

interface MemeberDataInterface {
    email: string,
    password: string,
    confirmPassword: string,
    name: string,
    lastname: string,
    type: number
}

interface AccounMembersPaginatedProps {
    accountMembersCount: number;
    currentPage: number;
    itemsQuantity: number;
    pageCount: number;
    accountMembers: MemberModel[];
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AccountMember: React.FC<AccountMemberProp> = () => {
    const navigate = useNavigate();
    const { getError, getSuccess } = useNotification();
    const [members, setMembers] = React.useState<MemberModel[] | null>(null);
    const [addMemberModaIsOpen, setAddMemberModaIsOpen] = React.useState<boolean>(false);
    const [updateMemberModaIsOpen, setUpdateMemberModaIsOpen] = React.useState<boolean>(false);
    const [memberData, setMemberData] = React.useState<MemeberDataInterface>({
        email: "",
        password: "",
        confirmPassword: "",
        name: "",
        lastname: "",
        type: 3
    });
    const [memberType, setMemberType] = React.useState<string>('');
    const [selectedMember, setSelectedMember] = React.useState<MemberModel | undefined>(undefined);
    const [paginationProps, setPaginationProps] = React.useState<AccounMembersPaginatedProps>({
        accountMembers: [],
        currentPage: 1,
        itemsQuantity: 8,
        pageCount: 0,
        accountMembersCount: 0
    });
    //ConfirmCancelDialog
    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = React.useState<boolean>(false);
    const [confirmCancelDialogProps, setconfirmCancelDialogProps] = React.useState<ConfirmCancelDialogProps>(
        {
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        }
    );

    React.useEffect(
        AuthValidate() ? () => {
            RefreshAccountMemberList();
        } : () => {
            SignOut();
            navigate("/");
        }, []);

    const RefreshAccountMemberList = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        accountMember.getByAccountIdPaginated({
            token: `Bearer ${token}`,
            data: {
                currentPage: paginationProps.currentPage,
                itemsQuantity: paginationProps.itemsQuantity
            }
        }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    const accounMemberspaginated: AccounMembersPaginatedProps = response.data.data;
                    setMembers(accounMemberspaginated.accountMembers);
                    paginationProps.pageCount = accounMemberspaginated.pageCount;
                }

            }).
            catch((error) => {

            });
    };

    const openAddMemberModal = () => {
        setAddMemberModaIsOpen(true);
    };

    const closeAddMemberModal = () => {
        setAddMemberModaIsOpen(false);
        setMemberData({
            email: "",
            password: "",
            confirmPassword: "",
            name: "",
            lastname: "",
            type: 3
        });
        setMemberType("");
    };

    const updateMemberData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMemberData({ ...memberData, [e.target.name]: e.target.value });
    };

    const handleMemberTypeChange = (event: SelectChangeEvent) => {
        setMemberType(event.target.value);
    };

    const handleAddMemberFormSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            memberData.type = memberType === "" ? 0 : parseInt(memberType);
            AddAccountMemberValidate.validate(memberData).
                then(() => {
                    accountMember.add({
                        token: `Bearer ${token}`,
                        data: {
                            username: memberData.email,
                            name: memberData.name,
                            lastname: memberData.lastname,
                            email: memberData.email,
                            type: memberData.type,
                            password: memberData.password
                        }
                    }).
                        then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Account Created successfully.");
                                closeAddMemberModal();
                                RefreshAccountMemberList();
                            }
                            else {

                                getError(`Failed to create account. Please try again or contact support. ${response.data.message}`);
                            }
                        }).
                        catch((error) => {

                            getError("Failed to create account. Please try again or contact support.");
                        });

                }).
                catch((error) => {
                    getError("Failed to create account. Please try again or contact support.");
                    getError(error.message);
                });

        }
        catch (error) {
            getError("Failed to create account. Please try again or contact support.");

        }
    };

    const openUpdateMemberModal = () => {
        setUpdateMemberModaIsOpen(true);
    };

    const closeUpdateMemberModal = () => {
        setMemberData({
            email: "",
            password: "",
            confirmPassword: "",
            name: "",
            lastname: "",
            type: 3
        });
        setMemberType("");
        setSelectedMember(undefined);
        setUpdateMemberModaIsOpen(false);
    };

    const selectMemberToUpdate = (member: MemberModel) => {
        setSelectedMember(member);
        setMemberData({
            email: member.email,
            password: "",
            confirmPassword: "",
            name: member.name,
            lastname: member.lastname,
            type: member.type
        });
        setMemberType(member.type.toString());
        openUpdateMemberModal();
    };

    const handleUpdateMemberFormSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            memberData.type = memberType === "" ? 0 : parseInt(memberType);
            UpdateAccountMemberValidate.validate(memberData).
                then(() => {
                    if (selectedMember == undefined) {
                        getError("Failed to update account. Please try again or contact support.");
                        throw new Error("");
                    }

                    accountMember.update({
                        token: `Bearer ${token}`,
                        data: {
                            id: selectedMember.id,
                            username: memberData.email,
                            name: memberData.name,
                            lastname: memberData.lastname,
                            email: memberData.email,
                            type: memberData.type,
                            password: memberData.password
                        }
                    }).
                        then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Account updated successfully.");
                                closeUpdateMemberModal();
                                RefreshAccountMemberList();
                            }
                            else {

                                getError("Failed to update account. Please try again or contact support.");
                            }
                        }).
                        catch((error) => {
                            getError("Failed to update account. Please try again or contact support.");
                        });

                }).
                catch((error) => {
                    getError("Failed to update account. Please try again or contact support.");
                    getError(error.message);
                });

        }
        catch (error) {
            getError("Failed to update account. Please try again or contact support.");
        }
    };

    const onPaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        paginationProps.currentPage = page;
        RefreshAccountMemberList();
    };

    const DeleteAccountButtonOnClick = () => {
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete account member";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that product?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteAccountMember(); };
    };

    const DeleteAccountMember = async () => {
        if (selectedMember?.id === undefined) {
            getError("Failed to delete account. Please try again or contact support.");
            return;
        }

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        accountMember.delete({
            token: `Bearer ${token}`,
            id: selectedMember.id
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Account deleted successfully.");
                    CloseConfirmCancelDialog();
                    closeUpdateMemberModal();
                    RefreshAccountMemberList();
                }
                else {
                    getError("Failed to delete account. Please try again or contact support.");
                }
            })
            .catch((error) => {
                getError("Failed to delete account. Please try again or contact support.");
            });

    };

    const CloseConfirmCancelDialog = () => {
        setconfirmCancelDialogProps({
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        });

        setShowConfirmCancelDialog(false);
    };

    return (
        <Container>
            <ConfirmCancelDialogComponent
                IsOpen={showConfirmCancelDialog}
                Title={confirmCancelDialogProps.Title}
                MessageDialog={confirmCancelDialogProps.MessageDialog}
                CloseDialog={confirmCancelDialogProps.CloseDialog}
                ConfirmOption={confirmCancelDialogProps.ConfirmOption}
                CancelOption={confirmCancelDialogProps.CancelOption}
            />

            <HeaderComponent
                title='Account members'
                description=''
            />

            <ModalComponent
                Title="New account member"
                IsOpen={addMemberModaIsOpen}
                MaxWidth="md"
                CloseModal={() => { closeAddMemberModal(); }}
                ModalContent={
                    <Box component="form" onSubmit={handleAddMemberFormSubmit}>
                        <Grid container>
                            <Grid item md={12}>
                                <TextField
                                    margin='normal'
                                    name='name'
                                    type="text"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Name"
                                    sx={{ mt: 2, mb: 1.5 }} />
                                <TextField
                                    margin='normal'
                                    name='lastname'
                                    type="text"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Lastname"
                                    sx={{ mt: 2, mb: 1.5 }} />
                                <TextField
                                    margin='normal'
                                    name='email'
                                    type="text"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Email"
                                    sx={{ mt: 2, mb: 1.5 }} />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Member type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={memberType.toString()}
                                        onChange={handleMemberTypeChange}
                                        label="Member type"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={2}>Manager</MenuItem>
                                        <MenuItem value={3}>Cashier</MenuItem>
                                    </Select>
                                </FormControl>

                                <TextField
                                    margin='normal'
                                    name='password'
                                    type="password"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Password"
                                    sx={{ mt: 1.5, mb: 1.5 }} />
                                <TextField
                                    margin='normal'
                                    name='confirmPassword'
                                    type="password"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Confirm password"
                                    sx={{ mt: 1.5, mb: 1.5 }} />
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                    sx={{ mt: 1.5, mb: 3 }}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />

            <ModalComponent
                Title="Update account member"
                IsOpen={updateMemberModaIsOpen}
                MaxWidth={"md"}
                CloseModal={() => { closeUpdateMemberModal(); }}
                ModalContent={
                    <Box component="form" onSubmit={handleUpdateMemberFormSubmit}>
                        <Grid container>
                            <Grid item md={12}>
                                <TextField
                                    InputProps={memberData.type === 1 ? { readOnly: true, } : {}}
                                    value={memberData.name}
                                    margin='normal'
                                    name='name'
                                    type="text"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Name"
                                    sx={{ mt: 2, mb: 1.5 }} />
                                <TextField
                                    InputProps={memberData.type === 1 ? { readOnly: true, } : {}}
                                    value={memberData.lastname}
                                    margin='normal'
                                    name='lastname'
                                    type="text"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Lastname"
                                    sx={{ mt: 2, mb: 1.5 }} />
                                <TextField
                                    InputProps={memberData.type === 1 ? { readOnly: true, } : {}}
                                    value={memberData.email}
                                    margin='normal'
                                    name='email'
                                    type="text"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Email"
                                    sx={{ mt: 2, mb: 1.5 }} />
                                {
                                    memberData.type === 1 ?
                                        <>
                                            <TextField
                                                InputProps={{ readOnly: true, }}
                                                value={"Account owner"}
                                                margin='normal'
                                                type="text"
                                                fullWidth
                                                label="Member type"
                                                sx={{ mt: 2, mb: 1.5 }} />
                                        </> :
                                        <>
                                            <FormControl fullWidth sx={{ mt: 2, mb: 1.5 }}>
                                                <InputLabel id="demo-simple-select-standard-label">Member type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={memberType.toString()}
                                                    onChange={handleMemberTypeChange}
                                                    label="Member type"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={2}>Manager</MenuItem>
                                                    <MenuItem value={3}>Cashier</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </>
                                }

                                <TextField
                                    margin='normal'
                                    name='password'
                                    type="password"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Password"
                                    sx={{ mt: 1.5, mb: 1.5 }} />
                                <TextField
                                    margin='normal'
                                    name='confirmPassword'
                                    type="password"
                                    onChange={updateMemberData}
                                    fullWidth
                                    label="Confirm password"
                                    sx={{ mt: 1.5, mb: 1.5 }} />
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                    sx={{ mt: 1.5 }}
                                >
                                    Update
                                </Button>

                                {
                                    memberData.type !== 1 ?
                                        <>
                                            <Button
                                                fullWidth
                                                variant='outlined'
                                                sx={{ mt: 1.5, }}
                                                onClick={DeleteAccountButtonOnClick}
                                            >
                                                Delete
                                            </Button>
                                        </>
                                        :
                                        <></>
                                }
                            </Grid>
                        </Grid>
                    </Box>

                }
            />

            <Grid sx={{ mb: 1 }} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                </Grid>
                <Grid item>
                    <Stack spacing={1} direction="row">
                        <Button onClick={openAddMemberModal}><AddIcon /> New</Button>
                    </Stack>
                </Grid>
            </Grid>

            {
                members?.length !== 0 ? (
                    <>
                        <Grid container spacing={2} direction="row" alignItems="stretch">
                            {
                                members?.map((member) => (
                                    <Grid key={member.id} item xs={12} sm={6} md={3} sx={{ display: "flex" }}>
                                        <Card sx={
                                            member.type === 1 ? {
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                borderStyle: "solid",
                                                borderWidth: "thin",
                                                borderColor: "#8E64FF",
                                            } : {
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                            }
                                        }>
                                            <CardMedia
                                                component="img"
                                                height={"190px"}
                                                width={"180px"}
                                                image={"https://rickandmortyapi.com/api/character/avatar/1.jpeg"}
                                                alt=""
                                            />

                                            <CardContent sx={{ height: "100%" }}>
                                                <Typography sx={{ mt: 1.5 }} variant="h4">{member.name}</Typography>
                                                <Divider />
                                                {
                                                    member.type === 1 ?
                                                        <Typography>account owner</Typography> :
                                                        <><Typography>{member.typeName}</Typography></>
                                                }

                                            </CardContent>

                                            <CardActions>
                                                <Button
                                                    onClick={() => { selectMemberToUpdate(member) }}
                                                    fullWidth
                                                    variant="contained"
                                                    size="small">
                                                    Info
                                                </Button>
                                            </CardActions>

                                        </Card>


                                    </Grid>
                                ))
                            }
                        </Grid>
                        <Grid
                            container
                            direction={"column"}
                            alignItems="center"
                            justifyContent={"center"}
                        >
                            <Stack sx={{ mt: 3, mb: 3 }} spacing={2}>
                                <Pagination onChange={onPaginationChange} count={paginationProps.pageCount} shape="rounded" />
                            </Stack>
                        </Grid>

                    </>
                ) :
                    <></>
            }

        </Container>
    );
};

export default AccountMember;