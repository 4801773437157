import { AppBar, Box, Button, Container, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthValidate, SignOut } from '../utils/authHelper';
import MenuIcon from '@mui/icons-material/Menu';
import { themePalette } from '../config/theme.config';

const drawerWidth = 240;

export const NavBar: React.FC<{}> = () => {
    const navigate = useNavigate();

    const handleSignOutClick = () => {
        SignOut();
        navigate("/");
    };

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            {/* <Typography
                fontSize={18}
                component="a"
                href="/"
                sx={{
                    letterSpacing: '.3rem',
                    textDecoration: 'none',
                }}     >
                Cento
            </Typography> */}

            <Grid sx={{ backgroundColor: themePalette.CentoColor, pt: 1 }}>
                <Typography component="a" href="/">
                    <img src="/cento-logo.png" alt="cento" />
                </Typography>
            </Grid>

            <List>
                {
                    AuthValidate() ?
                        <>
                            <ListItem disablePadding>
                                <Button fullWidth onClick={() => navigate("Dashboard")}>Dashboard</Button>
                            </ListItem>

                            <ListItem disablePadding>
                                <Button fullWidth onClick={() => navigate("Device")}>Device</Button>
                            </ListItem>

                            <ListItem disablePadding>
                                <Button fullWidth onClick={() => navigate("AccountMember")}>Members</Button>
                            </ListItem>

                            <ListItem disablePadding>
                                <Button fullWidth onClick={() => navigate("Menu")}>Menus</Button>
                            </ListItem>

                            <ListItem disablePadding>
                                <Button fullWidth onClick={() => navigate("Account")}>Account</Button>
                            </ListItem>
                            <ListItem>
                                <Button fullWidth onClick={handleSignOutClick}>Sign Out</Button>
                            </ListItem>

                        </>
                        :
                        <Button fullWidth onClick={() => navigate("SignIn")}>Sign In</Button>
                }

            </List>

        </Box>
    );

    const container = undefined;

    return (
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <AppBar component="nav">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Grid container direction={"row"} justifyContent="flex-end">
                        <Grid item xs={1}>
                            <Typography component="a" href="/">
                                <img src="/cento-logo.png" alt="cento" />
                            </Typography>

                        </Grid>
                        <Grid item xs={11}>
                            <Grid container direction={"row"} justifyContent="flex-end">
                                <Box sx={{ display: { xs: 'none', sm: 'block', color: "#F4F6F5" } }}>
                                    <Stack spacing={1} direction="row">
                                        {
                                            AuthValidate() ?
                                                <>
                                                    <Button sx={{ color: "#F4F6F5" }} variant='text' onClick={() => navigate("Dashboard")}>Dashboard</Button>
                                                    <Button sx={{ color: "#F4F6F5" }} onClick={() => navigate("Device")}>Device</Button>
                                                    <Button sx={{ color: "#F4F6F5" }} onClick={() => navigate("AccountMember")}>Members</Button>
                                                    <Button sx={{ color: "#F4F6F5" }} onClick={() => navigate("Menu")}>Menus</Button>
                                                    <Button sx={{ color: "#F4F6F5" }} onClick={() => navigate("Account")}>Account</Button>
                                                    <Button sx={{ color: "#F4F6F5" }} onClick={handleSignOutClick}>Sign Out</Button>
                                                </>
                                                :
                                                <Button sx={{ color: "#F4F6F5" }} variant="outlined" onClick={() => navigate("SignIn")}>Sign In</Button>
                                        }

                                    </Stack>


                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>


                    {/* <Typography
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            letterSpacing: '.3rem',
                            color: "white",
                            textDecoration: 'none',
                            flexGrow: 1, display: { xs: 'none', sm: 'block' },
                        }}
                    >
                        Cento
                    </Typography> */}



                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{ p: 2 }}>
                <Toolbar />

            </Box>
        </Box>

    )
}