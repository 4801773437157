import { Autocomplete, Box, Button, Card, CardActions, CardContent, Container, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import React from 'react';
import { customProducts } from '../../api/customProducts';
import { defaultProducts } from '../../api/defaultProducts';
import { CategoryModel, MenuModel, ProductModel, TaxModel } from '../../api/models/Menu.Model';
import { getAccessToken } from '../../utils/authHelper';
import AddIcon from '@mui/icons-material/Add';
import { ModalComponent } from '../../components/Modal';
import { useNotification } from '../../context/notification.context';
import { CreateCustomProductValidate } from '../../utils/formValidation';
import { defaultCategories } from '../../api/defaultCategories';
import { customCategories } from '../../api/customCategories';
import { defaultTaxes } from '../../api/defaultTaxes';
import { DefaultTaxModel } from '../../types/defaultTax.Model';
import { userMenu } from '../../api/userMenu';
import { ConfirmCancelDialogComponent, ConfirmCancelDialogProps } from '../../components/ConfirmCancelDialog';

interface ProductTabProps {
    menu: MenuModel | null
}

interface ProductData {
    name: string;
    price: number;
    description: string;
}

export const ProductTab: React.FC<ProductTabProps> = ({ menu }) => {
    const { getError, getSuccess } = useNotification();
    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = React.useState<boolean>(false);
    const [confirmCancelDialogProps, setconfirmCancelDialogProps] = React.useState<ConfirmCancelDialogProps>(
        {
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        }
    );
    const [selectedProduct, setSelectedProduct] = React.useState<ProductModel | undefined>(undefined);
    const [selectedTaxes, setSelectedTaxes] = React.useState<TaxModel[]>([]);
    const [products, setProducts] = React.useState<ProductModel[] | null>([]);
    const [categories, setCategories] = React.useState<CategoryModel[] | null>(null);
    const [taxes, setTaxes] = React.useState<DefaultTaxModel[] | null>(null);
    const [showCreateProductModal, setShowCreateProductModal] = React.useState<boolean>(false);
    const [showAddProductToMenuModal, setShowAddProductToMenuModal] = React.useState<boolean>(false);
    const [showAddTaxToProductModal, setShowAddTaxToProductModal] = React.useState<boolean>(false);
    const [productData, setProductData] = React.useState<ProductData>(
        {
            name: "",
            price: 0,
            description: ""
        });
    const [selectedCategoy, setSelectedCategory] = React.useState<string>('');

    React.useEffect(() => {
        GetProducts();
        GetCategories();
    }, []);

    const GetProducts = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        let productsResponse: ProductModel[] | null = [];

        await defaultProducts.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (productsResponse !== null) {
                    productsResponse = [...productsResponse, ...response.data.data]
                }
                // setProducts(response.data.data);
            }).
            catch((error) => {
                console.log(error);
            });

        await customProducts.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (productsResponse !== null) {
                    productsResponse = [...productsResponse, ...response.data.data]
                }

            }).
            catch((error) => {
                console.log(error);
            });

        setProducts(productsResponse);
    };

    const GetCategories = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        let categoriesResponse: CategoryModel[] | null = [];

        await defaultCategories.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (categoriesResponse !== null) {
                    categoriesResponse = [...categoriesResponse, ...response.data.data]
                }
            }).
            catch((error) => {
                console.log(error);
            });

        await customCategories.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (categoriesResponse !== null)
                    categoriesResponse = [...categoriesResponse, ...response.data.data]

            }).
            catch((error) => {
                console.log(error);
            });

        setCategories(categoriesResponse);
    };

    const GetTaxes = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        defaultTaxes.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                setTaxes(response.data.data);
            }).
            catch((error) => {
                console.log(error);
            });
    };

    const CreateProductModalOnClose = () => {
        setShowCreateProductModal(false);
    };

    const CreateProductFormOnSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            CreateCustomProductValidate.validate(productData).
                then(async () => {
                    const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                    customProducts.Add({
                        token: `Bearer ${token}`,
                        data: {
                            productName: productData.name.trim()
                        }
                    }).
                        then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Product created successfully.");
                                GetProducts();
                                CreateProductModalOnClose();
                            }
                            else {
                                getError("Failed to create product. Please try again or contact support.");
                            }
                        }).
                        catch((error) => {
                            getError("Failed to create product. Please try again or contact support.");
                        });
                }).
                catch((error) => {
                    getError(error.message);
                });

        }
        catch (error) {
            getError("Failed to create product. Please try again or contact support.");
        }
    };

    const ProductDataOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProductData({ ...productData, [e.target.name]: e.target.value });
    };

    const AddProductToMenuFormOnSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (selectedProduct === undefined || menu === null) {
            getError("Please refresh and try again");
            return;
        }

        if (selectedCategoy === '') {
            getError("Category is required");
            return;
        }

        if (productData.price < 1) {
            getError("Price is required");
            return;
        }

        const productToAdd: ProductModel = {
            id: '',
            cloudId: selectedProduct.id,
            productName: selectedProduct.productName,
            productDefaultId: selectedProduct.id,
            description: productData.description,
            productPrice: productData.price,
            taxes: selectedTaxes,
            variants: [],
            promotions: [],
            isCustom: selectedProduct.isCustom
        };

        getSuccess(JSON.stringify(productToAdd));

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        //VALIDATE DUPLICATE
        //VALIDATE CATEGORY EXIST IN MENU
        userMenu.AddProductToCategory({
            token: `Bearer ${token}`,
            data: {
                menuId: menu.id,
                categoryId: selectedCategoy,
                product: productToAdd
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Product added successfully.");
                    AddProductToModalOnClose();
                }
                else {
                    getError(`Failed to add product to menu. Please try again or contact support.`);
                }

            })
            .catch((error) => {
                getError(`Failed to add product to menu. Please try again or contact support.`);
            });
    };

    // const ValidateCategoryExistInMenu = () => {
    //     menu?.categories.find(c => c. === selectedCategoy);
    // };

    // const ValidateProductDuplicate = () => {

    // };

    const AddProductToModalOnClose = () => {
        setSelectedTaxes([]);
        setSelectedCategory('');
        setSelectedProduct(undefined);
        setProductData({
            name: "",
            price: 0,
            description: ""
        });
        setShowAddProductToMenuModal(false);
    };

    const AddProductToMenuButtonOnClick = (product: ProductModel) => {
        setSelectedProduct(product);
        setShowAddProductToMenuModal(true);
    };

    const AddTaxToProductModalOpen = () => {
        // GetTaxes();
        setShowAddTaxToProductModal(true);
    };

    const AddTaxToProductModalOnClose = () => {
        setShowAddTaxToProductModal(false);
    };

    const AddTaxToProductButtonOnClick = (tax: DefaultTaxModel) => {
        const taxToAdd: TaxModel = {
            taxDefaultId: tax.id,
            cloudId: tax.id,
            taxName: tax.taxName,
            taxValue: tax.taxValue,
            isCustom: tax.isCustom
        };

        let taxFound = selectedTaxes?.find(st => st.taxDefaultId === tax.id);

        if (taxFound === undefined) {
            setSelectedTaxes((value) => {
                if (value !== null) {
                    return [...value, taxToAdd];
                }

                return [{ ...taxToAdd }];
            });
        }
        else {
            getError("Duplicate Tax");
        }

    };

    const CategorySelectOnChange = (e: SelectChangeEvent) => {
        setSelectedCategory(e.target.value);
    };

    const DeleteProductButtonOnClick = (product: ProductModel) => {
        if (!product.isCustom) {
            getError("This product can't be deleted");
            return;
        }

        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete category";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that category?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteProduct(product.id) };

    };

    const DeleteProduct = async (productId: string) => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        customProducts.Delete({
            token: `Bearer ${token}`,
            id: productId
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Product deleted successfully.");
                    GetProducts();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete product. Please try again or contact support.");
                }
            })
            .catch((error) => {
                getError("Failed to delete product. Please try again or contact support.");
            });

    };

    const CloseConfirmCancelDialog = () => {
        setconfirmCancelDialogProps({
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        });

        setShowConfirmCancelDialog(false);
    };

    return (
        <Container>
            <ConfirmCancelDialogComponent
                IsOpen={showConfirmCancelDialog}
                Title={confirmCancelDialogProps.Title}
                MessageDialog={confirmCancelDialogProps.MessageDialog}
                CloseDialog={confirmCancelDialogProps.CloseDialog}
                ConfirmOption={confirmCancelDialogProps.ConfirmOption}
                CancelOption={confirmCancelDialogProps.CancelOption}
            />

            <ModalComponent
                Title='Create product'
                IsOpen={showCreateProductModal}
                CloseModal={CreateProductModalOnClose}
                MaxWidth={"md"}
                ModalContent={
                    <Box component={"form"} onSubmit={CreateProductFormOnSubmit}>
                        <Grid container>
                            <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
                                <TextField
                                    fullWidth
                                    name='name'
                                    label={"Name"}
                                    onChange={ProductDataOnChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                >Save</Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />

            <ModalComponent
                Title='Add product to menu'
                IsOpen={showAddProductToMenuModal}
                CloseModal={AddProductToModalOnClose}
                MaxWidth={"md"}
                ModalContent={
                    <Box component={"form"} onSubmit={AddProductToMenuFormOnSubmit} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    fullWidth
                                    type={"number"}
                                    label={"Price"}
                                    name={"price"}
                                    onChange={ProductDataOnChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={"Description"}
                                    name={"description"}
                                    onChange={ProductDataOnChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        value={selectedCategoy}
                                        onChange={CategorySelectOnChange}
                                        label="Category"
                                    >
                                        <MenuItem value={""}><em>None</em></MenuItem>
                                        {
                                            menu?.categories?.map((category) => (
                                                <MenuItem key={category.id} value={category.id}>{category.categoryName}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography>Taxes:<IconButton onClick={AddTaxToProductModalOpen}><AddIcon /></IconButton></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    selectedTaxes.map((tax, index) => (
                                        <Box key={tax.id + `${index}a`}>
                                            <Typography>Name: {tax?.taxName} Value: {tax.taxValue}</Typography>
                                            <Divider />
                                        </Box>
                                    ))
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                >
                                    Save
                                </Button>
                            </Grid>

                        </Grid>
                    </Box>
                }
            />

            <ModalComponent
                Title='Add tax'
                IsOpen={showAddTaxToProductModal}
                CloseModal={AddTaxToProductModalOnClose}
                MaxWidth={"xl"}
                ModalContent={
                    <Container>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Search"
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} direction="row">
                            {
                                taxes?.map((tax, index) => (
                                    <Grid key={tax.id + `${index}b`} item xs={12}>
                                        <Card sx={{
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",

                                        }}>
                                            <CardContent sx={{ height: "100%" }}>
                                                <Typography sx={{ mb: 1.5 }}>{tax.taxName}</Typography>
                                                <Divider />
                                                <Typography>{tax.taxValue}</Typography>
                                                {
                                                    tax.isCustom ? <Typography>Custom</Typography> : <></>
                                                }
                                            </CardContent>

                                            <CardActions>
                                                <Button
                                                    onClick={() => { AddTaxToProductButtonOnClick(tax) }}
                                                    fullWidth
                                                    variant="contained"
                                                    size="small"
                                                    sx={{
                                                        mb: 1
                                                    }}
                                                >
                                                    Add to product
                                                </Button>
                                            </CardActions>

                                        </Card>
                                    </Grid>
                                ))
                            }

                        </Grid>
                    </Container>
                }
            />

            <Grid container justifyContent="flex-end">
                <Button onClick={() => { setShowCreateProductModal(true) }}><AddIcon /> New</Button>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Search"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="stretch" sx={{ mt: 0.5 }}>
                {
                    products?.map((product) => (
                        <Grid key={product.id} item xs={12} sm={6} md={3} sx={{ display: "flex" }}>
                            <Card sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",

                            }}>
                                <CardContent sx={{ height: "100%" }}>
                                    <Typography sx={{ mb: 1.5 }}>{product.productName}</Typography>
                                    <Divider />
                                    <Typography>{product.description}</Typography>
                                    {product.isCustom ? <Typography>Custom</Typography> : <></>}
                                </CardContent>

                                <CardActions>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => { AddProductToMenuButtonOnClick(product) }}
                                                fullWidth
                                                variant="contained"
                                                size="small"
                                            >
                                                Add to menu
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => { DeleteProductButtonOnClick(product) }}
                                                fullWidth
                                                variant="outlined"
                                                size="small"

                                            >
                                                Delete
                                            </Button>
                                        </Grid>

                                    </Grid>

                                </CardActions>

                            </Card>


                        </Grid>
                    ))
                }
            </Grid>

        </Container >
    );
};