import { Box, Button, Container, Divider, Grid, Link, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../api/auth";
import { useNotification } from "../../context/notification.context";
import { AuthData, SaveAuthData } from "../../utils/authHelper";
import { LoginValidate, ResetPasswordValidate } from "../../utils/formValidation";
import { SignInResponse } from "./interface/login.inteface";
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { ModalComponent } from "../../components/Modal";

interface LoginProp {

}

const LoginPage: React.FC<LoginProp> = () => {

        type LoginType = {
            username: string,
            password: string
        }

    const navigate = useNavigate();

    const { getError, getSuccess } = useNotification();

    const [loginData, setLoginData] = React.useState<LoginType>({
        username: "",
        password: ""
    });

    const updateLoginData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            LoginValidate.validate(loginData).
                then(() => {
                    auth.signIn({ username: loginData.username, password: loginData.password }).
                        then((response) => {
                            const responseData: SignInResponse = response.data;
                            if (responseData.statusCode === 200) {
                                const centoData: AuthData = {
                                    a: responseData.data?.token,
                                    r: responseData.data?.refreshToken,
                                    a_exp: responseData.data?.tokenExpiration,
                                    r_exp: responseData.data?.refreshTokenExpiration
                                };
                                SaveAuthData(centoData);
                                getSuccess("Welcome!");
                                navigate("/Dashboard");
                            }
                            else if (responseData.statusCode !== 500) {
                                getError("The email address or password is incorrect. Please retry...");
                            }
                            else {
                                getError("An error occurred, please contact support team.");
                            }

                        }).
                        catch((error) => {
                            console.log(error);
                        });
                }).
                catch((error) => {
                    getError(error.message);
                });
        }
        catch (error) {
            getError("Failed to sign in. Please try again or contact support.");
        }
    };

    const handleOnSignUpButtonClick = () => {
        navigate("/SignUp");
    };

    //#region FORGOT PASSWORD
    const [showForgotPasswordModal, setShowForgotPasswordModal] = React.useState<boolean>(false);
    const ForgotPasswordModalOnClose = () => {
        CleanResetePasswordData();
        setShowForgotPasswordModal(false);
    };
    const HaveCodeButtonOnClick = () => {
        if (resetPasswordData.email.trim() === "") {
            getError("Email is required.");
            return;
        }
        setShowResetPasswordModal(true);
    };
    const ForgotPasswordFormOnSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (resetPasswordData.email.trim() === "") {
            getError("Email is required.");
            return;
        }

        auth.ForgotPassword(resetPasswordData.email.trim())
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Confirmation code sent successfully.");
                    setShowResetPasswordModal(true);
                }
                else {
                    getError("Failed to send confirmation code. Please try again or contact support.");
                }
            })
            .catch((error) => {
                getError("Failed to send confirmation code. Please try again or contact support.");
            });
    };
    //#endregion

    //#region REASET PASSWORD
    interface ResetPasswordProps {
        email: string;
        password: string;
        confirmPassword: string;
        confrimationCode: string;
    }

    const [resetPasswordData, setResetPasswordData] = React.useState<ResetPasswordProps>({
        email: "",
        password: "",
        confirmPassword: "",
        confrimationCode: "",
    });
    const [showResetPasswordModal, setShowResetPasswordModal] = React.useState<boolean>(false);
    const ResestPasswordModalOnClose = () => {
        CleanResetePasswordData();
        setShowResetPasswordModal(false);

    };
    const updateResetePasswordData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setResetPasswordData({ ...resetPasswordData, [e.target.name]: e.target.value });
    };
    const CleanResetePasswordData = () => {
        setResetPasswordData({
            email: "",
            password: "",
            confirmPassword: "",
            confrimationCode: "",
        });
    };
    const ResetPasswordFormOnSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        ResetPasswordValidate.validate(resetPasswordData)
            .then(() => {
                auth.ResetPassword({
                    password: resetPasswordData.password,
                    confirmationCode: resetPasswordData.confrimationCode,
                    username: resetPasswordData.email
                })
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            getSuccess("Password reset successfully.");
                            ResestPasswordModalOnClose();
                            ForgotPasswordModalOnClose();
                        }
                        else {
                            getError("Failed to reset password. Please try again or contact support.");
                        }

                    })
                    .catch((error) => {
                        getError("Failed to reset password. Please try again or contact support.");
                    });

            })
            .catch((error) => {
                getError(error.message);
            });

    };
    //#endregion

    return (
            <Container maxWidth="sm">
                <ModalComponent
                    Title="Forgot password"
                    IsOpen={showForgotPasswordModal}
                    CloseModal={ForgotPasswordModalOnClose}
                    MaxWidth={"md"}
                    ModalContent={
                        <Box component={"form"} onSubmit={ForgotPasswordFormOnSubmit}>
                            <TextField
                                fullWidth
                                label={"Email"}
                                sx={{ mt: 2 }}
                                name={"email"}
                                value={resetPasswordData.email}
                                onChange={updateResetePasswordData}
                            />
                            <Button
                                fullWidth
                                type='submit'
                                variant='contained'
                                sx={{ mt: 2 }}
                            >Send reset password confirmation code</Button>
                            <Button
                                fullWidth
                                variant='outlined'
                                sx={{ mt: 2, mb: 2 }}
                                onClick={HaveCodeButtonOnClick}
                            >I have a password confirmation code</Button>
                        </Box>
                    }
                />

                <ModalComponent
                    Title="Reset password"
                    IsOpen={showResetPasswordModal}
                    CloseModal={ResestPasswordModalOnClose}
                    MaxWidth={"md"}
                    ModalContent={
                        <Box component={"form"} onSubmit={ResetPasswordFormOnSubmit}>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label={"Email"}
                                value={resetPasswordData.email}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                type={"password"}
                                label={"Password"}
                                onChange={updateResetePasswordData}
                                name={"password"}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                type={"password"}
                                label={"Confirm password"}
                                onChange={updateResetePasswordData}
                                name={"confirmPassword"}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label={"Confirmation code"}
                                onChange={updateResetePasswordData}
                                name={"confrimationCode"}
                            />
                            <Button
                                sx={{ mt: 2, mb: 2 }}
                                fullWidth
                                type='submit'
                                variant='contained'
                            >Reset password</Button>
                        </Box>
                    }
                />

                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: "100vh" }}
                >
                    <Grid item>
                        <Paper sx={{ padding: "1.2em", borderRadius: "0.5em" }}>
                            <Typography
                                variant="h4"
                                sx={{ mt: 1, mb: 1 }}
                            >
                                Sign in
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} >
                                <TextField
                                    margin='normal'
                                    name='username'
                                    type="text"
                                    onChange={updateLoginData}
                                    fullWidth
                                    label="Email"
                                    sx={{ mt: 2, mb: 1.5 }} />
                                <TextField
                                    margin='normal'
                                    name='password'
                                    type="password"
                                    onChange={updateLoginData}
                                    fullWidth
                                    label="Password"
                                    sx={{ mt: 1.5, mb: 1.5 }} />
                                <Grid container justifyContent="flex-end">
                                    <Link
                                        style={{ cursor: 'pointer' }}
                                        variant="body2"
                                        underline="hover"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowForgotPasswordModal(true);
                                        }}
                                    >
                                        Forgot password?
                                    </Link>
                                </Grid>

                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                    sx={{ mt: 1.5, mb: 1.5 }}
                                >
                                    Next
                                </Button>
                            </Box>
                            <Grid container spacing={1} sx={{ mb: 3 }}>
                                <Grid item xs={6} md={6}>
                                    <Button
                                        fullWidth
                                        variant='outlined'
                                        startIcon={<FacebookIcon />}
                                    >
                                        Facebook
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Button
                                        fullWidth
                                        variant='outlined'
                                        startIcon={<GoogleIcon />}
                                    >
                                        Google
                                    </Button>
                                </Grid>
                            </Grid>
                            <Divider >New to cento?</Divider>
                            <Button
                                fullWidth
                                type='submit'
                                variant='outlined'
                                sx={{ mt: 1.5, mb: 3 }}
                                onClick={handleOnSignUpButtonClick}
                            >
                                Create a new Cento account
                            </Button>
                        </Paper>

                    </Grid>

                </Grid>
            </Container>
    );
};

export default LoginPage;