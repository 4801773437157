import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

export interface ConfirmCancelDialogProps {
    IsOpen: boolean,
    Title: string,
    MessageDialog: string,
    CloseDialog: () => void,
    CancelOption: () => void,
    ConfirmOption: () => void
}

export const ConfirmCancelDialogComponent: React.FC<ConfirmCancelDialogProps> = ({ ...props }) => {
    return (
        <Dialog
            open={props.IsOpen}
        >
            <DialogTitle>
                {props.Title}
            </DialogTitle>

            <DialogContent>
                <DialogContentText >
                    {props.MessageDialog}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.CancelOption}>Cancel</Button>
                <Button onClick={props.ConfirmOption}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};