import { instance } from "./base.api";

const endponit = {
    GetAll: "api/Promotion/GetAllPromotionTypes"
}

export const promotionTypes = {
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
};