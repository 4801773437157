import { Button, Card, CardActions, CardContent, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { useNotification } from '../../context/notification.context';
import AddIcon from '@mui/icons-material/Add';
import { getAccessToken } from '../../utils/authHelper';
import { defaultToppings } from '../../api/defaultToppings';
import { DefaultTopping } from '../../types/defaultTopping';
import { customToppings } from '../../api/customToppings';
import { ModalComponent } from '../../components/Modal';
import { Box } from '@mui/system';
import { CreateCustomToppingValidate } from '../../utils/formValidation';
import { ConfirmCancelDialogComponent, ConfirmCancelDialogProps } from '../../components/ConfirmCancelDialog';

interface ToppingTabProps {

}
interface ToppingData {
    name: string;
}

export const ToppingTab: React.FC<ToppingTabProps> = ({ }) => {
    const { getError, getSuccess } = useNotification();
    const [toppings, setToppings] = React.useState<DefaultTopping[] | undefined>(undefined);
    const [showCreateToppingModal, setShowCreateToppingModal] = React.useState<boolean>(false);
    const [toppingData, setToppingData] = React.useState<ToppingData>(
        {
            name: "",
        });

    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = React.useState<boolean>(false);
    const [confirmCancelDialogProps, setconfirmCancelDialogProps] = React.useState<ConfirmCancelDialogProps>(
        {
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        }
    );


    React.useEffect(() => {
        GetToppings();
    }, []);

    const GetToppings = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        let toppingsResponse: DefaultTopping[] | null = [];

        await defaultToppings.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (toppingsResponse !== null) {
                        toppingsResponse = [...toppingsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        await customToppings.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (toppingsResponse !== null) {
                        toppingsResponse = [...toppingsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        setToppings(toppingsResponse);
    };

    const CreateToppingModalOnClose = () => {
        setShowCreateToppingModal(false);
    };

    const ToppingDataOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setToppingData({ ...toppingData, [e.target.name]: e.target.value });
    };

    const CreateToppingFormOnSubmit = async (e: React.FormEvent) => {
        try {
            e.preventDefault();
            CreateCustomToppingValidate.validate(toppingData).
                then(async () => {
                    const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                    customToppings.Add({
                        token: `Bearer ${token}`,
                        data: {
                            toppingName: toppingData.name.trim(),
                            toppingTypeIndex: 1
                        }
                    }).
                        then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Topping created successfully.");
                                GetToppings();
                                CreateToppingModalOnClose();
                            }
                            else {
                                getError("Failed to create topping. Please try again or contact support.");
                            }
                        }).
                        catch((error) => {
                            getError("Failed to create topping. Please try again or contact support.");
                        });

                }).
                catch((error) => {
                    getError(error.message);
                });
        }
        catch (error) {
            getError("Failed to create topping. Please try again or contact support.");
        }
    };

    const DeleteToppingButtonOnClick = (topping: DefaultTopping) => {
        if (!topping.isCustom) {
            getError("This topping can't be deleted");
            return;
        }

        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete topping";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that topping?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteTopping(topping.id) };

    };

    const DeleteTopping = async (id: string) => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        customToppings.DeleteCustomTopping({
            token: `Bearer ${token}`,
            id: id
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Topping deleted successfully.");
                    GetToppings();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete topping. Please try again or contact support.");
                }
            })
            .catch((error) => {
                getError("Failed to delete topping. Please try again or contact support.");
            });
    };


    const CloseConfirmCancelDialog = () => {
        setconfirmCancelDialogProps({
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        });

        setShowConfirmCancelDialog(false);
    };


    return (
        <Container>
            <ConfirmCancelDialogComponent
                IsOpen={showConfirmCancelDialog}
                Title={confirmCancelDialogProps.Title}
                MessageDialog={confirmCancelDialogProps.MessageDialog}
                CloseDialog={confirmCancelDialogProps.CloseDialog}
                ConfirmOption={confirmCancelDialogProps.ConfirmOption}
                CancelOption={confirmCancelDialogProps.CancelOption}
            />

            <ModalComponent
                Title='Create topping'
                IsOpen={showCreateToppingModal}
                CloseModal={CreateToppingModalOnClose}
                MaxWidth={'md'}
                ModalContent={
                    <Box component={"form"} onSubmit={CreateToppingFormOnSubmit}>
                        <Grid container>
                            <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
                                <TextField
                                    fullWidth
                                    name='name'
                                    label={"Name"}
                                    onChange={ToppingDataOnChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                >Save</Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />

            <Grid container justifyContent="flex-end">
                <Button onClick={() => { setShowCreateToppingModal(true) }}><AddIcon /> New</Button>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Search"
                    />
                </Grid>

                <Grid container spacing={2} direction="row" alignItems="stretch" sx={{ mt: 0.5 }}>
                    {
                        toppings?.map((topping) => (
                            <Grid key={topping.id} item xs={12} sm={6} md={3} sx={{ display: "flex" }}>
                                <Card sx={{
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",

                                }}>
                                    <CardContent sx={{ height: "100%" }}>
                                        <Typography sx={{ mb: 1.5 }}>{topping.toppingName}</Typography>
                                        <Divider />
                                        {topping.isCustom ? <Typography>Custom</Typography> : <></>}
                                    </CardContent>
                                    <CardActions>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={() => { DeleteToppingButtonOnClick(topping) }}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"

                                                >
                                                    Delete
                                                </Button>
                                            </Grid>

                                        </Grid>

                                    </CardActions>

                                </Card>


                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>

        </Container>
    );
};