import { Box, Button, Card, CardContent, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ModalComponent } from '../../components/Modal';
import { useNotification } from '../../context/notification.context';
import { defaultTaxes } from '../../api/defaultTaxes';
import { getAccessToken } from '../../utils/authHelper';
import { DefaultTaxModel } from '../../types/defaultTax.Model';

interface TaxTabProps {

};

export const TaxTab: React.FC<TaxTabProps> = ({ }) => {
    const { getError, getSuccess } = useNotification();
    const [taxes, setTaxes] = React.useState<DefaultTaxModel[] | undefined>(undefined);
    const [showCreateTaxModal, setShowCreateTaxModal] = React.useState<boolean>(false);
    const [selectedApplyToType, setSelectedApplyToType] = React.useState<string>('');

    React.useEffect(() => {
        GetTaxes();
    }, []);

    const GetTaxes = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        defaultTaxes.GetAllByRegionId({
            token: `Bearer ${token}`,
            regionId: "001001001001"
        }).
            then((response) => {
                setTaxes(response.data.data);
            }).
            catch((error) => {
                console.log(error);
            });
    };

    const CreateTaxModalOnClose = () => {
        setShowCreateTaxModal(false);
    };

    const ApplyToTypeSelectOnChange = (event: SelectChangeEvent) => {
        setSelectedApplyToType(event.target.value);
    };

    const CreateTaxFormOnSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        getSuccess("Tax created successfully.");
    };

    return (
        <Container>

            <ModalComponent
                Title='Create tax'
                IsOpen={showCreateTaxModal}
                CloseModal={CreateTaxModalOnClose}
                MaxWidth={"xs"}
                ModalContent={
                    <Box component={"form"} onSubmit={CreateTaxFormOnSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    label={"Name"}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label={"Tax porcent"}
                                    fullWidth
                                    type={"number"}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Apply to</InputLabel>
                                    <Select
                                        value={selectedApplyToType.toString()}
                                        onChange={ApplyToTypeSelectOnChange}
                                        label="Apply to"
                                    >
                                        <MenuItem value={""}><em>None</em></MenuItem>
                                        <MenuItem value={"1"}>Menu</MenuItem>
                                        <MenuItem value={"2"}>Category</MenuItem>
                                        <MenuItem value={"3"}>Product</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                >
                                    Save
                                </Button>
                            </Grid>

                        </Grid>
                    </Box>
                }
            />

            {/* <Grid container justifyContent="flex-end">
                <Button onClick={() => { setShowCreateTaxModal(true) }}><AddIcon /> New</Button>
            </Grid> */}

            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Search"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="stretch" sx={{ mt: 0.5 }}>
                {
                    taxes?.map((tax) => (
                        <Grid key={tax.id} item xs={12} sm={6} md={3} sx={{ display: "flex" }}>
                            <Card sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",

                            }}>
                                <CardContent sx={{ height: "100%" }}>
                                    <Typography sx={{ mb: 1.5 }}>{tax.taxName}</Typography>
                                    <Divider />
                                    <Typography>{tax.taxValue} %</Typography>
                                </CardContent>
                            </Card>

                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    );
};