import { instance } from "./base.api";
import { BusinessModel } from "./models/User.Model";

const endponit = {
    Add: "api/User/Add",
    GetByAccountId: "api/User/GetByAccountId",
    AddWithAccountMainerMember: "api/User/AddWithAccountMainerMember",
    UpdateAccountInfo: "api/user/UpdateAccountInfo",
    UpdateBusinessInfo: "api/user/UpdateBusinessInfo"
}

interface AddUserProp {
    address: string;
    birthdate: string;
    familyName: string;
    name: string;
    password: string;
}

interface UpdateAccountInfoProp {
    name: string;
    lastname: string;
    birthdate: string;
}

interface UpdateBusinessInfoProp { 
    business : BusinessModel
}


export const user = {
    GetByAccountId: function ({ token }: { token: string }) {
        return instance.get(endponit.GetByAccountId, {
            headers: {
                Authorization: token
            }
        });
    },
    Add: function ({ token, data }: { token: string, data: AddUserProp }) {
        return instance.post(endponit.Add, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    AddWithAccountMainerMember: function ({ token, data }: { token: string, data: AddUserProp }) {
        return instance.post(endponit.AddWithAccountMainerMember, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    UpdateAccountInfo: function ({ token, data }: { token: string, data: UpdateAccountInfoProp }) {
        return instance.post(endponit.UpdateAccountInfo, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    UpdateBusinessInfo: function ({ token, data }: { token: string, data: UpdateBusinessInfoProp }) {
        return instance.post(endponit.UpdateBusinessInfo, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
};
