import { instance } from "./base.api";

const endponit = {
    Add: "api/Topping/Custom/Add",
    GetAll: "api/Topping/Custom/GetAll",
    DeleteCustomTopping:"api/Topping/Custom/DeleteTopping"
}

interface CustomToppingProp{
    toppingName: string,
    toppingTypeIndex: number
}

export const customToppings = {
    Add: function ({ token, data }: { token: string, data: CustomToppingProp }) {
        return instance.post(endponit.Add, JSON.stringify(data) ,{
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
    DeleteCustomTopping: function ({ token, id }: { token: string, id: string }) {
        return instance.delete(endponit.DeleteCustomTopping + `/${id}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
};