import { instance } from "./base.api";

const endponit = {
    GetAll: "api/Menu/Default/GetTaxes",
    GetAllByRegionId: "api/Tax/GetAllByRegionId"
}

export const defaultTaxes = {
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
    GetAllByRegionId: function ({ token, regionId }: { token: string, regionId: string }) {
        return instance.get(endponit.GetAllByRegionId + `/${regionId}`, {
            headers: {
                Authorization: token
            }
        });
    },
};