import { Grid } from "@mui/material";
import React from "react";
import 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';
import { AuthValidate, getAccessToken, SignOut } from "../../utils/authHelper";
import { tickets } from "../../api/tickets";
import Container from "@mui/material/Container";
import { getMonthByNumber } from "../../utils/appHelper";
import { useNavigate } from "react-router-dom";

interface DashboardProp {

}

interface SalesProp {
    salesDate: string;
    salesCount: number;
}

interface MonthlySalesProp {
    month: number;
    salesCount: number;
}
interface WeeklySalesProp {
    week: number,
    salesCount: number
}

interface TopMenuItemsProp {
    productName: string,
    quantity: number
}

interface BestSaleHourProp {
    hour: string,
    total: number
}


const DashboardPage: React.FC<DashboardProp> = () => {
    const navigate = useNavigate();

    const [datesLabels, setDatesLabels] = React.useState<String[]>([]);
    const [salesCount, setSalesCount] = React.useState<number[]>([]);

    const [monthLabels, setMonthLabels] = React.useState<String[]>([]);
    const [monthlySalesCount, setMonthlySalesCount] = React.useState<number[]>([]);


    const [weekLabels, setWeekLabels] = React.useState<String[]>([]);
    const [weeklySalesCount, setWeeklySalesCount] = React.useState<number[]>([]);

    const [topMenuItemLabels, setTopMenuItemLabels] = React.useState<String[]>([]);
    const [topMenuItemsCount, setTopMenuItemsCount] = React.useState<number[]>([]);

    const [bestSaleHoursLabels, setBestSaleHoursLabels] = React.useState<String[]>([]);
    const [bestSaleHoursCount, setBestSaleHoursCount] = React.useState<number[]>([]);

    React.useEffect(
        AuthValidate() ? () => {
            try {
                // validateMainAccountMemberExist();
                GetSalesGroupedByDate();
                GetSalesGroupedByMonth();
                GetSalesGroupedByWeek();
                GetTopMenuItems();
                GetBestSaleHours();
            }
            catch (error) {
                console.log(error);
            }

        } : () => {
            SignOut();
            navigate("/");
        }, []);

    const GetSalesGroupedByDate = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        tickets.GetSalesGroupedByDate({
            token: `Bearer ${token}`
        }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    const sales: SalesProp[] = response.data.data;
                    setDatesLabels([]);
                    setSalesCount([]);
                    sales.map((sale) => {
                        const [date, time] = sale.salesDate.split('T')
                        const [year, month, day] = date.split('-')
                        setDatesLabels((dates) => {
                            return [...dates, date]
                        })
                        setSalesCount((salesCount) => {
                            return [...salesCount, sale.salesCount]
                        })
                    })
                }
            }).
            catch((error) => {

            });
    };

    const GetSalesGroupedByMonth = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        const currentYear: string = (new Date()).getFullYear().toString();
        tickets.GetSalesGroupedByMonth({
            token: `Bearer ${token}`,
            year: currentYear
        }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    const sales: MonthlySalesProp[] = response.data.data;
                    setMonthLabels([]);
                    setMonthlySalesCount([]);
                    sales.map((sale) => {
                        setMonthLabels((month) => {

                            return [...month, getMonthByNumber(sale.month)]
                        })
                        setMonthlySalesCount((salesCount) => {
                            return [...salesCount, sale.salesCount]
                        })
                    })
                }
            }).
            catch((error) => {

            });
    };

    const GetSalesGroupedByWeek = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        const currentYear: string = (new Date()).getFullYear().toString();
        tickets.GetSalesGroupedByWeek({
            token: `Bearer ${token}`,
            year: currentYear
        }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    const sales: WeeklySalesProp[] = response.data.data;
                    setWeekLabels([]);
                    setWeeklySalesCount([]);
                    sales.map((sale) => {
                        setWeekLabels((month) => {
                            return [...month, sale.week.toString()]
                        })
                        setWeeklySalesCount((salesCount) => {
                            return [...salesCount, sale.salesCount]
                        })
                    })
                }
            }).
            catch((error) => {

            });

    };

    const GetTopMenuItems = async () => {
        try {
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            tickets.GetTopMenuItems({
                token: `Bearer ${token}`
            })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        const menuItems: TopMenuItemsProp[] = response.data.data;
                        setTopMenuItemLabels([]);
                        setTopMenuItemsCount([]);
                        menuItems.map((item) => {
                            setTopMenuItemLabels((month) => {
                                return [...month, item.productName]
                            })
                            setTopMenuItemsCount((salesCount) => {
                                return [...salesCount, item.quantity]
                            })
                        })
                    }
                })
                .catch((error) => {

                });
        }
        catch (error) {

        }
    };

    const GetBestSaleHours = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        tickets.GetBestSaleHours({
            token: `Bearer ${token}`
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    const sales: BestSaleHourProp[] = response.data.data;
                    setBestSaleHoursLabels([]);
                    setBestSaleHoursCount([]);
                    sales.map((sale) => {
                        setBestSaleHoursLabels((month) => {
                            return [...month, sale.hour.toString()]
                        })
                        setBestSaleHoursCount((salesCount) => {
                            return [...salesCount, sale.total]
                        })
                    })
                }
            })
            .catch((error) => {

            });
    };

    return (
        <Container>
            <Grid container spacing={1} direction="row">
                <Grid item xs={12}>
                    <Line
                        data={{
                            labels: datesLabels,
                            datasets: [
                                {
                                    label: 'Sales by date',
                                    backgroundColor: "#12181b",
                                    borderColor: "#8E64FF",
                                    data: salesCount,

                                }
                            ]
                        }}
                        options={{ maintainAspectRatio: false }}
                    />
                </Grid>

                <Grid item xs={12} >
                    <Bar
                        data={{
                            labels: monthLabels,
                            datasets: [
                                {
                                    label: 'Sales by month',
                                    backgroundColor: "#8E64FF",
                                    borderColor: "#8E64FF",
                                    data: monthlySalesCount,

                                }
                            ]
                        }}
                        options={{ maintainAspectRatio: false }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Line
                        data={{
                            labels: weekLabels,
                            datasets: [
                                {
                                    label: 'Sales by week',
                                    backgroundColor: "#12181b",
                                    borderColor: "#8E64FF",
                                    data: weeklySalesCount,

                                }
                            ]
                        }}
                        options={{ maintainAspectRatio: false }}
                    />
                </Grid>

                <Grid item xs={12} >
                    <Bar
                        data={{
                            labels: topMenuItemLabels,
                            datasets: [
                                {
                                    label: 'Top menu items',
                                    backgroundColor: "#8E64FF",
                                    borderColor: "#8E64FF",
                                    data: topMenuItemsCount,

                                }
                            ]
                        }}
                        options={{ maintainAspectRatio: false }}
                    />
                </Grid>

                <Grid item xs={12} >
                    <Bar
                        data={{
                            labels: bestSaleHoursLabels,
                            datasets: [
                                {
                                    label: 'Best sale hours',
                                    backgroundColor: "#8E64FF",
                                    borderColor: "#8E64FF",
                                    data: bestSaleHoursCount,
                                    hoverBorderColor: 'red'

                                }
                            ]
                        }}
                        options={{ maintainAspectRatio: false }}
                    />
                </Grid>

            </Grid>
        </Container>
    );
};

export default DashboardPage;