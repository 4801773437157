import { instance } from "./base.api";

const endponit = {
    GetSalesGroupedByDate: "api/Graph/GetSalesGroupedByDate",
    GetSalesGroupedByMonth: "api/Graph/GetSalesGroupedByMonth",
    GetSalesGroupedByWeek: "api/Graph/GetSalesGroupedByWeek",
    GetTopMenuItems: "api/Graph/GetTopMenuItems",
    GetBestSaleHours: "api/Graph/GetBestSaleHours"
}

export const tickets = {
    GetSalesGroupedByDate: function ({ token }: { token: string }) {
        return instance.get(endponit.GetSalesGroupedByDate, {
            headers: {
                Authorization: token
            }
        });
    },
    GetSalesGroupedByMonth: function ({ token, year }: { token: string, year: string }) {
        return instance.get(endponit.GetSalesGroupedByMonth + `/${year}`, {
            headers: {
                Authorization: token
            }
        });
    },
    GetSalesGroupedByWeek: function ({ token, year }: { token: string, year: string }) {
        return instance.get(endponit.GetSalesGroupedByWeek + `/${year}`, {
            headers: {
                Authorization: token
            }
        });
    },
    GetTopMenuItems: function ({ token}: { token: string }) {
        return instance.get(endponit.GetTopMenuItems, {
            headers: {
                Authorization: token
            }
        });
    },
    GetBestSaleHours: function ({ token}: { token: string }) {
        return instance.get(endponit.GetBestSaleHours, {
            headers: {
                Authorization: token
            }
        });
    },
};