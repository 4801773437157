import { instance } from "./base.api";

const endponit = {
    Add: "api/ProductVariant/Custom/Add",
    GetAll: "api/ProductVariant/Custom/GetAll",
    DeleteCustomProductVariant: "api/ProductVariant/Custom/DeleteProductVariant"
}

interface CustomProductVariantProp{
    variantName: string
}

export const customProductVariants = {
    Add: function ({ token, data }: { token: string, data: CustomProductVariantProp }) {
        return instance.post(endponit.Add, JSON.stringify(data) ,{
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
    DeleteCustomProductVariant: function ({ token, id }: { token: string, id: string }) {
        return instance.delete(endponit.DeleteCustomProductVariant + `/${id}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
};