import { Container } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MenuModel, UserMenusModel } from "../../api/models/Menu.Model";
import { userMenu } from "../../api/userMenu";
import { HeaderComponent, TabComponent } from "../../components";
import { useNotification } from "../../context/notification.context";
import { AuthValidate, getAccessToken, SignOut } from "../../utils/authHelper";
import { AllMenuTab } from "./AllMenuTab";
import { CategoryTab } from "./CategoryTab";
import { ProductTab } from "./ProductTab";
import { ProductVariantTab } from "./ProductVariantTab";
import { PromotionTab } from "./PromotionTab";
import { TaxTab } from "./TaxTab";
import { ToppingTab } from "./ToppingTab";

interface UserMenuProp {

}
interface TabComponentProp {
    label: string,
    component: JSX.Element | undefined
}

const UserMenuPage: React.FC<UserMenuProp> = () => {
    const navigate = useNavigate();
    const [TabProp, setTabProp] = React.useState<TabComponentProp[]>([{ label: "", component: undefined }]);
    const [userMenus, setUserMenus] = React.useState<UserMenusModel | null>(null);
    const [menu, setMenu] = React.useState<MenuModel | null>(null);
    const { getError, getSuccess } = useNotification();

    const UpdateMenu = (updatedMenu: MenuModel | null) => {
        setMenu(updatedMenu);
    };

    const UpdateUserMenu = (userMenus: UserMenusModel | null) => {
        setUserMenus(userMenus);
    };

    // const SaveChanges = async () => {
    //     const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
    //     if (userMenus !== null) {
    //         userMenu.UpdateUserMenu({ token: `Bearer ${token}`, data: userMenus }).
    //             then((response) => {
    //                 if (response.data.statusCode === 200) {
    //                     getSuccess("Menu modified successfully");
    //                 }
    //                 else {
    //                     getError("Failed to complete menu modification");
    //                 }
    //             }).
    //             catch((error) => {
    //                 console.log(error);
    //             });
    //     }

    // };

    const RefreshMenu = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        userMenu.GetByUserId({ token: `Bearer ${token}` }).
            then((response) => {
                UpdateMenu(response.data.data[0].menus[0]);
                UpdateUserMenu(response.data.data[0]);
            }).
            catch((error) => {
                console.log(error);
            });
    };

    React.useEffect(
        AuthValidate() ? () => {
            setTabProp([
                {
                    label: "All",
                    component: <AllMenuTab menu={menu} refreshMenu={RefreshMenu} />
                },
                {
                    label: "Categories",
                    component: (<CategoryTab menu={menu} refreshMenu={RefreshMenu} />)
                },
                {
                    label: "Products",
                    component: (<ProductTab menu={menu} />)
                },
                {
                    label: "Taxes",
                    component: (<TaxTab />)
                },
                {
                    label: "Promotions",
                    component: (<PromotionTab />)
                },
                {
                    label: "Toppings",
                    component: (<ToppingTab />)
                },
                {
                    label: "Product variants",
                    component: (<ProductVariantTab />)
                }
            ]);

        } : () => {
            SignOut();
            navigate("/");
        }, [menu]);

    return (
        <Container>
            <HeaderComponent
                title='Menu'
                description=''
            />
            <TabComponent tabs={TabProp} />
        </Container>
    );
};

export default UserMenuPage;