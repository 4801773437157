import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";

interface TabProp {
    label: string,
    component: JSX.Element | undefined
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabComponent: React.FC<{ tabs: TabProp[] }> = ({ tabs }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box >
                <Tabs variant="scrollable" scrollButtons="auto" value={value}
                    onChange={handleChange} aria-label="basic tabs example">
                    {
                        tabs.map((tab, index) => (
                            <Tab key={index} label={tab.label} {...a11yProps(index)} />
                        ))
                    }
                </Tabs>
            </Box>
            {
                tabs.map((tab, index) => (
                    <TabPanel key={index} value={value} index={index}> {tab.component} </TabPanel>
                ))
            }
        </Box>
    );
};

export default TabComponent;