import { DefaultPromotion } from "../types/defaultPromotion.Model";
import { instance } from "./base.api";
import { } from "./models/Menu.Model";

const endponit = {
    Add: "api/Promotion/Custom/Add",
    Update: "api/Promotion/Custom/Update",
    Delete: "api/Promotion/Custom/Delete",
    GetAll: "api/Promotion/Custom/GetAll",
    GetAllOrderedByPriority: "api/Promotion/Custom/GetAllOrderedByPriority",
    EditPromotionsPriority: "api/Promotion/ChangePromotionPriority",
    GetAllByToApplyId: "api/Promotion/Custom/GetAllByToApplyId"
}

interface CustomPromotionProp {
    id?: string;
    enabled: boolean;
    promotionName: string;
    promotionTypeIndex: number;
    discountValue: number;
    starts: string;
    ends: string;
    priority: number;
    allowOverlap: boolean;
    automatic: boolean;
    amountProductsToPay: number;
    amountProductsToGet: number;
    daysOfTheWeek: number[];
    hoursOfTheDay: number[];
    toApplyId: number;
}

interface EditPromotionsPriorityProp {
    promotions: DefaultPromotion[]
}

export const customPromotions = {
    Add: function ({ token, data }: { token: string, data: CustomPromotionProp }) {
        return instance.post(endponit.Add, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    Update: function ({ token, data }: { token: string, data: CustomPromotionProp }) {
        return instance.post(endponit.Update, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    Delete: function ({ token, id }: { token: string, id: string }) {
        return instance.delete(endponit.Delete + `/${id}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
    GetAllByToApplyId: function ({ token, id }: { token: string, id: number }) {
        return instance.get(endponit.GetAllByToApplyId + `/${id}`, {
            headers: {
                Authorization: token
            }
        });
    },
    GetAllOrderedByPriority: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAllOrderedByPriority, {
            headers: {
                Authorization: token
            }
        });
    },
    EditPromotionsPriority: function ({ token, data }: { token: string, data: EditPromotionsPriorityProp }) {
        return instance.post(endponit.EditPromotionsPriority, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
};