import { instance } from "./base.api";

const endponit = {
    ByPostalCodeAndType: "api/Menu/Default/ByPostalCodeAndType",
    SetMenuToUser: "api/Menu/Default/SetMenuToUser",
}

interface GetMenuByPostalCodeAndTypeProps {
    postalCode: string,
    type: number,
};

export const defaultMenu = {
    GetByPostalCodeAndType: function ({ token, data }: { token: string, data: GetMenuByPostalCodeAndTypeProps }) {
        return instance.post(endponit.ByPostalCodeAndType, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    SetMenuToUser: function ({ token, menuId }: { token: string, menuId: string }) {
        return instance.post(`${endponit.SetMenuToUser}/${menuId}`, {}, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
};