import { instance } from "./base.api";

const endponit = {
    GetAll: "api/Topping/Default/GetAll"
}

export const defaultToppings = {
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
};