import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

type ThemeProp = {
    children: JSX.Element
};

export enum themePalette {
    Background = "#F4F6F5",
    Lime = "#C8FA5F",
    V1 = "#36413E",
    OilersMainColor = "rgba(235,110,36,255)",
    CentoColor = "#A743FF",
    FontGlobal = "'JetBrains Mono', monospace",
    FontGlobalV1 = "'Proxima Nova', sans-serif",
    //Alert styles
    ErrorMain = "#f44336",
    BgErrorMain = "rgba(244,67,54,0.1)",
    SuccessMain = "#66bb6a",
    BgSuccessMain = "rgba(102,187,106,0.1)"
}

const theme = createTheme({
    palette: {
        // mode:"dark",
        background: {
            default: themePalette.Background
        },
        primary: {
            main: themePalette.CentoColor
        }
    },
    typography: {
        fontFamily: themePalette.FontGlobalV1,
    },
    components: {
        MuiButton: {
            defaultProps: {
                style: {
                    textTransform: "none",
                    boxShadow: "none",
                    borderRadius: "0.5em",

                }
            }
        },
        MuiAlert: {
            defaultProps: {
                style: {
                    borderRadius: "0.8em",
                    fontSize: "1em"
                },
            },
            styleOverrides: {
                standardError: {
                    border: `1px solid ${themePalette.ErrorMain}`,
                    background: themePalette.BgErrorMain
                },
                standardSuccess: {
                    border: `1px solid ${themePalette.SuccessMain}`,
                    background: themePalette.BgSuccessMain
                },
            },
        },
        MuiTab: {
            defaultProps: {
                style: {
                    textTransform: "none",
                }
            }
        },
    }
});

export const ThemeConfig: React.FC<ThemeProp> = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
};