import { Box, Button, Card, CardActions, CardContent, Container, Divider, Grid, IconButton, Modal, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { HeaderComponent } from "../../components";
import AddIcon from '@mui/icons-material/Add';
import { device } from "../../api/device";
import { AuthValidate, getAccessToken, SignOut } from "../../utils/authHelper";
import { useNotification } from "../../context/notification.context";
import { DeviceModel } from "../../api/models/Device.Model";
import UpdateIcon from '@mui/icons-material/Update';
import { ModalComponent } from "../../components/Modal";
import { ConfirmCancelDialogComponent, ConfirmCancelDialogProps } from "../../components/ConfirmCancelDialog";
import { useNavigate } from "react-router-dom";

interface DeviceProp {

}

interface EditedDeviceData {
    name: string;
}

const Device: React.FC<DeviceProp> = () => {
    const { getError, getSuccess } = useNotification();
    const [showAddDeviceModal, setShowAddDeviceModal] = React.useState<boolean>(false);
    const [deviceCode, setDeviceCode] = React.useState<string>("");
    const [userDevices, setUserDevices] = React.useState<DeviceModel[] | undefined>(undefined);
    const [showEditDeviceModal, setShowEditDeviceModal] = React.useState<boolean>(false);
    const [selecteDevice, setSelectedDevice] = React.useState<DeviceModel | undefined>(undefined);
    const [editedDeviceData, setEditedDeviceData] = React.useState<EditedDeviceData>({
        name: ""
    });
    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = React.useState<boolean>(false);
    const [confirmCancelDialogProps, setconfirmCancelDialogProps] = React.useState<ConfirmCancelDialogProps>(
        {
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        }
    );

    const navigate = useNavigate();

    React.useEffect(
        AuthValidate() ? () => {
            GetUserDevices();
        } : () => {
            SignOut();
            navigate("/");
        }, []);

    const CloseConfirmCancelDialog = () => {
        setconfirmCancelDialogProps({
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        });

        setShowConfirmCancelDialog(false);
    };

    const GetUserDevices = async () => {
        try {
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            device.GetDevicesByUserId({ token: `Bearer ${token}` }).
                then((response) => {
                    if (response.data.statusCode === 200) {
                        setUserDevices(response.data.data);
                    }

                }).
                catch((error) => {
                    console.log(error);
                });
        }
        catch (error) {

        }
    };

    const UnpairButtonOnClick = async (deviceSelected: DeviceModel) => {
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Unpair device";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to unpair that device?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { UnpairDevice(deviceSelected.deviceId) };
    };

    const UnpairDevice = async (deviceId: string) => {
        try {
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            device.Unpair({
                token: `Bearer ${token}`,
                data: {
                    DeviceId: deviceId
                }
            }).
                then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Device unpaired successfully.");
                        GetUserDevices();
                        CloseConfirmCancelDialog();
                    }
                    else {
                        getError("Failed to unpair device. Please try again or contact support.");
                    }

                }).catch((error) => {
                    getError("Failed to unpair device. Please try again or contact support.");
                });
        }
        catch (error) {
            getError("Failed to unpair device. Please try again or contact support.");
        }
    };

    const DeviceCodeOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceCode(e.target.value);
    };

    const EditDeviceFormOnSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();

            if (selecteDevice === undefined) {
                getError("Failed to select device.");
                throw new Error();
            }

            if (selecteDevice.name === editedDeviceData.name) {
                getError("Device not edited.");
                throw new Error();
            }

            selecteDevice.name = editedDeviceData.name;

            if (editedDeviceData.name.trim() === "") {
                getError("Name is required.");
                throw new Error();
            }
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

            device.EditDevice({
                token: `Bearer ${token}`,
                data: {
                    device: selecteDevice
                }
            })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Device edited sucessfully.");
                        setShowEditDeviceModal(false);
                    }
                    else {
                        getError("Failed to edit device. Please try again or contact support.");
                    }

                })
                .catch((error) => {
                    getError("Failed to edit device. Please try again or contact support.");
                });

        }
        catch (error) {

        }
    };

    const editDeviceData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedDeviceData({ ...editedDeviceData, [e.target.name]: e.target.value });
    };

    const EditDeviceButtonOnClick = (device: DeviceModel) => {
        setSelectedDevice(device);
        editedDeviceData.name = device.name ?? "";
        setShowEditDeviceModal(true)
    };

    const AddDeviceModalOnClose = () => {
        setShowAddDeviceModal(false);
    };

    const PairDeviceFormOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if (deviceCode.trim() === "") {
                getError("Device code is required.");
                return;
            }
            console.log(deviceCode);
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            device.Add({
                token: `Bearer ${token}`,
                data: {
                    ConfirmationCode: deviceCode
                }
            }).
                then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Device paired successfully.");
                        GetUserDevices();
                        AddDeviceModalOnClose();
                    }
                    else {
                        getError("Failed to pair device. Please try again or contact support.");
                    }

                }).catch((error) => {
                    getError("Failed to pair device. Please try again or contact support.");
                    console.log(error);
                });

        }
        catch (error) {
            getError("Failed to pair device. Please try again or contact support.");
            console.log(error);
        }
    };

    return (
        <Container>
            <HeaderComponent
                title='Devices'
                description=''
            />

            <ConfirmCancelDialogComponent
                IsOpen={showConfirmCancelDialog}
                Title={confirmCancelDialogProps.Title}
                MessageDialog={confirmCancelDialogProps.MessageDialog}
                CloseDialog={confirmCancelDialogProps.CloseDialog}
                ConfirmOption={confirmCancelDialogProps.ConfirmOption}
                CancelOption={confirmCancelDialogProps.CancelOption}
            />

            <ModalComponent
                Title="New device"
                IsOpen={showAddDeviceModal}
                CloseModal={AddDeviceModalOnClose}
                MaxWidth={"md"}
                ModalContent={
                    <Box component={"form"} onSubmit={PairDeviceFormOnSubmit}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField
                                    margin='normal'
                                    name='deviceCode'
                                    type="text"
                                    fullWidth
                                    label="Device code"
                                    onChange={DeviceCodeOnChange}
                                    sx={{ mt: 2, mb: 1 }} />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                    sx={{ mt: 1.5, }}
                                >
                                    Pair
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />

            <ModalComponent
                Title="Edit device"
                IsOpen={showEditDeviceModal}
                CloseModal={() => { setShowEditDeviceModal(false) }}
                MaxWidth={"xl"}
                ModalContent={
                    <Box component={"form"} onSubmit={EditDeviceFormOnSubmit} >
                        <TextField
                            margin='normal'
                            name='name'
                            type="text"
                            value={editedDeviceData.name}
                            onChange={editDeviceData}
                            fullWidth
                            label="Name"
                            sx={{ mt: 2, mb: 1.5 }} />
                        <Button type='submit' fullWidth variant='contained' sx={{ mt: 2 }}>Save</Button>
                    </Box>
                }
            />

            <Grid sx={{ mb: 1 }} container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                </Grid>
                <Grid item>
                    <Stack spacing={1} direction="row">
                        <Button onClick={() => { setShowAddDeviceModal(true) }} ><AddIcon /> New</Button>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={12}>
                    <TextField
                        margin='normal'
                        name='name'
                        type="text"
                        fullWidth
                        label="Search"
                        sx={{ mt: 2, mb: 1.5 }} />
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row">
                {
                    userDevices?.map((device, index) => (
                        <Grid key={index} item xs={12} md={3}>
                            <Card>
                                <CardContent>
                                    <Grid container sx={{ mb: 2 }}>
                                        <Grid item xs={10}>
                                            <Typography sx={{ mt: 1.5 }}>{device.name}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <IconButton onClick={() => { EditDeviceButtonOnClick(device) }}><UpdateIcon /></IconButton>
                                        </Grid>
                                    </Grid>

                                    <Divider />
                                    <Typography sx={{ mt: 1.5 }}>Id: {device.deviceId}</Typography>
                                    <Typography sx={{ mt: 1.5 }}>Verification code: {device.confirmationCode}</Typography>
                                </CardContent>

                                <CardActions>
                                    <Button
                                        fullWidth
                                        type='button'
                                        variant='contained'
                                        sx={{ mt: 1, mb: 2 }}
                                        onClick={() => { UnpairButtonOnClick(device) }}
                                    >
                                        Unpair
                                    </Button>
                                </CardActions>

                            </Card>
                        </Grid>
                    ))
                }

            </Grid>
        </Container>
    );
};

export default Device;