import { Button, Card, CardActions, CardContent, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { customCategories } from '../../api/customCategories';
import { defaultCategories } from '../../api/defaultCategories';
import { CategoryModel, MenuModel } from '../../api/models/Menu.Model';
import { getAccessToken } from '../../utils/authHelper';
import AddIcon from '@mui/icons-material/Add';
import { ModalComponent } from '../../components/Modal';
import { Box } from '@mui/system';
import { useNotification } from '../../context/notification.context';
import { CreateCustomCategoryValidate } from '../../utils/formValidation';
import { userMenu } from '../../api/userMenu';
import { ConfirmCancelDialogComponent, ConfirmCancelDialogProps } from '../../components/ConfirmCancelDialog';

interface CategoryTabProps {
    menu: MenuModel | null;
    refreshMenu: () => void
}

interface CategoryData {
    name: string
}

export const CategoryTab: React.FC<CategoryTabProps> = ({ menu, refreshMenu }) => {
    const { getError, getSuccess } = useNotification();
    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = React.useState<boolean>(false);
    const [confirmCancelDialogProps, setconfirmCancelDialogProps] = React.useState<ConfirmCancelDialogProps>(
        {
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        }
    );
    const [categories, setCategories] = React.useState<CategoryModel[] | null>(null);
    const [showCreateCategoryModal, setShowCreateCategoryModal] = React.useState<boolean>(false);
    const [categoryData, setCataegoryData] = React.useState<CategoryData>(
        {
            name: ""
        });


    React.useEffect(() => {
        GetCategories();
    }, []);

    const GetCategories = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        let categoriesResponse: CategoryModel[] | null = [];

        await defaultCategories.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (categoriesResponse !== null) {
                    categoriesResponse = [...categoriesResponse, ...response.data.data]
                }
            }).
            catch((error) => {
                console.log(error);
            });

        await customCategories.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (categoriesResponse !== null)
                    categoriesResponse = [...categoriesResponse, ...response.data.data]

            }).
            catch((error) => {
                console.log(error);
            });

        setCategories(categoriesResponse);
    };

    const CreateCategoryModalOnClose = () => {
        setCataegoryData({
            name: ""
        });
        setShowCreateCategoryModal(false);
    };

    const updateNewCategoryData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCataegoryData({ ...categoryData, [e.target.name]: e.target.value });
    };

    const CreateCategoryFormOnSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            CreateCustomCategoryValidate.validate(categoryData).
                then(async () => {
                    const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                    customCategories.Add({
                        token: `Bearer ${token}`,
                        data: {
                            categoryName: categoryData.name.trim()
                        }
                    }).
                        then(async (response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Category created successfully.");
                                GetCategories();
                                CreateCategoryModalOnClose();
                            }
                            else {
                                getError("Failed to create category. Please try again or contact support.");
                            }
                        }).
                        catch((error) => {
                            getError("Failed to create category. Please try again or contact support.");
                        });

                }).
                catch((error) => {
                    getError(error.message);
                });
        }
        catch (error) {
            getError("Failed to create category. Please try again or contact support.");
        }
    };

    const AddCategoryToMenuButtonOnClick = async (category: CategoryModel) => {
        const categoryFound = menu?.categories.find(c => c.cloudId === category.id);

        if (menu?.id === undefined) {
            return;
        }

        if (categoryFound !== undefined) {
            getError("Category duplicated.");
            return;
        }

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        const categoryToAdd: CategoryModel = {
            id: '',
            categoryDefaultId: '',
            cloudId: category.id,
            isCustom: category.isCustom,
            categoryName: category.categoryName,
            categoryValue: category.categoryValue,
            products: [],
            promotions: [],
            taxes: [],
            toppings: []

        };

        userMenu.AddCategoryToMenu({
            token: `Bearer ${token}`,
            data: {
                menuId: menu.id,
                category: categoryToAdd
            }
        })
            .then(async (response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Category added successfully.");
                    await refreshMenu();
                }
                else {
                    getError(`Failed to add category to menu. Please try again or contact support.`);
                }
            })
            .catch((error) => {
                getError(`Failed to add category to menu. Please try again or contact support.`);
            });
    };

    const DeleteCategoryButtonOnClick = (category: CategoryModel) => {
        if (!category.isCustom) {
            getError("This category can't be deleted");
            return;
        }

        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete category";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that category?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteCategory(category.id) };

    };

    const DeleteCategory = async (categoryId: string) => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        customCategories.Delete({
            token: `Bearer ${token}`,
            id: categoryId
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Category deleted successfully.");
                    GetCategories();
                    CloseConfirmCancelDialog();
                }
                else{
                    getError("Failed to delete category. Please try again or contact support.");
                }
            })
            .catch((error) => {
                getError("Failed to delete category. Please try again or contact support.");
            });
    };

    const CloseConfirmCancelDialog = () => {
        setconfirmCancelDialogProps({
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        });

        setShowConfirmCancelDialog(false);
    };

    return (
        <Container>
            <ConfirmCancelDialogComponent
                IsOpen={showConfirmCancelDialog}
                Title={confirmCancelDialogProps.Title}
                MessageDialog={confirmCancelDialogProps.MessageDialog}
                CloseDialog={confirmCancelDialogProps.CloseDialog}
                ConfirmOption={confirmCancelDialogProps.ConfirmOption}
                CancelOption={confirmCancelDialogProps.CancelOption}
            />

            <ModalComponent
                Title='Create category'
                IsOpen={showCreateCategoryModal}
                CloseModal={CreateCategoryModalOnClose}
                MaxWidth={"md"}
                ModalContent={
                    <Box component={"form"} onSubmit={CreateCategoryFormOnSubmit}>
                        <Grid container >
                            <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label={"Name"}
                                    name={"name"}
                                    onChange={updateNewCategoryData}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                >
                                    Save
                                </Button>

                            </Grid>
                        </Grid>

                    </Box>
                }
            />

            <Grid container justifyContent="flex-end">
                <Button onClick={() => { setShowCreateCategoryModal(true); }}><AddIcon /> New</Button>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Search"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="stretch" sx={{ mt: 0.5 }}>
                {
                    categories?.map((category) => (
                        <Grid key={category.id} item xs={12} sm={6} md={3} sx={{ display: "flex" }}>
                            <Card sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",

                            }}>
                                <CardContent sx={{ height: "100%" }}>
                                    <Typography sx={{ mb: 1.5 }}>{category.categoryName}</Typography>
                                    <Divider />
                                    {category.isCustom ? <Typography>Custom</Typography> : <></>}
                                </CardContent>

                                <CardActions>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} >
                                            <Button
                                                onClick={() => { AddCategoryToMenuButtonOnClick(category) }}
                                                fullWidth
                                                variant="contained"
                                                size="small"

                                            >
                                                Add to menu
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => { DeleteCategoryButtonOnClick(category) }}
                                                fullWidth
                                                variant="outlined"
                                                size="small"

                                            >
                                                Delete
                                            </Button>
                                        </Grid>
                                    </Grid>


                                </CardActions>

                            </Card>


                        </Grid>
                    ))
                }
            </Grid>

        </Container>
    );
};