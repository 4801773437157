import { instance } from "./base.api";

const endponit = {
    GetAll: "api/Menu/Default/GetCategories"
}

export const defaultCategories = {
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
};