import { instance } from "./base.api";

const endponit = {
    Add: "api/Category/Custom/Add",
    GetAll: "api/Category/Custom/GetAllByAccountId",
    Delete: "api/Category/Custom/Delete"
}

interface CustomCategoryProp{
    categoryName: string
}

export const customCategories = {
    Add: function ({ token, data }: { token: string, data: CustomCategoryProp }) {
        return instance.post(endponit.Add, JSON.stringify(data) ,{
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
    Delete: function ({ token, id }: { token: string, id: string }) {
        return instance.delete(endponit.Delete + `/${id}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
};