import { Button, Container, Grid, Modal, Paper, Typography } from "@mui/material";
import React from "react";

type ModalProps = {
    Title: string;
    IsOpen: boolean;
    CloseModal: () => void
    MaxWidth: any;
    ModalContent: React.ReactNode | undefined;
};

export const ModalComponent: React.FC<ModalProps> = ({ ...props }) => {
    return (
        <Modal
            open={props.IsOpen}
            sx={{ overflow: "scroll" }}
        >
            <Container maxWidth={props.MaxWidth}>
                <Grid
                    container
                    direction={"column"}
                    alignItems="center"
                    justifyContent={"center"}
                    sx={{ minHeight: "100vh" }}
                >
                    <Grid item>
                        <Paper sx={{ mt: 3, mb: 3, padding: "1.2em", borderRadius: "0.5em" }}>
                            <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>{props.Title}</Typography>
                            <Grid container justifyContent={"right"}>
                                <Button onClick={props.CloseModal}>Close</Button>
                            </Grid>
                            {props.ModalContent}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Modal>
    );
};