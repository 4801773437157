import { Accordion, Box, Button, Card, CardActions, CardContent, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { CategoryModel, MenuModel, ProductModel } from '../../api/models/Menu.Model';
import { userMenu } from '../../api/userMenu';
import { getAccessToken } from '../../utils/authHelper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';
import { ConfirmCancelDialogComponent, ConfirmCancelDialogProps } from '../../components/ConfirmCancelDialog';
import { useNotification } from '../../context/notification.context';
import { ModalComponent } from '../../components/Modal';
import { DefaultPromotion } from '../../types/defaultPromotion.Model';
import { defaultPromotions } from '../../api/defaultPromotions';
import { customPromotions } from '../../api/customPromotions';
import { DefaultTopping } from '../../types/defaultTopping';
import { defaultToppings } from '../../api/defaultToppings';
import { customToppings } from '../../api/customToppings';
import { DefaultProductVariant } from '../../types/defaultProductVariant';
import { defaultProdcutVariant } from '../../api/defaultProductVariant';
import { customProductVariants } from '../../api/customProductVariant';
import { DefaultTaxModel } from '../../types/defaultTax.Model';
import { defaultTaxes } from '../../api/defaultTaxes';

interface AllMenuTabProps {
    menu: MenuModel | null;
    refreshMenu: () => void
}
interface EditedProductData {
    price: number,
    description: string,
}

export const AllMenuTab: React.FC<AllMenuTabProps> = ({ menu, refreshMenu }) => {
    const { getError, getSuccess } = useNotification();
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [promotions, setPromotions] = React.useState<DefaultPromotion[] | undefined>(undefined);
    const [toppings, setToppings] = React.useState<DefaultTopping[] | undefined>(undefined);
    const [variants, setVariants] = React.useState<DefaultProductVariant[] | undefined>(undefined);
    const [taxes, setTaxes] = React.useState<DefaultTaxModel[] | undefined>(undefined);
    const [selectedProduct, setSelectedProduct] = React.useState<ProductModel | undefined>(undefined);
    const [selectedCategory, setSelectedCategoty] = React.useState<CategoryModel | undefined>(undefined);
    const [showProductInfoModal, setShowProductInfoModal] = React.useState<boolean>(false);
    const [showAddPromotionToProductModal, setShowAddPromotionToProductModal] = React.useState<boolean>(false);
    const [showAddPromotionToCategoryModal, setShowAddPromotionToCategoryModal] = React.useState<boolean>(false);
    const [showAddPromotionToMenuModal, setShowAddPromotionToMenuModal] = React.useState<boolean>(false);
    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = React.useState<boolean>(false);
    const [showAddToppingToCategoryModal, setShowAddToppingToCategoryModal] = React.useState<boolean>(false);
    const [showAddVariantToProductModal, setShowAddVariantToProductModal] = React.useState<boolean>(false);
    const [showAddTaxToMenuModal, setShowAddTaxToMenuModal] = React.useState<boolean>(false);
    const [showAddTaxToCategoryModal, setShowAddTaxToCategoryModal] = React.useState<boolean>(false);
    const [showAddTaxToProductModal, setShowAddTaxToProductModal] = React.useState<boolean>(false);
    const [confirmCancelDialogProps, setconfirmCancelDialogProps] = React.useState<ConfirmCancelDialogProps>(
        {
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        }
    );
    const [productData, setProductData] = React.useState<EditedProductData>({
        price: 0,
        description: ""
    });

    React.useEffect(() => {
        refreshMenu();
    }, []);

    React.useEffect(() => {
        if (selectedProduct?.id !== undefined || selectedCategory?.id !== undefined) {
            const productFound = menu?.categories.find(c => c.id === selectedCategory?.id)?.products.find(p => p.id === selectedProduct?.id);

            if (productFound !== undefined) {
                setSelectedProduct(productFound);
            }
        }

        if (selectedCategory?.id !== undefined) {
            const categoryFound = menu?.categories.find(c => c.id === selectedCategory?.id);

            if (categoryFound !== undefined) {
                setSelectedCategoty(categoryFound);
            }
        }

    }, [menu]);

    const AccordionOnChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const DeleteCategoryButtonOnClick = (category: CategoryModel) => {
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete category";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that category?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteCategory(category.id) };
    };

    const CloseConfirmCancelDialog = () => {
        setconfirmCancelDialogProps({
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        });

        setShowConfirmCancelDialog(false);
    };

    const DeleteCategory = async (categoryId: string) => {
        const categoryFound = menu?.categories.find(c => c.id === categoryId);
        if (menu?.id === undefined) {
            getError("Menu not found");
            return;
        }

        if (categoryFound !== undefined) {
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            userMenu.DeleteCategoryFromMenu({
                token: `Bearer ${token}`,
                data: {
                    menuId: menu?.id,
                    categoryId: categoryId
                }
            })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Menu category deleted successfully.");
                        refreshMenu();
                        CloseConfirmCancelDialog();
                    }
                    else {
                        getError(`Failed to remove category from menu. Please try again or contact support.`);
                    }
                })
                .catch((error) => {
                    getError(`Failed to remove category from menu. Please try again or contact support.`);
                });

        }
        else {
            getError("Category not found");
        }
    };

    const DeleteProductFromMenuCategoryButtonOnClick = ({ product, category }: { product: ProductModel; category: CategoryModel }) => {
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete product";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that product?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteCategoryProduct({ product, category }) };
    };

    const DeleteCategoryProduct = async ({ product, category }: { product: ProductModel; category: CategoryModel }) => {
        try {

            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

            userMenu.DeleteProductFromMenuCategory({
                token: `Bearer ${token}`,
                data: {
                    menuId: menu?.id ?? "",
                    categoryId: category.id,
                    productId: product.id
                }
            })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Product deleted sucessfully.");
                        CloseConfirmCancelDialog();
                        refreshMenu();
                    }
                    else {
                        getError("Failed to delete product. Please try again or contact support.");
                    }

                })
                .catch((error) => {
                    getError("Failed to delete product. Please try again or contact support.");
                });

        }
        catch (error) {

        }
    };

    const OpenProductInfoModal = ({ product, category }: { product: ProductModel; category: CategoryModel }) => {
        setSelectedCategoty(category);
        setSelectedProduct(product);
        setProductData({ price: product.productPrice, description: product.description });
        setShowProductInfoModal(true);
    }

    const ProductInfoModalOnClose = () => {
        setSelectedCategoty(undefined);
        setSelectedProduct(undefined);
        setShowProductInfoModal(false);
        setProductData({ price: 0, description: "" });
    };

    const ProductDataOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProductData({ ...productData, [e.target.name]: e.target.value });
    };

    const AddPromotionToProductModalOnClose = () => {
        setShowAddPromotionToProductModal(false);
    };

    const AddPromotionToProductModalOnOpen = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        let promotionsResponse: DefaultPromotion[] | null = [];

        await defaultPromotions.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (promotionsResponse !== null) {
                        promotionsResponse = [...promotionsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        await customPromotions.GetAllByToApplyId({
            token: `Bearer ${token}`,
            id: 3
        }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (promotionsResponse !== null) {
                        promotionsResponse = [...promotionsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        setPromotions(promotionsResponse);

        setShowAddPromotionToProductModal(true);
    };

    const SelectPromotionToProductButtonOnClick = async (promotion: DefaultPromotion) => {
        if (menu?.id === undefined || selectedCategory?.id === undefined || selectedProduct?.id === undefined) {
            return;
        }

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        userMenu.AddPromotionToMenuProduct({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                categoryId: selectedCategory?.id,
                productId: selectedProduct?.id,
                promotion: promotion
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Promotion added successfully.");
                    refreshMenu();
                    AddPromotionToProductModalOnClose();
                }
                else {
                    getError("Failed to add promotion. Please try again or contact support.");
                }
            })
            .catch((error) => {
                getError("Failed to add promotion. Please try again or contact support.");
            });

    };

    const ProductPromotionExist = (id: string) => {
        const promotionFound = selectedProduct?.promotions?.find(p => p.cloudId === id);
        if (promotionFound === undefined) {
            return false;
        }

        return true;
    };

    const DeletePromotionFromProductButtonOnClick = (promotion: DefaultPromotion) => {
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete promotion";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that promotion?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeletePromotionFromProduct(promotion.id) };
    };

    const DeletePromotionFromProduct = async (id: string) => {
        if (menu?.id === undefined || selectedCategory?.id === undefined || selectedProduct?.id === undefined) {
            return;
        }

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        userMenu.DeletePromotionFromMenuProduct({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                categoryId: selectedCategory?.id,
                productId: selectedProduct?.id,
                cloudId: id
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Promotion deleted successfully.");
                    refreshMenu();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete promotion. Please try again or contact support.");
                }
            })
            .catch((erro) => {
                getError("Failed to delete promotion. Please try again or contact support.");
            });
    };

    const EditProductFormOnSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();

            if (selectedProduct === undefined) {
                getError("Failed to select product.");
                throw new Error();
            }

            if (productData.price <= 0) {
                getError("Set a price");
                throw new Error("");
            }

            selectedProduct.productPrice = productData.price.toString().trim() == "" ? 0 : productData.price;
            selectedProduct.description = productData.description;


            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            userMenu.EditProductFromMenuCategory({
                token: `Bearer ${token}`,
                data: {
                    menuId: menu?.id ?? "",
                    categoryId: selectedCategory?.id ?? "",
                    product: selectedProduct
                }
            })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Product edited sucessfully.");
                        ProductInfoModalOnClose();
                    }
                    else {
                        getError("Failed to edit product. Please try again or contact support.");
                    }

                })
                .catch((error) => {
                    getError("Failed to edit product. Please try again or contact support.");
                });

        }
        catch (error) {

        }
    };

    const AddPromotionToCategoryModalOnClose = () => {
        setShowAddPromotionToCategoryModal(false);
    };

    const AddPromotionToCategoryModalOnOpen = async (category: CategoryModel) => {
        setSelectedCategoty(category);
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        let promotionsResponse: DefaultPromotion[] | null = [];

        await defaultPromotions.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (promotionsResponse !== null) {
                        promotionsResponse = [...promotionsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        await customPromotions.GetAllByToApplyId({
            token: `Bearer ${token}`,
            id: 2
        }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (promotionsResponse !== null) {
                        promotionsResponse = [...promotionsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        setPromotions(promotionsResponse);

        setShowAddPromotionToCategoryModal(true);

    };

    const SelectPromotionToCategoryButtonOnClick = async (promotion: DefaultPromotion) => {
        if (menu?.id === undefined || selectedCategory?.id === undefined) {
            return;
        }
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        userMenu.AddPromotionToCategory({
            token: `Bearer ${token}`,
            data: {
                menuId: menu !== null ? menu.id : "",
                categoryId: selectedCategory !== undefined ? selectedCategory.id : "",
                promotion: {
                    cloudId: promotion.id,
                    promotionDefaultId: promotion.id,
                    promotionCustomId: promotion.id,
                    promotionName: promotion.promotionName,
                    promotionTypeIndex: promotion.promotionTypeIndex,
                    categories: [],
                    products: [],
                    discountValue: promotion.discountValue,
                    starts: promotion.starts,
                    ends: promotion.ends,
                    priority: promotion.priority,
                    allowOverlap: promotion.allowOverlap,
                    automatic: promotion.automatic,
                    amountProductsToPay: promotion.amountProductsToPay,
                    amountProductsToGet: promotion.amountProductsToGet,
                    daysOfTheWeek: promotion.daysOfTheWeek,
                    hoursOfTheDay: promotion.hoursOfTheDay,
                    isCustom: promotion.isCustom,
                    toApplyId: promotion.toApplyId
                }
            }
        }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Promotion added successfully.");
                    refreshMenu();
                    AddPromotionToCategoryModalOnClose();
                }
                else {
                    getError("Failed to add promotion. Please try again or contact support.");
                }


            }).catch((error) => {
                getError("Failed to add promotion. Please try again or contact support.");
            });

    };

    const CategoryPromotionExist = (id: string) => {
        const promotionFound = selectedCategory?.promotions?.find(p => p.cloudId === id);
        if (promotionFound === undefined) {
            return false;
        }

        return true;
    };

    const DeletePromotionFromCategoryButtonOnClick = (promotion: DefaultPromotion) => {
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete promotion";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that promotion?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeletePromotionFromCategory(promotion.id) };
    };

    const DeletePromotionFromCategory = async (promotionCloudId: string) => {
        if (menu?.id === undefined || selectedCategory?.id === undefined) {
            return;
        }

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        userMenu.DeletePromotionFromMenuCategory({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                categoryId: selectedCategory?.id,
                promotionCloudId: promotionCloudId
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Promotion deleted successfully.");
                    refreshMenu();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete promotion. Please try again or contact support.");
                }
            })
            .catch((erro) => {
                getError("Failed to delete promotion. Please try again or contact support.");
            });
    };

    const AddPromotionToMenuModalOnClose = () => {
        setShowAddPromotionToMenuModal(false);
    };

    const AddPromotionToMenuModalOnOpen = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        let promotionsResponse: DefaultPromotion[] | null = [];

        await defaultPromotions.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (promotionsResponse !== null) {
                        promotionsResponse = [...promotionsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        await customPromotions.GetAllByToApplyId({
            token: `Bearer ${token}`,
            id: 1
        }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (promotionsResponse !== null) {
                        promotionsResponse = [...promotionsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        setPromotions(promotionsResponse);
        setShowAddPromotionToMenuModal(true);
    };

    const SelectPromotionToMenuButtonOnClick = async (promotion: DefaultPromotion) => {
        try {

            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

            userMenu.AddPromotionToMenu({
                token: `Bearer ${token}`,
                data: {
                    menuId: menu !== null ? menu.id : "",
                    promotion: {
                        cloudId: promotion.id,
                        promotionDefaultId: promotion.id,
                        promotionCustomId: promotion.id,
                        promotionName: promotion.promotionName,
                        promotionTypeIndex: promotion.promotionTypeIndex,
                        categories: [],
                        products: [],
                        discountValue: promotion.discountValue,
                        starts: promotion.starts,
                        ends: promotion.ends,
                        priority: promotion.priority,
                        allowOverlap: promotion.allowOverlap,
                        automatic: promotion.automatic,
                        amountProductsToPay: promotion.amountProductsToPay,
                        amountProductsToGet: promotion.amountProductsToGet,
                        daysOfTheWeek: promotion.daysOfTheWeek,
                        hoursOfTheDay: promotion.hoursOfTheDay,
                        isCustom: promotion.isCustom,
                        toApplyId: promotion.toApplyId
                    }
                }
            }).
                then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Promotion added successfully.");
                        refreshMenu();
                        AddPromotionToMenuModalOnClose();
                    }
                    else {
                        getError("Failed to add promotion. Please try again or contact support.");
                    }


                }).catch((error) => {
                    getError("Failed to add promotion. Please try again or contact support.");
                });
        }
        catch (error) {
            getError("Failed to add promotion. Please try again or contact support.");
        }
    };

    const MenuPromotionExist = (id: string) => {
        const promotionFound = menu?.promotions?.find(p => p.cloudId === id);
        if (promotionFound === undefined) {
            return false;
        }

        return true;
    };

    const DeletePromotionFromMenuButtonOnClick = (promotion: DefaultPromotion) => {
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete promotion";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that promotion?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeletePromotionFromMenu(promotion.id) };
    };

    const DeletePromotionFromMenu = async (promotionCloudId: string) => {
        if (menu?.id === undefined) {
            return;
        }
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        userMenu.DeletePromotionFromMenu({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                promotionCloudId: promotionCloudId
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Promotion deleted successfully.");
                    refreshMenu();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete promotion. Please try again or contact support.");
                }
            })
            .catch((erro) => {
                getError("Failed to delete promotion. Please try again or contact support.");
            });
    };

    const AddToppingToCategoryModalOnOpen = async (category: CategoryModel) => {
        setSelectedCategoty(category);
        let toppingsResponse: DefaultTopping[] | null = [];
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        await defaultToppings.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (toppingsResponse !== null) {
                        toppingsResponse = [...toppingsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        await customToppings.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (toppingsResponse !== null) {
                        toppingsResponse = [...toppingsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        setToppings(toppingsResponse);

        setShowAddToppingToCategoryModal(true);
    };

    const AddToppingToCategoryModalOnClose = () => {
        setShowAddToppingToCategoryModal(false);
    };

    const AddToppingToCategoryFormOnSubmit = async (e: React.FormEvent<HTMLFormElement>, topping: DefaultTopping) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            price: { value: number };
        };

        const toppingPrice = target.price.value;

        if (toppingPrice <= 0) {
            getError("Set a price");
            return;
        }

        if (menu?.id === undefined || selectedCategory?.id === undefined) {
            return;
        }

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        userMenu.AddToppingToMenuCategory({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                categoryId: selectedCategory?.id,
                topping: {
                    toppingDefaultId: topping.id,
                    toppingTypeIndex: topping.toppingTypeIndex,
                    toppingName: topping.toppingName,
                    toppingPrice: toppingPrice,
                    isCustom: topping.isCustom,
                    cloudId: topping.id
                }
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Topping added successfully.");
                    refreshMenu();
                }
                else {
                    getError("Failed to add topping. Please try again or contact support.");
                }
            })
            .catch((error) => {
                getError("Failed to add topping. Please try again or contact support.");
            });

    };

    const ToppingExist = (id: string) => {
        const toppingFound = menu?.categories?.find(c => c.id === selectedCategory?.id)?.toppings?.find(t => t.cloudId === id);

        if (toppingFound === undefined) {
            return undefined;
        }

        return toppingFound.toppingPrice;
    };

    const DeleteToppingFromCategoryFormOnSubmit = (e: React.FormEvent<HTMLFormElement>, topping: DefaultTopping) => {
        e.preventDefault();
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete topping";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that topping?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteToppingFromCategory(topping.id) };
    };

    const DeleteToppingFromCategory = async (toppingCloudId: string) => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        userMenu.DeleteToppingFromMenuCategory({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id ?? "",
                categoryId: selectedCategory?.id ?? "",
                ToppingCloudId: toppingCloudId
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Topping deleted sucessfully.");
                    CloseConfirmCancelDialog();
                    refreshMenu();
                }
                else {
                    getError("Failed to delete topping. Please try again or contact support.");
                }

            })
            .catch((error) => {
                getError("Failed to delete topping. Please try again or contact support.");
            });
    };

    const AddVariantToProductModalOnOpen = async () => {
        let productVariantsResponse: DefaultProductVariant[] | null = [];

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        await defaultProdcutVariant.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (productVariantsResponse !== null) {
                        productVariantsResponse = [...productVariantsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        await customProductVariants.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (productVariantsResponse !== null) {
                        productVariantsResponse = [...productVariantsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        setVariants(productVariantsResponse);

        setShowAddVariantToProductModal(true);
    };

    const AddVariantToProductModalOnClose = () => {
        setShowAddVariantToProductModal(false);
    };

    const VariantExist = (id: string) => {
        const variantFound = selectedProduct?.variants?.find(v => v.cloudId === id);

        if (variantFound === undefined) {
            return undefined;
        }

        return variantFound.variantPrice;
    };

    const AddVariantToProductFormOnSubmit = async (e: React.FormEvent<HTMLFormElement>, variant: DefaultProductVariant) => {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            price: { value: number };
        };

        const variantPrice = target.price.value;

        if (variantPrice <= 0) {
            getError("Set a price");
            return;
        }

        if (menu?.id === undefined || selectedCategory?.id === undefined || selectedProduct?.id === undefined) {
            return;
        }

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        userMenu.AddVariantToMenuProduct({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                categoryId: selectedCategory?.id,
                productId: selectedProduct?.id,
                variant: {
                    variantDefaultId: variant.id,
                    cloudId: variant.id,
                    variantName: variant.variantName,
                    variantPrice: variantPrice,
                    subVariants: [],
                    isCustom: variant.isCustom,
                }
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Product variant added successfully.");
                    refreshMenu();
                }
                else {
                    getError("Failed to add product variant. Please try again or contact support.");
                }
            })
            .catch((error) => {
                getError("Failed to add product variant. Please try again or contact support.");
            });


    };

    const DeleteVariantFromProductFormOnSubmit = async (e: React.FormEvent<HTMLFormElement>, variant: DefaultProductVariant) => {
        e.preventDefault();
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete product variant";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that product variant?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteVariantFromProduct(variant.id) };
    };

    const DeleteVariantFromProduct = async (id: string) => {
        if (menu?.id === undefined || selectedCategory?.id === undefined || selectedProduct?.id === undefined) {
            return;
        }

        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        userMenu.DeleteVariantFromMenuProduct({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                categoryId: selectedCategory?.id,
                productId: selectedProduct?.id,
                variantCloudId: id
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Product variant deleted successfully.");
                    refreshMenu();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete product variant. Please try again or contact support.");
                }
            })
            .catch((erro) => {
                getError("Failed to delete product variant. Please try again or contact support.");
            });
    };

    const AddTaxToMenuModalOnClose = () => {
        setShowAddTaxToMenuModal(false);
    };

    const AddTaxToMenuModalOnOpen = () => {
        GetTaxes();
        setShowAddTaxToMenuModal(true);
    };

    const GetTaxes = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        defaultTaxes.GetAllByRegionId({
            token: `Bearer ${token}`,
            regionId: "001001001001"
        }).
            then((response) => {
                setTaxes(response.data.data);
            }).
            catch((error) => {
                console.log(error);
            });
    };

    const AddTaxToMenuFormOnSubmit = async (e: React.FormEvent, tax: DefaultTaxModel) => {
        try {
            e.preventDefault();
            if (menu?.id === undefined) {
                return;
            }
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

            userMenu.AddTaxToMenu({
                token: `Bearer ${token}`,
                data: {
                    menuId: menu?.id,
                    tax: {
                        taxDefaultId: tax.id,
                        cloudId: tax.id,
                        taxName: tax.taxName,
                        taxValue: tax.taxValue,
                        isCustom: tax.isCustom,
                    }
                }
            })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Tax added successfully.");
                        refreshMenu();
                    }
                    else {
                        getError("Failed to add tax. Please try again or contact support.");
                    }
                })
                .catch((error) => {
                    getError("Failed to add tax. Please try again or contact support.");
                });

        }
        catch (error) {
            getError("Failed to add tax. Please try again or contact support.");
        }
    };

    const DeleteTaxFromMenuFormOnSubmit = (e: React.FormEvent, tax: DefaultTaxModel) => {
        e.preventDefault();
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete tax";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that tax?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteTaxFromMenu(tax.id) };
    };

    const DeleteTaxFromMenu = async (taxCloudId: string) => {
        if (menu?.id === undefined) {
            return;
        }
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        userMenu.DeleteTaxFromMenu({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                taxCloudId: taxCloudId
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Tax deleted successfully.");
                    refreshMenu();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete tax. Please try again or contact support.");
                }
            })
            .catch((erro) => {
                getError("Failed to delete tax. Please try again or contact support.");
            });
    };

    const MenuTaxExist = (id: string) => {
        const taxFound = menu?.taxes.find(t => t.cloudId === id);
        if (taxFound === undefined) {
            return false;
        }

        return true;
    };

    const AddTaxToCategoryModalOnClose = () => {
        setShowAddTaxToCategoryModal(false);
    };

    const AddTaxToCategoryModalOnOpen = (category: CategoryModel) => {
        setSelectedCategoty(category);
        GetTaxes();
        setShowAddTaxToCategoryModal(true);
    };

    const CategoryTaxExist = (id: string) => {
        const taxFound = selectedCategory?.taxes.find(t => t.cloudId === id);
        if (taxFound === undefined) {
            return false;
        }

        return true;
    };

    const DeleteTaxFromCategoryFormOnSubmit = (e: React.FormEvent, tax: DefaultTaxModel) => {
        e.preventDefault();
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete tax";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that tax?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteTaxFromCategory(tax.id) };

    };

    const DeleteTaxFromCategory = async (taxCloudId: string) => {
        if (menu?.id === undefined || selectedCategory?.id === undefined) {
            return;
        }
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        userMenu.DeleteTaxFromMenuCategory({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                categoryId: selectedCategory?.id,
                taxCloudId: taxCloudId
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Tax deleted successfully.");
                    refreshMenu();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete tax. Please try again or contact support.");
                }
            })
            .catch((erro) => {
                getError("Failed to delete tax. Please try again or contact support.");
            });
    };

    const AddTaxToCategoryFormOnSubmit = async (e: React.FormEvent, tax: DefaultTaxModel) => {
        try {
            e.preventDefault();
            if (menu?.id === undefined || selectedCategory?.id === undefined) {
                return;
            }
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

            userMenu.AddTaxToCategory({
                token: `Bearer ${token}`,
                data: {
                    menuId: menu?.id,
                    categoryId: selectedCategory?.id,
                    tax: {
                        taxDefaultId: tax.id,
                        cloudId: tax.id,
                        taxName: tax.taxName,
                        taxValue: tax.taxValue,
                        isCustom: tax.isCustom,
                    }
                }
            })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Tax added successfully.");
                        refreshMenu();
                    }
                    else {
                        getError("Failed to add tax. Please try again or contact support.");
                    }
                })
                .catch((error) => {
                    getError("Failed to add tax. Please try again or contact support.");
                });

        }
        catch (error) {
            getError("Failed to add tax. Please try again or contact support.");
        }
    };

    const AddTaxToProductModalOnClose = () => {
        setShowAddTaxToProductModal(false);
    };

    const AddTaxToProductModalOnOpen = () => {
        GetTaxes();
        setShowAddTaxToProductModal(true);
    };

    const ProductTaxExist = (id: string) => {
        const taxFound = selectedProduct?.taxes.find(t => t.cloudId === id);
        if (taxFound === undefined) {
            return false;
        }

        return true;
    };

    const AddTaxToProductFormOnSubmit = async (e: React.FormEvent, tax: DefaultTaxModel) => {
        try {
            e.preventDefault();
            if (menu?.id === undefined || selectedCategory?.id === undefined || selectedProduct?.id === undefined) {
                return;
            }

            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

            userMenu.AddTaxToMenuProduct({
                token: `Bearer ${token}`,
                data: {
                    menuId: menu?.id,
                    categoryId: selectedCategory?.id,
                    productId: selectedProduct?.id,
                    tax: {
                        taxDefaultId: tax.id,
                        cloudId: tax.id,
                        taxName: tax.taxName,
                        taxValue: tax.taxValue,
                        isCustom: tax.isCustom,
                    }
                }
            })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Tax added successfully.");
                        refreshMenu();
                    }
                    else {
                        getError("Failed to add tax. Please try again or contact support.");
                    }
                })
                .catch((error) => {
                    getError("Failed to add tax. Please try again or contact support.");
                });

        }
        catch (error) {
            getError("Failed to add tax. Please try again or contact support.");
        }
    };

    const DeleteTaxFromProductFormOnSubmit = (e: React.FormEvent, tax: DefaultTaxModel) => {
        e.preventDefault();
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete tax";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that tax?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteTaxFromProduct(tax.id) };
    };

    const DeleteTaxFromProduct = async (taxCloudId: string) => {
        if (menu?.id === undefined || selectedCategory?.id === undefined || selectedProduct?.id === undefined) {
            return;
        }
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        userMenu.DeleteTaxFromMenuProduct({
            token: `Bearer ${token}`,
            data: {
                menuId: menu?.id,
                categoryId: selectedCategory?.id,
                productId: selectedProduct?.id,
                taxCloudId: taxCloudId
            }
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Tax deleted successfully.");
                    refreshMenu();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete tax. Please try again or contact support.");
                }
            })
            .catch((erro) => {
                getError("Failed to delete tax. Please try again or contact support.");
            });
    };

    return (
        <>
            <ConfirmCancelDialogComponent
                IsOpen={showConfirmCancelDialog}
                Title={confirmCancelDialogProps.Title}
                MessageDialog={confirmCancelDialogProps.MessageDialog}
                CloseDialog={confirmCancelDialogProps.CloseDialog}
                ConfirmOption={confirmCancelDialogProps.ConfirmOption}
                CancelOption={confirmCancelDialogProps.CancelOption}
            />

            <ModalComponent
                Title={selectedProduct?.productName ?? ''}
                IsOpen={showProductInfoModal}
                CloseModal={ProductInfoModalOnClose}
                MaxWidth={'md'}
                ModalContent={
                    <Box component={"form"} onSubmit={EditProductFormOnSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography>Promotions: {selectedProduct?.promotions?.length ?? 0}<IconButton onClick={AddPromotionToProductModalOnOpen}><AddIcon /></IconButton></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography>Taxes: {selectedProduct?.taxes?.length ?? 0}<IconButton onClick={AddTaxToProductModalOnOpen}><AddIcon /></IconButton></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography>Variants: {selectedProduct?.variants?.length ?? 0}<IconButton onClick={AddVariantToProductModalOnOpen}><AddIcon /></IconButton></Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Category"
                                    value={selectedCategory?.categoryName}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Price"
                                    name="price"
                                    type={"number"}

                                    value={productData.price}
                                    onChange={ProductDataOnChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Description"
                                    name="description"
                                    value={productData.description}
                                    onChange={ProductDataOnChange}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                >
                                    Save
                                </Button>

                            </Grid>



                        </Grid>

                    </Box>
                }
            />

            <ModalComponent
                Title='Product promotions'
                IsOpen={showAddPromotionToProductModal}
                CloseModal={AddPromotionToProductModalOnClose}
                MaxWidth={'md'}
                ModalContent={

                    <Grid container spacing={2} >
                        {
                            promotions?.map((promotion) => (
                                <Grid key={promotion.id} item xs={12} md={promotions.length > 1 ? 6 : 12} >
                                    <Card >
                                        <CardContent >
                                            <Typography sx={{ mb: 1.5 }}>{promotion.promotionName}</Typography>
                                            <Divider />
                                            <Typography>Apply to {promotion.toApplyId === 1 ? "menu" : promotion.toApplyId === 2 ? "category" : promotion.toApplyId === 3 ? "product" : "undefined"}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                variant={ProductPromotionExist(promotion.id) ? "outlined" : "contained"}
                                                fullWidth
                                                onClick={() => {
                                                    ProductPromotionExist(promotion.id) ?
                                                        DeletePromotionFromProductButtonOnClick(promotion)
                                                        :
                                                        SelectPromotionToProductButtonOnClick(promotion)
                                                }}
                                            >
                                                {ProductPromotionExist(promotion.id) ? <>Delete</> : <>Select</>}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>

                            ))
                        }
                    </Grid>


                }
            />

            <ModalComponent
                Title='Category promotions'
                IsOpen={showAddPromotionToCategoryModal}
                CloseModal={AddPromotionToCategoryModalOnClose}
                MaxWidth={'md'}
                ModalContent={

                    <Grid container spacing={2} >
                        {
                            promotions?.map((promotion) => (
                                <Grid key={promotion.id} item xs={12} md={promotions.length > 1 ? 6 : 12} >
                                    <Card >
                                        <CardContent >
                                            <Typography sx={{ mb: 1.5 }}>{promotion.promotionName}</Typography>
                                            <Divider />
                                            <Typography>Apply to {promotion.toApplyId === 1 ? "menu" : promotion.toApplyId === 2 ? "category" : promotion.toApplyId === 3 ? "product" : "undefined"}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                variant={CategoryPromotionExist(promotion.id) ? "outlined" : "contained"}
                                                fullWidth
                                                onClick={() => {
                                                    CategoryPromotionExist(promotion.id) ?
                                                        DeletePromotionFromCategoryButtonOnClick(promotion) :
                                                        SelectPromotionToCategoryButtonOnClick(promotion)
                                                }}
                                            >
                                                {CategoryPromotionExist(promotion.id) ?
                                                    <>Delete</> :
                                                    <>Select</>
                                                }
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>

                            ))
                        }
                    </Grid>

                }
            />

            <ModalComponent
                Title='Menu promotions'
                IsOpen={showAddPromotionToMenuModal}
                CloseModal={AddPromotionToMenuModalOnClose}
                MaxWidth={'md'}
                ModalContent={
                    <Grid container spacing={2} >
                        {
                            promotions?.map((promotion) => (
                                <Grid key={promotion.id} item xs={12} md={promotions.length > 1 ? 6 : 12} >
                                    <Card >
                                        <CardContent >
                                            <Typography sx={{ mb: 1.5 }}>{promotion.promotionName}</Typography>
                                            <Divider />
                                            <Typography>Apply to {promotion.toApplyId === 1 ? "menu" : promotion.toApplyId === 2 ? "category" : promotion.toApplyId === 3 ? "product" : "undefined"}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                variant={MenuPromotionExist(promotion.id) ? "outlined" : "contained"}
                                                fullWidth
                                                onClick={() => {
                                                    MenuPromotionExist(promotion.id) ?
                                                        DeletePromotionFromMenuButtonOnClick(promotion)
                                                        : SelectPromotionToMenuButtonOnClick(promotion)
                                                }}
                                            >
                                                {
                                                    MenuPromotionExist(promotion.id) ?
                                                        <>Delete</> :
                                                        <>Select</>
                                                }
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>

                            ))
                        }
                    </Grid>
                }
            />

            <ModalComponent
                Title='Toppings'
                IsOpen={showAddToppingToCategoryModal}
                CloseModal={AddToppingToCategoryModalOnClose}
                MaxWidth={"xl"}
                ModalContent={
                    <Grid container spacing={2}>
                        {
                            toppings?.map((topping) => (
                                <Grid key={topping.id} item xs={12} md={toppings.length > 1 ? 6 : 12} xl={toppings.length > 1 ? 3 : 12}>
                                    <Box component="form" noValidate onSubmit={(e) => {
                                        ToppingExist(topping.id) ?
                                            DeleteToppingFromCategoryFormOnSubmit(e, topping) :
                                            AddToppingToCategoryFormOnSubmit(e, topping)
                                    }} >
                                        <Card>
                                            <CardContent>
                                                <Typography sx={{ mb: 1.5 }}>{topping.toppingName}</Typography>
                                                <Divider />
                                                {/* {topping.isCustom ? <Typography>Custom</Typography> : <></>} */}
                                                {
                                                    ToppingExist(topping.id) === undefined ?
                                                        <TextField
                                                            fullWidth
                                                            name='price'
                                                            label={"Price"}
                                                            type={"number"}
                                                        />
                                                        :
                                                        <Typography>Price: ${ToppingExist(topping.id)}</Typography>
                                                }

                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    variant={ToppingExist(topping.id) ? "outlined" : "contained"}
                                                    fullWidth
                                                    type='submit'
                                                >
                                                    {
                                                        ToppingExist(topping.id) ?
                                                            <>Delete</> :
                                                            <>Select</>
                                                    }
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Grid>

                            ))
                        }
                    </Grid>
                }
            />

            <ModalComponent
                Title='Product variant'
                IsOpen={showAddVariantToProductModal}
                CloseModal={AddVariantToProductModalOnClose}
                MaxWidth={"xl"}
                ModalContent={
                    <Grid container spacing={2}>
                        {
                            variants?.map((variant) => (
                                <Grid key={variant.id} item xs={12} md={variants.length > 1 ? 6 : 12} xl={variants.length > 1 ? 3 : 12}>
                                    <Box component="form" noValidate onSubmit={(e) => {
                                        VariantExist(variant.id) ?
                                            DeleteVariantFromProductFormOnSubmit(e, variant) :
                                            AddVariantToProductFormOnSubmit(e, variant)
                                    }} >
                                        <Card>
                                            <CardContent>
                                                <Typography sx={{ mb: 1.5 }}>{variant.variantName}</Typography>
                                                <Divider />
                                                {/* {topping.isCustom ? <Typography>Custom</Typography> : <></>} */}
                                                {
                                                    VariantExist(variant.id) === undefined ?
                                                        <TextField
                                                            fullWidth
                                                            name='price'
                                                            label={"Price"}
                                                            type={"number"}
                                                        />
                                                        :
                                                        <Typography>Price: ${VariantExist(variant.id)}</Typography>
                                                }

                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    variant={VariantExist(variant.id) ? "outlined" : "contained"}
                                                    fullWidth
                                                    type='submit'
                                                >
                                                    {
                                                        VariantExist(variant.id) ?
                                                            <>Delete</> :
                                                            <>Select</>
                                                    }
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Grid>

                            ))
                        }
                    </Grid>
                }
            />

            <ModalComponent
                Title='Menu taxes'
                IsOpen={showAddTaxToMenuModal}
                CloseModal={AddTaxToMenuModalOnClose}
                MaxWidth={"md"}
                ModalContent={
                    <Grid container spacing={2}>
                        {
                            taxes?.map((tax) => (
                                <Grid key={tax.id} item xs={12} md={taxes.length > 1 ? 6 : 12} xl={taxes.length > 1 ? 3 : 12}>
                                    <Box component="form" noValidate onSubmit={(e) => {
                                        MenuTaxExist(tax.id) ?
                                            DeleteTaxFromMenuFormOnSubmit(e, tax) :
                                            AddTaxToMenuFormOnSubmit(e, tax)
                                    }} >
                                        <Card>
                                            <CardContent>
                                                <Typography sx={{ mb: 1.5 }}>{tax.taxName}</Typography>
                                                <Divider />
                                                <Typography sx={{ mb: 1.5 }}>{tax.taxValue + "%"}</Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    variant={MenuTaxExist(tax.id) ? "outlined" : "contained"}
                                                    fullWidth
                                                    type='submit'
                                                >
                                                    {
                                                        MenuTaxExist(tax.id) ?
                                                            <>Delete</> :
                                                            <>Select</>
                                                    }
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Grid>

                            ))
                        }
                    </Grid>
                }
            />

            <ModalComponent
                Title='Category taxes'
                IsOpen={showAddTaxToCategoryModal}
                CloseModal={AddTaxToCategoryModalOnClose}
                MaxWidth={"md"}
                ModalContent={
                    <Grid container spacing={2}>
                        {
                            taxes?.map((tax) => (
                                <Grid key={tax.id} item xs={12} md={taxes.length > 1 ? 6 : 12} xl={taxes.length > 1 ? 3 : 12}>
                                    <Box component="form" noValidate onSubmit={(e) => {
                                        CategoryTaxExist(tax.id) ?
                                            DeleteTaxFromCategoryFormOnSubmit(e, tax) :
                                            AddTaxToCategoryFormOnSubmit(e, tax)
                                    }} >
                                        <Card>
                                            <CardContent>
                                                <Typography sx={{ mb: 1.5 }}>{tax.taxName}</Typography>
                                                <Divider />
                                                <Typography sx={{ mb: 1.5 }}>{tax.taxValue + "%"}</Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    variant={CategoryTaxExist(tax.id) ? "outlined" : "contained"}
                                                    fullWidth
                                                    type='submit'
                                                >
                                                    {
                                                        CategoryTaxExist(tax.id) ?
                                                            <>Delete</> :
                                                            <>Select</>
                                                    }
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Grid>

                            ))
                        }
                    </Grid>
                }
            />

            <ModalComponent
                Title='Product taxes'
                IsOpen={showAddTaxToProductModal}
                CloseModal={AddTaxToProductModalOnClose}
                MaxWidth={"md"}
                ModalContent={
                    <Grid container spacing={2}>
                        {
                            taxes?.map((tax) => (
                                <Grid key={tax.id} item xs={12} md={taxes.length > 1 ? 6 : 12} xl={taxes.length > 1 ? 3 : 12}>
                                    <Box component="form" noValidate onSubmit={(e) => {
                                        ProductTaxExist(tax.id) ?
                                            DeleteTaxFromProductFormOnSubmit(e, tax) :
                                            AddTaxToProductFormOnSubmit(e, tax)
                                    }} >
                                        <Card>
                                            <CardContent>
                                                <Typography sx={{ mb: 1.5 }}>{tax.taxName}</Typography>
                                                <Divider />
                                                <Typography sx={{ mb: 1.5 }}>{tax.taxValue + "%"}</Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    variant={ProductTaxExist(tax.id) ? "outlined" : "contained"}
                                                    fullWidth
                                                    type='submit'
                                                >
                                                    {
                                                        ProductTaxExist(tax.id) ?
                                                            <>Delete</> :
                                                            <>Select</>
                                                    }
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Grid>

                            ))
                        }
                    </Grid>
                }
            />

            <Typography>Menu promotions: {menu?.promotions?.length ?? 0}<IconButton onClick={() => { AddPromotionToMenuModalOnOpen() }}><AddIcon /></IconButton></Typography>
            <Typography>Menu taxes: {menu?.taxes?.length ?? 0}<IconButton onClick={() => { AddTaxToMenuModalOnOpen() }}><AddIcon /></IconButton></Typography>

            {
                menu?.categories?.map((category, index) => (
                    <Accordion key={category.id} expanded={expanded === `panel${index}`} onChange={AccordionOnChange(`panel${index}`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography>
                                {
                                    category.categoryName
                                }
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container justifyContent="flex-end">
                                <Grid item xs={11}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography>Category promotions: {category.promotions?.length ?? 0}<IconButton onClick={() => { AddPromotionToCategoryModalOnOpen(category) }}><AddIcon /></IconButton></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Category taxes: {category.taxes?.length ?? 0}<IconButton onClick={() => { AddTaxToCategoryModalOnOpen(category) }}><AddIcon /></IconButton></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Category toppings: {category.toppings?.length ?? 0}<IconButton onClick={() => { AddToppingToCategoryModalOnOpen(category) }}><AddIcon /></IconButton></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={1}>
                                    <Grid container justifyContent="flex-end">
                                        <IconButton onClick={() => { DeleteCategoryButtonOnClick(category) }}><DeleteIcon /></IconButton>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid container spacing={2} direction="row" alignItems="stretch" sx={{ mt: 0.5 }}>
                                {
                                    category.products.map((product) => (
                                        <Grid key={product.id} item xs={12} sm={6} md={3} sx={{ display: "flex" }}>
                                            <Card sx={{
                                                height: "100%",
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",

                                            }}>
                                                <CardContent sx={{ height: "100%" }}>
                                                    <Typography sx={{ mb: 1.5 }}>{product.productName}</Typography>

                                                    <Divider />

                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={2}>
                                                            <IconButton onClick={() => { OpenProductInfoModal({ product, category }) }}><UpdateIcon /></IconButton>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <IconButton onClick={() => { DeleteProductFromMenuCategoryButtonOnClick({ product, category }) }}><DeleteIcon /></IconButton>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography>Description:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography>{product.description}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography>Price:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography>${product.productPrice}</Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item>
                                                            {product.isCustom ? <Typography variant='caption'>This product is custom</Typography> : <></>}
                                                        </Grid>
                                                    </Grid>

                                                </CardContent>

                                            </Card>


                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                ))
            }
        </>
    );
};