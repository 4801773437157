import { Box, Button, Card, CardActions, CardContent, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { user } from "../api/user";
import { userMenu } from "../api/userMenu";
import { getAccessToken, SignOut } from "../utils/authHelper";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useNotification } from "../context/notification.context";
import { AddMainerAccountFormValidate, CompleteAccountInfo, CompleteBusinessInfo } from "../utils/formValidation";
import { UserModel } from "../api/models/User.Model";
import { business } from "../api/business";
import { BusinessType } from "../api/models/BusinessType.Model";
import { defaultMenu } from "../api/defaultMenu";
import { MenuModel } from "../api/models/Menu.Model";
import { accountMember } from "../api/accountMember";
import { ModalComponent } from "../components/Modal";

interface AccountInterface {
    email: string,
    password: string,
    confirmPassword: string,
    name: string,
    lastname: string,
    birthdate: string
}
interface BusinessInterface {
    name: string,
    address: string,
    phone: number,
    postalCode: string,
    type: number
}

export const AccountInformationCompleted = () => {
    const [userInfoNotFoundModalIsOpen, setUserInfoNotFoundModalIsOpen] = React.useState<boolean>(false);
    const [businessInfoNotFoundModalIsOpen, setBusinessInfoNotFoundModalIsOpen] = React.useState<boolean>(false);
    const [userMenuNotFoundModalIsOpen, setUserMenuNotFoundModalIsOpen] = React.useState<boolean>(false);
    const [businessTypes, setBusinessTypes] = React.useState<BusinessType[] | undefined>(undefined);
    const [businessType, setBusinessType] = React.useState<string>('');
    const [birthdate, setbirthdate] = React.useState<Dayjs | null>(
        dayjs('2018-01-01T00:00:00.000Z'),
    );

    const [defaultMenus, setDefaultMenus] = React.useState<MenuModel[] | undefined>();
    const navigate = useNavigate();
    const { getError, getSuccess } = useNotification();

    React.useEffect(() => {
        ValidateAccountInfoCompleted();
        GetBusinessTypes();
    }, []);

    const GetBusinessTypes = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        business.GetBusinessTypes({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    setBusinessTypes(response.data.data);
                }
            }).
            catch((error) => {
                console.log(error);
            });
    };

    const ValidateAccountInfoCompleted = async () => {
        const accountInfoFound: UserModel | undefined = await ValidateAccountInfoExist();
        const mainerAccountExist: boolean = await ValidateMainerAccountExist();

        if (!mainerAccountExist) {
            setAddMainerAccountModalIsOpen(true);
            return;
        }

        if (accountInfoFound === undefined) {
            setAddAccountInfoModalIsOpen(true);
            return;
        }

        if (accountInfoFound?.business === null || accountInfoFound?.business.id === null) {
            setAddBusinessInfoModalIsOpen(true);
            return;
        }

        const userHasMenu: boolean = await ValidateUserHasMenu();
        if (!userHasMenu) {
            if (accountInfoFound?.business !== undefined) {
                GetMenusByPostalCodeAndType(accountInfoFound?.business.postalCode, accountInfoFound?.business.type);
            }

            setSelectMenuModalIsOpen(true);
            return;
        }
    };

    const GetMenusByPostalCodeAndType = async (postalCode: string, type: number) => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        defaultMenu.GetByPostalCodeAndType({
            token: `Bearer ${token}`,
            data: {
                postalCode: postalCode,
                type: type
            }
        }).
            then((response) => {

                if (response.data.statusCode === 200) {
                    setDefaultMenus(response.data.data);
                }

            }).
            catch((error) => {
                console.log(error);
            })
    };

    const handelCancelButtonOnClick = () => {
        SignOut();
        navigate("/SignIn");
    };

    const handleBusinessTypeChange = (event: SelectChangeEvent) => {
        setBusinessType(event.target.value);
    };

    //#region ACCOUNT INFO
    interface AccountProps {
        email: string;
        password: string;
        confirmPassword: string;
        name: string;
        lastname: string;
        birthdate: string;
    }
    const [addAccountInfoModalIsOpen, setAddAccountInfoModalIsOpen] = React.useState<boolean>(false);
    const [accountData, setAccountData] = React.useState<AccountProps>({
        email: "",
        password: "",
        confirmPassword: "",
        name: "",
        lastname: "",
        birthdate: ""
    });
    const ValidateAccountInfoExist: () => Promise<UserModel | undefined> = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        return await user.GetByAccountId({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode !== 200) {
                    return undefined;
                }
                else {
                    const data: UserModel = response.data.data;
                    return data;
                }
            }).
            catch((error) => {
                return undefined;
            });

    };
    const AddAccountInfoFormInputsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAccountData({ ...accountData, [e.target.name]: e.target.value });
    };
    const AddAccountInfoModalOnClose = () => {
        setAddAccountInfoModalIsOpen(false);
        ValidateAccountInfoCompleted();
    };
    const AddAccountInfoFormOnSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            accountData.birthdate = birthdate?.format('DD/MM/YYYY') ?? "";
            CompleteAccountInfo.validate(accountData)
                .then(async () => {
                    const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                    user.AddWithAccountMainerMember({
                        token: `Bearer ${token}`,
                        data: {
                            name: accountData.name,
                            familyName: accountData.lastname,
                            address: "",
                            birthdate: accountData.birthdate,
                            password: accountData.password
                        }
                    }).
                        then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Account info saved successfully.");
                                AddAccountInfoModalOnClose();
                            }
                            else {
                                getError("Failed to save account info. Please try again or contact support.");
                            }
                        }).
                        catch((error) => {
                            getError("Failed to save account info. Please try again or contact support.");
                        });

                })
                .catch((error) => {
                    getError(error.message);
                });
        }
        catch (error) {
            getError("Failed to save account info. Please try again or contact support.");
        }
    };
    //#endregion

    //#region MAINER ACCOUNT INFO
    interface MainerAccountProps {
        password: string;
        confirmPassword: string;
    }
    const [addMainerAccountModalIsOpen, setAddMainerAccountModalIsOpen] = React.useState<boolean>(false);
    const [mainerAccountData, setMainerAccountData] = React.useState<MainerAccountProps>({
        password: "",
        confirmPassword: ""
    });
    const ValidateMainerAccountExist: () => Promise<boolean> = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        return await accountMember.mainAccountMemberExists({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode !== 200) {
                    return false;
                }
                else {
                    return true;
                }
            }).
            catch((error) => {
                return false;
            });
    };
    const AddMainerAccountFormInputsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMainerAccountData({ ...mainerAccountData, [e.target.name]: e.target.value });
    };
    const AddMainerAccountModalOnClose = () => {
        setAddMainerAccountModalIsOpen(false);
        ValidateAccountInfoCompleted();
    };
    const AddMainerAccountFormOnSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();

            AddMainerAccountFormValidate.validate(mainerAccountData)
                .then(async () => {
                    const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                    accountMember.createMainAccountMember({
                        token: `Bearer ${token}`,
                        data: {
                            password: mainerAccountData.password
                        }
                    })
                        .then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Info saved successfully.");
                                AddMainerAccountModalOnClose();
                            }
                            else {
                                getError("Failed to save info. Please try again or contact support.");
                            }
                        })
                        .catch((error) => {
                            getError("Failed to save info. Please try again or contact support.");
                        });

                })
                .catch((error) => {
                    getError(error.message);
                });

        }
        catch (error) {
            getError("Failed to save info. Please try again or contact support.");
        }
    };

    //#endregion

    //#region BUSINESS INFO
    interface BusinessProps {
        name: string;
        address: string;
        phone: number;
        postalCode: string;
        type: number;
    }
    const [addBusinessInfoModalIsOpen, setAddBusinessInfoModalIsOpen] = React.useState<boolean>(false);
    const [businessData, setBusinessData] = React.useState<BusinessProps>({
        name: "",
        address: "",
        phone: 0,
        postalCode: "",
        type: 0
    });
    const AddBusinessInfoFormInputsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBusinessData({ ...businessData, [e.target.name]: e.target.value });
    };
    const AddBusinessInfoModalOnClose = () => {
        setAddBusinessInfoModalIsOpen(false);
        ValidateAccountInfoCompleted();
    };
    const AddBusinessInfoFormOnSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            businessData.type = businessType === "" ? 0 : parseInt(businessType);
            CompleteBusinessInfo.validate(businessData).
                then(async () => {
                    const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                    business.Add({
                        token: `Bearer ${token}`,
                        data: {
                            name: businessData.name,
                            type: businessData.type,
                            logo: "",
                            address: businessData.address,
                            phone: businessData.phone,
                            postalCode: businessData.postalCode,
                        }
                    })
                        .then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Account info saved successfully.");
                                AddBusinessInfoModalOnClose();
                            }
                            else {
                                getError("Failed to save business info. Please try again or contact support.");
                            }
                        })
                        .catch((error) => {
                            getError("Failed to save business info. Please try again or contact support.");
                        });

                })
                .catch((error) => {
                    getError(error.message);
                });

        }
        catch (error) {
            getError("Failed to save business info. Please try again or contact support.");
        }
    };
    //#endregion

    //#region SELECT MENU
    const [selectMenuModalIsOpen, setSelectMenuModalIsOpen] = React.useState<boolean>(false);
    const ValidateUserHasMenu: () => Promise<boolean> = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        return await userMenu.GetByUserId({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode !== 200) {
                    return false;
                }
                else {
                    return true;
                }
            }).
            catch((error) => {
                return false;
            });

    };
    const SelectMenuModalOnClose = () => {
        setSelectMenuModalIsOpen(false);
        ValidateAccountInfoCompleted();
    };
    const SelectMenuButtonOnClick = async (menuId: string) => {
        try {
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            defaultMenu.SetMenuToUser({ token: `Bearer ${token}`, menuId: menuId }).
                then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Menu info saved successfully.");
                        SelectMenuModalOnClose();
                    }
                    else {
                        getError("Failed to set menu to user. Please try again or contact support.");
                    }

                }).
                catch((error) => {
                    getError("Failed to set menu to user. Please try again or contact support.");
                });

        }
        catch (error) {
            getError("Failed to set menu to user. Please try again or contact support.");
        }
    };
    //#endregion

    return (
        <>
            <ModalComponent
                Title="Account"
                IsOpen={addAccountInfoModalIsOpen}
                MaxWidth={"md"}
                CloseModal={handelCancelButtonOnClick}
                ModalContent={
                    <Box component="form" onSubmit={AddAccountInfoFormOnSubmit}>
                        <TextField
                            margin='normal'
                            name='name'
                            type="text"
                            onChange={AddAccountInfoFormInputsOnChange}
                            fullWidth
                            label="Name"
                            sx={{ mt: 2 }} />
                        <TextField
                            margin='normal'
                            name='lastname'
                            type="text"
                            onChange={AddAccountInfoFormInputsOnChange}
                            fullWidth
                            label="Lastname"
                            sx={{ mt: 2 }} />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Birthdate"
                                value={birthdate}
                                onChange={(newValue) => {
                                    setbirthdate(newValue);
                                }}
                                renderInput={(params) => <TextField sx={{ mt: 2, mb: 2 }} fullWidth {...params} />}
                            />
                        </LocalizationProvider>

                        <Divider>Set password for account mainer member</Divider>

                        <TextField
                            margin='normal'
                            name='password'
                            type="password"
                            onChange={AddAccountInfoFormInputsOnChange}
                            fullWidth
                            label="Password"
                            sx={{ mt: 2 }} />
                        <TextField
                            margin='normal'
                            name='confirmPassword'
                            type="password"
                            onChange={AddAccountInfoFormInputsOnChange}
                            fullWidth
                            label="Confirm password"
                            sx={{ mt: 2 }} />

                        <Grid container spacing={1} sx={{ mt: 2, mb: 2 }}  >
                            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={handelCancelButtonOnClick}
                                >Cancel</Button>
                            </Grid>

                            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />

            <ModalComponent
                Title="Add mainer account info"
                IsOpen={addMainerAccountModalIsOpen}
                MaxWidth={"md"}
                CloseModal={handelCancelButtonOnClick}
                ModalContent={
                    <Box component={"form"} onSubmit={AddMainerAccountFormOnSubmit}>
                        <Grid container>
                            <Grid item>
                                <TextField
                                    label={"Password"}
                                    name={"password"}
                                    type={"password"}
                                    fullWidth
                                    onChange={AddMainerAccountFormInputsOnChange}
                                    sx={{ mt: 2 }}
                                />
                                <TextField
                                    label={"Confirm password"}
                                    name={"confirmPassword"}
                                    type={"password"}
                                    fullWidth
                                    onChange={AddMainerAccountFormInputsOnChange}
                                    sx={{ mt: 2 }}
                                />
                                <Button
                                    variant='contained'
                                    type="submit"
                                    fullWidth
                                    sx={{ mt: 2, mb: 2 }}
                                >Save</Button>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />

            <ModalComponent
                Title="Business"
                IsOpen={addBusinessInfoModalIsOpen}
                MaxWidth={"md"}
                CloseModal={handelCancelButtonOnClick}
                ModalContent={
                    <Box component="form" onSubmit={AddBusinessInfoFormOnSubmit}>
                        <TextField
                            margin='normal'
                            name='name'
                            type="text"
                            onChange={AddBusinessInfoFormInputsOnChange}
                            fullWidth
                            label="Name"
                            sx={{ mt: 2 }} />
                        <TextField
                            margin='normal'
                            name='address'
                            type="text"
                            onChange={AddBusinessInfoFormInputsOnChange}
                            fullWidth
                            label="Address"
                            sx={{ mt: 2 }} />
                        <TextField
                            margin='normal'
                            name='postalCode'
                            type="text"
                            onChange={AddBusinessInfoFormInputsOnChange}
                            fullWidth
                            label="Postal code"
                            sx={{ mt: 2 }} />
                        <TextField
                            margin='normal'
                            name='phone'
                            type="number"
                            onChange={AddBusinessInfoFormInputsOnChange}
                            fullWidth
                            label="Phone"
                            sx={{ mt: 2 }} />
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="demo-simple-select-standard-label">Business type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={businessType.toString()}
                                onChange={handleBusinessTypeChange}
                                label="Business type"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    businessTypes?.map((businessType, index) => (
                                        <MenuItem key={businessType.id} value={businessType.value}>{businessType.name}</MenuItem>
                                    ))
                                }

                            </Select>
                        </FormControl>

                        <Grid container spacing={1} sx={{ mt: 2, mb: 2 }}  >
                            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={handelCancelButtonOnClick}
                                >Cancel</Button>
                            </Grid>

                            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                >
                                    Next
                                </Button>
                            </Grid>
                        </Grid>

                    </Box>
                }
            />

            <ModalComponent
                Title="Select menu"
                IsOpen={selectMenuModalIsOpen}
                MaxWidth={"md"}
                CloseModal={handelCancelButtonOnClick}
                ModalContent={
                    <Grid container sx={{ mb: 2 }}>
                        {
                            defaultMenus !== undefined ?
                                defaultMenus?.map((defaultMenu, index) => (
                                    <Grid item key={index}>
                                        <Card key={index} variant="outlined">
                                            <CardContent>
                                                <Typography>{`Id: ${defaultMenu.id}`}</Typography>
                                                <Divider />
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    onClick={() => { SelectMenuButtonOnClick(defaultMenu.id) }}
                                                    sx={{ mb: 2 }}
                                                    fullWidth
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    Select
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                ))
                                :
                                <>
                                    <Typography>menus not found</Typography>
                                    <Grid container spacing={1} sx={{ mt: 2 }}  >
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                onClick={handelCancelButtonOnClick}
                                            >Cancel</Button>
                                        </Grid>


                                    </Grid>
                                </>

                        }

                    </Grid>
                }
            />

            <Outlet />
        </>
    );
};