export const reorder = <T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const getDateYYYYMMDD = () => {
    let yourDate = new Date();
    return yourDate.toISOString().split('T')[0];
};

export const getMonthByNumber = (number: number) => {
    const months = Object.create({
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    })

    return months[number];
};