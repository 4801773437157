import { instance } from "./base.api";
import { BusinessModel } from "./models/User.Model";

const endponit = {
    Add: "api/Business/Add",
    GetBusinessTypes: "api/Business/GetBusinessTypes",
}

export const business = {
    Add: function ({ token, data }: { token: string, data: BusinessModel }) {
        return instance.post(endponit.Add, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    GetBusinessTypes: function ({ token }: { token: string }) {
        return instance.get(endponit.GetBusinessTypes, {
            headers: {
                Authorization: token
            }
        });
    },
};
