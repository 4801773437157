import { instance } from "./base.api";
import { MemberModel } from "./models/AccountMember.Model";

const endponit = {
    getByAccountId: "api/AccountMember/GetByAccountId",
    getByAccountIdPaginated: "api/AccountMember/GetByAccountIdPaginated",
    mainAccountMemberExists: "api/AccountMember/MainAccountMemberExists",
    createMainAccountMember: "api/AccountMember/CreateMainAccountMember",
    add: "api/AccountMember/add",
    update: "api/AccountMember/Update",
    delete: "api/AccountMember/DeleteAccountMember"
}

interface MainAccountProps {
    password: string
}

interface GetByAccountIdPaginatedProps {
    currentPage: number;
    itemsQuantity: number;
}

export const accountMember = {
    getByAccountId: function ({ token }: { token: string }) {
        return instance.get(endponit.getByAccountId, {
            headers: {
                Authorization: token
            }
        });
    },
    getByAccountIdPaginated: function ({ token, data }: { token: string, data: GetByAccountIdPaginatedProps }) {
        return instance.post(endponit.getByAccountIdPaginated, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    mainAccountMemberExists: function ({ token }: { token: string }) {
        return instance.get(endponit.mainAccountMemberExists, {
            headers: {
                Authorization: token
            }
        });
    },
    createMainAccountMember: function ({ token, data }: { token: string, data: MainAccountProps }) {
        return instance.post(endponit.createMainAccountMember, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    add: function ({ token, data }: { token: string, data: MemberModel }) {
        return instance.post(endponit.add, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    update: function ({ token, data }: { token: string, data: MemberModel }) {
        return instance.put(endponit.update, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    delete: function ({ token, id }: { token: string, id: string }) {
        return instance.delete(endponit.delete + `/${id}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },

};