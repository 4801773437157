import { DefaultPromotion } from "../types/defaultPromotion.Model";
import { instance } from "./base.api";
import { CategoryModel, ProductModel, PromotionModel, TaxModel, ToppingModel, UserMenusModel, VariantModel } from "./models/Menu.Model";

const endponit = {
    GetByUserId: "api/Menu/GetByUserId",
    UpdateUserMenu: "api/Menu/UpdateUserMenu",
    AddPromotionToMenu: "api/Menu/AddPromotionToMenu",
    AddPromotionToCategory: "api/Category/AddPromotionToCategory",
    EditCategoryTopping: "api/Category/EditCategoryTopping",
    DeleteProductFromMenuCategory: "api/Menu/DeleteProductFromMenuCategory",
    EditProductFromMenuCategory: "api/Menu/EditProductFromMenuCategory",
    AddProductToCategory: "api/Category/AddProductToCategory",
    AddCategoryToMenu: "api/Menu/AddCategoryToMenu",
    DeleteCategoryFromMenu: "api/Menu/DeleteCategoryFromMenu",
    AddPromotionToMenuProduct: "api/Menu/AddPromotionToMenuProduct",
    DeletePromotionFromMenuProduct: "api/Menu/DeletePromotionFromMenuProduct",
    DeletePromotionFromMenuCategory: "api/Menu/DeletePromotionFromMenuCategory",
    DeletePromotionFromMenu: "api/Menu/DeletePromotionFromMenu",
    AddToppingToMenuCategory: "api/Category/AddToppingToCategory",
    DeleteToppingFromMenuCategory: "api/Menu/DeleteToppingFromMenuCategory",
    AddVariantToMenuProduct: "api/Menu/AddVariantToMenuProduct",
    DeleteVariantFromMenuProduct: "api/Menu/DeleteVariantFromMenuProduct",
    AddTaxToMenu: "api/Menu/AddTaxToMenu",
    DeleteTaxFromMenu:"api/Menu/DeleteTaxFromMenu",
    AddTaxToCategory: "api/Category/AddTaxToCategory",
    DeleteTaxFromMenuCategory:"api/Category/DeleteTaxFromMenuCategory",
    AddTaxToMenuProduct:"api/Menu/AddTaxToMenuProduct",
    DeleteTaxFromMenuProduct: "api/Menu/DeleteTaxFromMenuProduct" 
}

interface AddPromtionToMenuProps {
    menuId: string,
    promotion: PromotionModel
}

interface AddPromtionToCategoryProps {
    menuId: string,
    categoryId: string,
    promotion: PromotionModel
}

interface EditCategoryToppingProps {
    menuId: string,
    categoryId: string,
    topping: ToppingModel
}

interface DeleteProductFromMenuCategoryProps {
    menuId: string,
    categoryId: string,
    productId: string
}

interface EditProductFromMenuCategoryProps {
    menuId: string,
    categoryId: string,
    product: ProductModel
}

interface AddProductToCategoryProps {
    menuId: string;
    categoryId: string;
    product: ProductModel;
}

interface AddCategoryToMenuProps {
    menuId: string;
    category: CategoryModel
}

interface DeleteCategoryFromMenuProps {
    menuId: string;
    categoryId: string;
}

interface AddPromotionToMenuProductProps {
    menuId: string;
    categoryId: string;
    productId: string;
    promotion: DefaultPromotion;
}

interface DeletePromotionFromMenuProductProps {
    menuId: string;
    categoryId: string;
    productId: string;
    cloudId: string;
}

interface DeletePromotionFromMenuCategoryProps {
    menuId: string;
    categoryId: string;
    promotionCloudId: string;
}

interface DeletePromotionFromMenuProps {
    menuId: string;
    promotionCloudId: string;
}

interface AddToppingToMenuCategoryProps {
    menuId: string;
    categoryId: string;
    topping: ToppingModel;
}

interface DeleteToppingFromMenuCategoryProps {
    menuId: string;
    categoryId: string;
    ToppingCloudId: string;
}

interface AddVariantToMenuProductProps {
    menuId: string;
    categoryId: string;
    productId: string;
    variant: VariantModel;
}
interface DeleteVariantFromMenuProductProps {
    menuId: string;
    categoryId: string;
    productId: string;
    variantCloudId: string;
}

interface AddTaxToMenuProps {
    menuId: string;
    tax: TaxModel;
}

interface DeleteTaxFromMenuProps{
    menuId: string;
    taxCloudId: string;
}

interface AddTaxToCategoryProps{
    menuId: string;
    categoryId: string;
    tax: TaxModel;
}

interface DeleteTaxFromMenuCategoryProps{
    menuId: string;
    categoryId: string;
    taxCloudId: string;
}

interface AddTaxToMenuProductProps{
    menuId: string;
    categoryId: string;
    productId: string;
    tax: TaxModel;
}
interface DeleteTaxFromMenuProductProps{
    menuId: string;
    categoryId: string;
    productId: string;
    taxCloudId: string;
}

export const userMenu = {
    GetByUserId: function ({ token }: { token: string }) {
        return instance.get(endponit.GetByUserId, {
            headers: {
                Authorization: token
            }
        });
    },
    UpdateUserMenu: function ({ token, data }: { token: string, data: UserMenusModel }) {
        return instance.put(endponit.UpdateUserMenu, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    AddPromotionToMenu: function ({ token, data }: { token: string, data: AddPromtionToMenuProps }) {
        return instance.post(endponit.AddPromotionToMenu, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    AddPromotionToCategory: function ({ token, data }: { token: string, data: AddPromtionToCategoryProps }) {
        return instance.post(endponit.AddPromotionToCategory, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    EditCategoryTopping: function ({ token, data }: { token: string, data: EditCategoryToppingProps }) {
        return instance.post(endponit.EditCategoryTopping, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    DeleteProductFromMenuCategory: function ({ token, data }: { token: string, data: DeleteProductFromMenuCategoryProps }) {
        return instance.post(endponit.DeleteProductFromMenuCategory, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    EditProductFromMenuCategory: function ({ token, data }: { token: string, data: EditProductFromMenuCategoryProps }) {
        return instance.post(endponit.EditProductFromMenuCategory, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    AddProductToCategory: function ({ token, data }: { token: string, data: AddProductToCategoryProps }) {
        return instance.post(endponit.AddProductToCategory, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    AddCategoryToMenu: function ({ token, data }: { token: string, data: AddCategoryToMenuProps }) {
        return instance.post(endponit.AddCategoryToMenu, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    DeleteCategoryFromMenu: function ({ token, data }: { token: string, data: DeleteCategoryFromMenuProps }) {
        return instance.delete(endponit.DeleteCategoryFromMenu, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            data: data
        });
    },
    AddPromotionToMenuProduct: function ({ token, data }: { token: string, data: AddPromotionToMenuProductProps }) {
        return instance.post(endponit.AddPromotionToMenuProduct, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    AddToppingToMenuCategory: function ({ token, data }: { token: string, data: AddToppingToMenuCategoryProps }) {
        return instance.post(endponit.AddToppingToMenuCategory, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    DeletePromotionFromMenuProduct: function ({ token, data }: { token: string, data: DeletePromotionFromMenuProductProps }) {
        return instance.delete(endponit.DeletePromotionFromMenuProduct, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            data: data
        });
    },
    DeletePromotionFromMenuCategory: function ({ token, data }: { token: string, data: DeletePromotionFromMenuCategoryProps }) {
        return instance.delete(endponit.DeletePromotionFromMenuCategory, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            data: data
        });
    },
    DeletePromotionFromMenu: function ({ token, data }: { token: string, data: DeletePromotionFromMenuProps }) {
        return instance.delete(endponit.DeletePromotionFromMenu, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            data: data
        });
    },
    DeleteToppingFromMenuCategory: function ({ token, data }: { token: string, data: DeleteToppingFromMenuCategoryProps }) {
        return instance.delete(endponit.DeleteToppingFromMenuCategory, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            data: data
        });
    },
    AddVariantToMenuProduct: function ({ token, data }: { token: string, data: AddVariantToMenuProductProps }) {
        return instance.post(endponit.AddVariantToMenuProduct, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    DeleteVariantFromMenuProduct: function ({ token, data }: { token: string, data: DeleteVariantFromMenuProductProps }) {
        return instance.delete(endponit.DeleteVariantFromMenuProduct, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            data: data
        });
    },
    AddTaxToMenu: function ({ token, data }: { token: string, data: AddTaxToMenuProps }) {
        return instance.post(endponit.AddTaxToMenu, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    DeleteTaxFromMenu: function ({ token, data }: { token: string, data: DeleteTaxFromMenuProps }) {
        return instance.delete(endponit.DeleteTaxFromMenu, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            data: data
        });
    },
    AddTaxToCategory: function ({ token, data }: { token: string, data: AddTaxToCategoryProps }) {
        return instance.post(endponit.AddTaxToCategory, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    DeleteTaxFromMenuCategory: function ({ token, data }: { token: string, data: DeleteTaxFromMenuCategoryProps }) {
        return instance.delete(endponit.DeleteTaxFromMenuCategory, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            data: data
        });
    },
    AddTaxToMenuProduct: function ({ token, data }: { token: string, data: AddTaxToMenuProductProps }) {
        return instance.post(endponit.AddTaxToMenuProduct, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    DeleteTaxFromMenuProduct: function ({ token, data }: { token: string, data:DeleteTaxFromMenuProductProps }) {
        return instance.delete(endponit.DeleteTaxFromMenuProduct, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            data: data
        });
    },
};
