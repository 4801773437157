import { auth } from "../api/auth";
import { SignInResponse } from "../pages/Login/interface/login.inteface";

export interface AuthData {
    a: string | undefined,
    a_exp?: Date,
    r: string | undefined,
    r_exp?: Date
}

export interface GetAccessTokenResponse {
    
}

export enum AccessTokenStatusCode {
    valid = 1,
    Expirated = 2,
    RefreshTokenExpirated = 3
};

const localStorageKey = "cento";

export const SaveAuthData = (data: AuthData): void => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));

};

export const getAccessToken = async (): Promise<string | null> => {
    let dataJson = localStorage.getItem(localStorageKey) ?? null;
    let authData: AuthData = dataJson !== null ? JSON.parse(dataJson) : null;
    if (authData === null || authData.a === undefined) {
        return null;
    }

    const currentUtcDate = new Date().toUTCString();

    if (new Date(currentUtcDate) >= new Date(authData.a_exp ?? currentUtcDate)) {
        console.log("You need to refresh token " + `Expiration date ${new Date(currentUtcDate)}`);
        const refreshTokenResponse = await auth.refreshToken({ token: `Bearer ${authData.r}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    const responseData: SignInResponse = response.data;
                    const data: AuthData = {
                        a: responseData.data?.token,
                        r: responseData.data?.refreshToken,
                        a_exp: responseData.data?.tokenExpiration,
                        r_exp: responseData.data?.refreshTokenExpiration
                    };
                    localStorage.setItem(localStorageKey, JSON.stringify(data));
                    console.log(`New expiration date ${new Date(responseData.data?.tokenExpiration ?? "")}`);
                    return true;
                }
                else {
                    console.log("SignIn Again " + `status code: ${response.data.statusCode}`);
                    localStorage.removeItem(localStorageKey);
                    return false;
                }
            }).
            catch((error) => {
                console.log("SignIn Again " + `error ${error}`);
                localStorage.removeItem(localStorageKey);
                return false;
            });

        if (refreshTokenResponse) {
            dataJson = localStorage.getItem(localStorageKey) ?? null;
            authData = dataJson !== null ? JSON.parse(dataJson) : null;
            if (authData === null || authData.a === undefined) {
                console.log("token null, singin again");
                localStorage.removeItem(localStorageKey);
                return null;
            }

            return authData.a;
        }
        else {
            //console.log("SignIn again");
            localStorage.removeItem(localStorageKey);
            return null;
        }
    }
    else {
        //console.log(`Expiration date ${new Date(authData.a_exp ?? "")}`);
        return authData.a;
    }
};

export const AuthValidate = (): boolean => {
    let dataJson = localStorage.getItem(localStorageKey) ?? null;
    let authData: AuthData = dataJson !== null ? JSON.parse(dataJson) : null;
    if (authData === null || authData.a === undefined) {
        return false;
    }

    return true;
};

export const SignOut = (): void => {
    localStorage.removeItem(localStorageKey);
};
