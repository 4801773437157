import { Box, Button, Container, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../context/notification.context";
import { SignUpValidate } from "../../utils/formValidation";
import { SignUp as SignUpInterface } from "./interface/login.inteface";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { auth } from "../../api/auth";

interface SignUpProp {

}


const SignUp: React.FC<SignUpProp> = () => {
    const [birthdate, setbirthdate] = React.useState<Dayjs | null>(
        dayjs('2018-01-01T00:00:00.000Z'),
    );

    const [signUpData, setSignUpData] = React.useState<SignUpInterface>({
        email: "",
        password: "",
        confirmPassword: "",
        name: "",
        lastname: "",
        birthdate: ""
    });

    const { getError, getSuccess } = useNotification();

    const navigate = useNavigate();

    const updateSignUpData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        signUpData.birthdate = birthdate?.format('DD/MM/YYYY') ?? "";
        SignUpValidate.validate(signUpData).
            then(() => {
                auth.signUp({
                    username: signUpData.email,
                    address: "",
                    birthdate: signUpData.birthdate,
                    email: signUpData.email,
                    name: signUpData.name,
                    familyName: signUpData.lastname,
                    password: signUpData.password,
                    business: ""
                }).
                    then((response) => {
                        if (response.data.statusCode === 200) {
                            getSuccess("Account created successfully. Please confirm your email with the link sent to your email.");
                            navigate("/SignIn");
                        }
                        else {
                            getError("Failed to create account. Please try again or contact support.");
                        }
                    }).
                    catch((error) => {
                        getError("Failed to create account. Please try again or contact support.");
                    });
            }).
            catch((error) => {
                getError("Failed to create account. Please try again or contact support.");
                getError(error.message);
            });
    };

    const handleOnSignInButtonClick = () => {
        navigate("/SignIn");
    };

    return (
        <Container maxWidth="sm">
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: "100vh" }}
            >
                <Grid item>
                    <Paper sx={{ padding: "1.2em", borderRadius: "0.5em" }}>
                        <Typography
                            variant="h4"
                            sx={{ mt: 1, mb: 1 }}
                        >
                            Sign up
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} >
                            <TextField
                                margin='normal'
                                name='name'
                                type="text"
                                onChange={updateSignUpData}
                                fullWidth
                                label="Name"
                                sx={{ mt: 2, mb: 1.5 }} />
                            <TextField
                                margin='normal'
                                name='lastname'
                                type="text"
                                onChange={updateSignUpData}
                                fullWidth
                                label="Lastname"
                                sx={{ mt: 2, mb: 1.5 }} />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Birthdate"
                                    value={birthdate}
                                    onChange={(newValue) => {
                                        setbirthdate(newValue);
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                            <TextField
                                margin='normal'
                                name='email'
                                type="text"
                                onChange={updateSignUpData}
                                fullWidth
                                label="Email"
                                sx={{ mt: 2, mb: 1.5 }} />
                            <TextField
                                margin='normal'
                                name='password'
                                type="password"
                                onChange={updateSignUpData}
                                fullWidth
                                label="Password"
                                sx={{ mt: 1.5, mb: 1.5 }} />
                            <TextField
                                margin='normal'
                                name='confirmPassword'
                                type="password"
                                onChange={updateSignUpData}
                                fullWidth
                                label="Confirm password"
                                sx={{ mt: 1.5, mb: 1.5 }} />
                            <Button
                                fullWidth
                                type='submit'
                                variant='contained'
                                sx={{ mt: 1.5, mb: 3 }}
                            >
                                Next
                            </Button>
                        </Box>
                        <Divider> OR </Divider>
                        <Button
                            fullWidth
                            type='submit'
                            variant='outlined'
                            sx={{ mt: 1.5, mb: 3 }}
                            onClick={handleOnSignInButtonClick}
                        >
                            Sign in to an existing Cento account
                        </Button>
                    </Paper>

                </Grid>
            </Grid>
        </Container>
    );
};

export default SignUp;