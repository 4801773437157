import { instance } from "./base.api";
import { DeviceModel } from "./models/Device.Model";

const endponit = {
    AddDevice: "api/Device/AddDevice",
    GetDevicesByUserId: "api/Device/GetDevicesByUserId",
    Unpair: "api/Device/Unpair",
    EditDevice: "api/Device/EditDevice"
}

interface AddDeviceProp {
    ConfirmationCode: string
}

interface UnpairDeviceProp {
    DeviceId: string
}

interface EditDeviceProp {
    device : DeviceModel
}

export const device = {
    Add: function ({ token, data }: { token: string, data: AddDeviceProp }) {
        return instance.post(endponit.AddDevice, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    GetDevicesByUserId: function ({ token }: { token: string }) {
        return instance.get(endponit.GetDevicesByUserId, {
            headers: {
                Authorization: token
            }
        });
    },
    Unpair: function ({ token, data }: { token: string, data: UnpairDeviceProp }) {
        return instance.put(endponit.Unpair, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    EditDevice: function ({ token, data }: { token: string, data: EditDeviceProp }) {
        return instance.post(endponit.EditDevice, JSON.stringify(data), {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
};
