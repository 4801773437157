import React from 'react';
import { Alert, AlertColor, Snackbar, Typography } from "@mui/material";


type NotificationProps = {
    open: boolean,
    message: string,
    severity: AlertColor | undefined,
    handleClose: () => void
}

const NotificationComponent: React.FC<NotificationProps> = ({
    open,
    message,
    severity,
    handleClose
}) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={4000}
            open={open}
            onClose={handleClose}
        >
            <Alert variant="filled" onClose={handleClose} severity={severity}>
                <Typography>{message}</Typography>
            </Alert>
        </Snackbar>
    );
};

export default NotificationComponent;