import { instance } from "./base.api";

const endponit = {
    GetAll: "api/Promotion/Default/GetAll"
}

export const defaultPromotions = {
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
};