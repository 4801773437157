import React from "react";

interface HomeProp {

}

const Home : React.FC<HomeProp> = () => {
    return(
        <>
        </>
    );
};

export default Home;