import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import { BusinessModel, UserModel } from '../../api/models/User.Model';
import { user } from '../../api/user';
import { HeaderComponent } from '../../components';
import { ModalComponent } from '../../components/Modal';
import { AuthValidate, getAccessToken, SignOut } from '../../utils/authHelper';
import UpdateIcon from '@mui/icons-material/Update';
import IconButton from '@mui/material/IconButton';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { UpdateAccounInfoValidate } from '../../utils/formValidation';
import { useNotification } from '../../context/notification.context';
import { useNavigate } from 'react-router-dom';

interface AccountPageProps {

}

type AccountInfo = {

};

type UpdateAccountData = {
    name: string;
    lastname: string;
    birthdate: string;
}

type UpdatedBusinessDate = {
    name: string;
    address: string;
    phone: number;
    postalCode: string;
}

export const AccountPage: React.FC<AccountPageProps> = () => {
    const navigate = useNavigate();
    const { getError, getSuccess } = useNotification();
    const [accountData, setAccountData] = React.useState<UserModel | undefined>(undefined);
    const [updatedAccountData, setUpdatedAccountData] = React.useState<UpdateAccountData>({
        name: "",
        lastname: "",
        birthdate: ""
    });
    const [showUpdateAccountInfoModal, setShowUpdateAccountInfoModal] = React.useState<boolean>(false);
    const [showUpdateBusinessInfoModal, setShowUpdateBusinessInfoModal] = React.useState<boolean>(false);
    const [birthdate, setbirthdate] = React.useState<Dayjs | null>(
        dayjs('2018-01-01T00:00:00.000Z'),
    );
    const [updatedBusinessData, setUpdatedBusinessData] = React.useState<UpdatedBusinessDate>({
        name: "",
        address: "",
        phone: 0,
        postalCode: "",
    });

    React.useEffect(
        AuthValidate() ? () => {
            getAccountInfo();
        } : () => {
            SignOut();
            navigate("/");
        }, []);

    const getAccountInfo = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

        user.GetByAccountId({
            token: `Bearer ${token}`
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    setAccountData(response.data.data);
                }
            })
            .catch((error) => {

            });

    };

    const updateAccountData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedAccountData({ ...updatedAccountData, [e.target.name]: e.target.value });
    };

    const updateAccountInfoFormOnSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        updatedAccountData.birthdate = birthdate?.format('DD/MM/YYYY') ?? "";
        UpdateAccounInfoValidate.validate(updatedAccountData)
            .then(async () => {
                const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                user.UpdateAccountInfo({
                    token: `Bearer ${token}`,
                    data: {
                        name: updatedAccountData.name,
                        lastname: updatedAccountData.lastname,
                        birthdate: updatedAccountData.birthdate
                    }
                })
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            getSuccess("Account info updated successfully.");
                        }
                        else {
                            getError("Account info not modified");
                        }

                        setUpdatedAccountData({ name: "", lastname: "", birthdate: "" });
                        setbirthdate(null);
                        setShowUpdateAccountInfoModal(false);
                        getAccountInfo();
                    })
                    .catch((error) => {
                        getError("Failed to update account info. Please try again or contact support.");
                    });

            })
            .catch((error) => {
                getError(error.message);
            });

    };

    const updateBusinessData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedBusinessData({ ...updatedBusinessData, [e.target.name]: e.target.value });
    };

    const updateBusinessInfoFormOnSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            if (accountData?.business === undefined) {
                getError("Failed to select business.");
                throw new Error();
            }

            accountData.business.name = updatedBusinessData.name;
            accountData.business.address = updatedBusinessData.address;
            accountData.business.phone = updatedBusinessData.phone;
            accountData.business.postalCode = updatedBusinessData.postalCode;

            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
            user.UpdateBusinessInfo({
                token: `Bearer ${token}`,
                data: {
                    business: accountData?.business
                }
            })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Business info edited sucessfully.");
                        EditedBusinessDataModalClose();
                    }
                    else {
                        getError("Failed to edit business info. Please try again or contact support.");
                    }

                })
                .catch((error) => {
                    getError("Failed to edit business info. Please try again or contact support.");
                });


        } catch (error) {

        }
    };

    const UpdateBusinessInfoModalOpen = (business: BusinessModel | undefined) => {
        if (business !== undefined) {
            updatedBusinessData.name = business.name;
            updatedBusinessData.address = business.address;
            updatedBusinessData.postalCode = business.postalCode;
            updatedBusinessData.phone = business.phone;
        }

        setShowUpdateBusinessInfoModal(true);
    };

    const EditedBusinessDataModalClose = () => {
        setUpdatedBusinessData({
            name: "",
            address: "",
            phone: 0,
            postalCode: "",
        });
        setShowUpdateBusinessInfoModal(false);
    };

    return (
        <Container>

            <ModalComponent
                Title={'Update account info'}
                IsOpen={showUpdateAccountInfoModal}
                MaxWidth={"xl"}
                CloseModal={() => { setShowUpdateAccountInfoModal(false) }}
                ModalContent={
                    <Box component={"form"} onSubmit={updateAccountInfoFormOnSubmit}>
                        <TextField
                            margin='normal'
                            name='name'
                            type="text"
                            onChange={updateAccountData}
                            fullWidth
                            label="Name"
                            sx={{ mt: 2, mb: 1.5 }} />
                        <TextField
                            margin='normal'
                            name='lastname'
                            type="text"
                            onChange={updateAccountData}
                            fullWidth
                            label="Lastname"
                            sx={{ mt: 2, mb: 3.5 }} />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker

                                label="Birthdate"
                                value={birthdate}
                                onChange={(newValue) => {
                                    setbirthdate(newValue);
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                        <Button type='submit' fullWidth variant='contained' sx={{ mt: 2 }}>Save</Button>
                    </Box>
                }
            />

            <ModalComponent
                Title={'Update business info'}
                IsOpen={showUpdateBusinessInfoModal}
                MaxWidth={"xl"}
                CloseModal={() => { setShowUpdateBusinessInfoModal(false) }}
                ModalContent={
                    <Box component={"form"} onSubmit={updateBusinessInfoFormOnSubmit}>
                        <TextField
                            margin='normal'
                            name='name'
                            type="text"
                            value={updatedBusinessData.name}
                            onChange={updateBusinessData}
                            fullWidth
                            label="Name"
                            sx={{ mt: 2, mb: 1.5 }} />
                        <TextField
                            margin='normal'
                            name='address'
                            type="text"
                            value={updatedBusinessData.address}
                            onChange={updateBusinessData}
                            fullWidth
                            label="Address"
                            sx={{ mt: 2, mb: 1.5 }} />
                        <TextField
                            margin='normal'
                            name='postalCode'
                            type="text"
                            value={updatedBusinessData.postalCode}
                            onChange={updateBusinessData}
                            fullWidth
                            label="Postal code"
                            sx={{ mt: 2, mb: 1.5 }} />
                        <TextField
                            margin='normal'
                            name='phone'
                            type="number"
                            value={updatedBusinessData.phone}
                            onChange={updateBusinessData}
                            fullWidth
                            label="Phone"
                            sx={{ mt: 2, mb: 1.5 }} />
                        <Button type='submit' fullWidth variant='contained' sx={{ mt: 2 }}>Save</Button>
                    </Box>
                }
            />

            <HeaderComponent
                title='Account'
                description=''
            />

            <Container maxWidth={"md"}>
                <Grid
                    container
                    direction={"row"}
                    sx={{ mt: 1 }}
                >
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <Paper sx={{ padding: "1.2em", borderRadius: "0.5em" }}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        direction={"column"}
                                        alignItems="center"
                                        justifyContent={"center"}
                                    >
                                        <Grid item xs={13} >
                                            <img
                                                src={"https://www.puntogeek.com/wp-content/uploads/2013/11/hC2dAuK.gif"}
                                                loading="lazy"
                                                height={"100%"}
                                                width={"100%"}

                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>

                                    <Typography variant={"h6"} sx={{ mt: 1, mb: 1 }}
                                    >
                                        Info:<IconButton onClick={() => { setShowUpdateAccountInfoModal(true) }}
                                        ><UpdateIcon /></IconButton>
                                    </Typography>
                                    <TextField
                                        label={"Account user"}

                                        value={accountData ? accountData.email : ""}
                                        fullWidth
                                        sx={{ mt: 2 }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={"Name"}
                                        value={accountData ? accountData.name : ""}
                                        defaultValue={accountData?.name}
                                        fullWidth
                                        sx={{ mt: 2 }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={"Birthdate"}
                                        value={accountData ? accountData.birthdate.toString().split('T')[0] : ""}
                                        fullWidth
                                        sx={{ mt: 2 }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                    <Typography variant={"h6"} sx={{ mt: 2, mb: 1 }}
                                    >
                                        Business:<IconButton onClick={() => { UpdateBusinessInfoModalOpen(accountData?.business); }}><UpdateIcon /></IconButton>
                                    </Typography>

                                    <TextField
                                        label={"Name"}
                                        value={accountData ? accountData.business.name : ""}
                                        fullWidth
                                        sx={{ mt: 2 }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={"Address"}
                                        value={accountData ? accountData.business.address : ""}
                                        fullWidth
                                        sx={{ mt: 2 }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={"Postal code"}
                                        value={accountData ? accountData.business.postalCode : ""}
                                        fullWidth
                                        sx={{ mt: 2 }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={"Phone"}
                                        value={accountData ? accountData.business.phone : ""}
                                        fullWidth
                                        sx={{ mt: 2 }}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>


                </Grid>
            </Container>
        </Container>
    );
};