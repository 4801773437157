import { Box, Button, Card, CardContent, Checkbox, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ModalComponent } from '../../components/Modal';
import { getAccessToken } from '../../utils/authHelper';
import { promotionTypes as promotionTypesService } from '../../api/promotionTypes';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useNotification } from '../../context/notification.context';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { customPromotions } from '../../api/customPromotions';
import { DefaultPromotion } from '../../types/defaultPromotion.Model';
import { getDateYYYYMMDD, reorder } from '../../utils/appHelper';
import { CreateCustomPromotionValidate, EditCustomPromotionValidate } from '../../utils/formValidation';
import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmCancelDialogComponent, ConfirmCancelDialogProps } from '../../components/ConfirmCancelDialog';

interface PromotionTabProps {

}

interface PromtionTypeModel {
    id: string;
    name: string;
    index: number;
}

interface PromotionCrudModalProps {
    title: string;
    closeModal: () => void;
    maxWidth: string;
    modalContent: React.ReactNode;
    isNew: boolean;
}

interface PromotionData {
    name: string;
    promotionType: number;
    discountValue: number;
    amountProductsToPay: number;
    amountProductsToGet: number;
    starts: string;
    ends: string;
    daysOfTheWeek: string[];
    hoursOfTheDay: string[];
    toApplyId: number;
    allowOverlap: boolean;
    automatic: boolean;
    enabled: boolean;

}

export const PromotionTab: React.FC<PromotionTabProps> = ({ }) => {
    const { getError, getSuccess } = useNotification();
    const [selectedPromotion, setSelectedPromotion] = React.useState<DefaultPromotion | undefined>(undefined);
    const [promotions, setPromotions] = React.useState<DefaultPromotion[]>([]);
    const [promotionTypes, setPromotionTypes] = React.useState<PromtionTypeModel[]>([]);
    const [showPromotionCrudModal, setShowPromotionModal] = React.useState<boolean>(false);
    const [promotionCrudModalProps, setPromotionCrudModalProps] = React.useState<PromotionCrudModalProps>({
        title: "",
        closeModal: () => { },
        maxWidth: "",
        modalContent: <></>,
        isNew: true
    });
    const [automaticChecked, setAutomaticChecked] = React.useState(false);
    const [allowOverlapChecked, setAllowOverlapChecked] = React.useState(false);
    const [enabledChecked, setEnabledChecked] = React.useState(false);
    const [selectedApplyToType, setSelectedApplyToType] = React.useState<string>('');
    const [selectedPromotionType, setSelectedPromotionType] = React.useState<string>('');
    const [promotionStartsDate, setPromotionStartsDate] = React.useState<Dayjs | null>(
        dayjs(getDateYYYYMMDD()),
    );
    const [promotionEndsDate, setPromotionEndsDate] = React.useState<Dayjs | null>(
        dayjs(getDateYYYYMMDD()),
    );
    const [daysOfTheWeek, setDaysOfTheWeek] = React.useState(() => ["", "1", "2", "3", "4", "5", "6", "7"]);
    const [hoursOfTheDay, setHoursOfTheDay] = React.useState(() => ["", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23",]);
    const [promotionData, setPromotionData] = React.useState<PromotionData>({
        name: "",
        promotionType: 0,
        discountValue: 0,
        amountProductsToPay: 0,
        amountProductsToGet: 0,
        starts: "",
        ends: "",
        daysOfTheWeek: [],
        hoursOfTheDay: [],
        toApplyId: 0,
        allowOverlap: false,
        automatic: false,
        enabled: false,
    });
    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = React.useState<boolean>(false);
    const [confirmCancelDialogProps, setconfirmCancelDialogProps] = React.useState<ConfirmCancelDialogProps>(
        {
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        }
    );

    const NewPromotionButtonOnClick = () => {
        promotionCrudModalProps.title = "Add promotion"
        promotionCrudModalProps.closeModal = PromotionModalOnClose;
        promotionCrudModalProps.isNew = true;
        promotionCrudModalProps.maxWidth = "md";
        setShowPromotionModal(true);

    };

    const EditPromotionButtonOnClick = (promotion: DefaultPromotion) => {
        setSelectedPromotion(promotion);
        setPromotionData({
            name: promotion.promotionName,
            promotionType: promotion.promotionTypeIndex,
            discountValue: promotion.discountValue,
            amountProductsToPay: promotion.amountProductsToPay,
            amountProductsToGet: promotion.amountProductsToGet,
            starts: "",
            ends: "",
            daysOfTheWeek: [],
            hoursOfTheDay: [],
            toApplyId: promotion.toApplyId,
            allowOverlap: promotion.allowOverlap,
            automatic: promotion.automatic,
            enabled: promotion.enabled
        });
        setSelectedPromotionType(promotion.promotionTypeIndex.toString());
        setSelectedApplyToType(promotion.toApplyId.toString());
        setAllowOverlapChecked(promotion.allowOverlap);
        setAutomaticChecked(promotion.automatic);
        setEnabledChecked(promotion.enabled);
        setDaysOfTheWeek(() => promotion.daysOfTheWeek.map(String));
        setHoursOfTheDay(() => promotion.hoursOfTheDay.map(String));
        const startsdate = promotion.starts.toString().split('T')[0];
        const endsdate = promotion.ends.toString().split('T')[0];
        setPromotionStartsDate(dayjs(startsdate));
        setPromotionEndsDate(dayjs(endsdate))
        promotionCrudModalProps.title = "Edit promotion"
        promotionCrudModalProps.closeModal = PromotionModalOnClose;
        promotionCrudModalProps.isNew = false;
        promotionCrudModalProps.maxWidth = "md";
        setShowPromotionModal(true);

    };

    const DeletePromotionButtonOnClick = (promotion: DefaultPromotion) => {
        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete promotion";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that promotion?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeletePromotion(promotion.id) };
    };


    const CloseConfirmCancelDialog = () => {
        setconfirmCancelDialogProps({
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        });

        setShowConfirmCancelDialog(false);
    };

    const DeletePromotion = async (id: string) => {
        try {
            const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });

            customPromotions.Delete({ token: `Bearer ${token}`, id: id })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        getSuccess("Promotion deleted successfully.");
                        getPromotions();
                        CloseConfirmCancelDialog();
                    }
                    else {
                        getError("Failed to delete promotion. Please try again or contact support.");
                    }

                })
                .catch((error) => {
                    getError("Failed to delete promotion. Please try again or contact support.");
                });

        }
        catch (error) {
            getError("Failed to delete promotion. Please try again or contact support.");
        }
    };

    const PromotionModalOnClose = () => {
        setSelectedPromotion(undefined);
        setSelectedPromotionType('');
        setSelectedApplyToType('');
        setAllowOverlapChecked(false);
        setAutomaticChecked(false);
        setEnabledChecked(false);
        setPromotionStartsDate(dayjs(getDateYYYYMMDD()));
        setPromotionEndsDate(dayjs(getDateYYYYMMDD()));
        setDaysOfTheWeek(() => ["", "1", "2", "3", "4", "5", "6", "7"]);
        setHoursOfTheDay(() => ["", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23",]);
        setPromotionData({
            name: "",
            promotionType: 0,
            discountValue: 0,
            amountProductsToPay: 0,
            amountProductsToGet: 0,
            starts: "",
            ends: "",
            daysOfTheWeek: [],
            hoursOfTheDay: [],
            toApplyId: 0,
            allowOverlap: false,
            automatic: false,
            enabled: false,
        });
        setShowPromotionModal(false);
    };

    const GetPromotionTypes = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        promotionTypesService.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    setPromotionTypes(response.data.data);
                }
            }).
            catch((error) => {

            });
    };

    const ApplyToTypeSelectOnChange = (event: SelectChangeEvent) => {
        setSelectedApplyToType(event.target.value);
    };
    const PromotionTypeSelectOnChange = (event: SelectChangeEvent) => {
        setSelectedPromotionType(event.target.value);
        console.log(event.target.value);
    };
    const DaysOfTheWeekToggleButtonOnChange = (event: React.MouseEvent<HTMLElement>, newDayOfTheWeek: string[]) => {
        if (newDayOfTheWeek.length) {
            setDaysOfTheWeek(newDayOfTheWeek);
        }
    };
    const HoursOfTheDayToggleButtonOnChange = (event: React.MouseEvent<HTMLElement>, newHoursOfTheDay: string[]) => {
        if (newHoursOfTheDay.length) {
            setHoursOfTheDay(newHoursOfTheDay);
        }
    };

    const updatePromotionData = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPromotionData({ ...promotionData, [e.target.name]: e.target.value });
    };

    const AddPromotionFormOnSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            promotionData.promotionType = selectedPromotionType === "" ? 0 : parseInt(selectedPromotionType);
            promotionData.toApplyId = selectedApplyToType === "" ? 0 : parseInt(selectedApplyToType);
            promotionData.starts = promotionStartsDate?.format('DD/MM/YYYY') ?? "";
            promotionData.ends = promotionEndsDate?.format('DD/MM/YYYY') ?? "";
            promotionData.daysOfTheWeek = daysOfTheWeek.filter(x => x !== "");
            promotionData.hoursOfTheDay = hoursOfTheDay.filter(x => x !== "");
            promotionData.allowOverlap = allowOverlapChecked;
            promotionData.automatic = automaticChecked;

            CreateCustomPromotionValidate.validate(promotionData).
                then(async () => {
                    const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                    customPromotions.Add({
                        token: `Bearer ${token}`,
                        data: {
                            promotionName: promotionData.name,
                            promotionTypeIndex: promotionData.promotionType,
                            discountValue: promotionData.promotionType !== 3 ? promotionData.discountValue : 0,
                            starts: promotionData.starts,
                            ends: promotionData.ends,
                            priority: 1,
                            allowOverlap: promotionData.allowOverlap,
                            automatic: promotionData.automatic,
                            amountProductsToPay: promotionData.promotionType === 3 ? promotionData.amountProductsToPay : 0,
                            amountProductsToGet: promotionData.promotionType === 3 ? promotionData.amountProductsToGet : 0,
                            daysOfTheWeek: promotionData.daysOfTheWeek.map((x) => { return parseInt(x) }),
                            hoursOfTheDay: promotionData.hoursOfTheDay.map((x) => { return parseInt(x) }),
                            toApplyId: promotionData.toApplyId,
                            enabled: true
                        }
                    }).
                        then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Promotion created successfully.");
                                getPromotions();
                                PromotionModalOnClose();
                            }
                            else {
                                getError("Failed to create promotion. Please try again or contact support.");
                            }
                        }).
                        catch((error) => {
                            getError("Failed to create promotion. Please try again or contact support.");
                        });
                }).
                catch((error) => {
                    getError(error.message);
                });

        }
        catch (error) {
            getError("Failed to create promotion. Please try again or contact support.");
        }
    };

    const EditPromotionFormOnSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            promotionData.promotionType = selectedPromotionType === "" ? 0 : parseInt(selectedPromotionType);
            promotionData.toApplyId = selectedApplyToType === "" ? 0 : parseInt(selectedApplyToType);
            promotionData.starts = promotionStartsDate?.format('DD/MM/YYYY') ?? "";
            promotionData.ends = promotionEndsDate?.format('DD/MM/YYYY') ?? "";
            promotionData.daysOfTheWeek = daysOfTheWeek.filter(x => x !== "");
            promotionData.hoursOfTheDay = hoursOfTheDay.filter(x => x !== "");
            promotionData.allowOverlap = allowOverlapChecked;
            promotionData.automatic = automaticChecked;
            promotionData.enabled = enabledChecked;
            console.log(promotionData.enabled);
            EditCustomPromotionValidate.validate(promotionData)
                .then(async () => {
                    const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                    customPromotions.Update({
                        token: `Bearer ${token}`,
                        data: {
                            id: selectedPromotion?.id,
                            promotionName: promotionData.name,
                            promotionTypeIndex: promotionData.promotionType,
                            discountValue: promotionData.promotionType !== 3 ? promotionData.discountValue : 0,
                            starts: promotionData.starts,
                            ends: promotionData.ends,
                            priority: 1,
                            allowOverlap: promotionData.allowOverlap,
                            automatic: promotionData.automatic,
                            amountProductsToPay: promotionData.promotionType === 3 ? promotionData.amountProductsToPay : 0,
                            amountProductsToGet: promotionData.promotionType === 3 ? promotionData.amountProductsToGet : 0,
                            daysOfTheWeek: promotionData.daysOfTheWeek.map((x) => { return parseInt(x) }),
                            hoursOfTheDay: promotionData.hoursOfTheDay.map((x) => { return parseInt(x) }),
                            toApplyId: promotionData.toApplyId,
                            enabled: promotionData.enabled
                        }
                    })
                        .then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Promotion edited successfully.");
                                getPromotions();
                                PromotionModalOnClose();
                            }
                            else {
                                getError("Failed to edit promotion. Please try again or contact support.");
                            }
                        })
                        .catch((error) => {
                            getError("Failed to edit promotion. Please try again or contact support.");
                        });
                })
                .catch((error) => {
                    getError(error.message);
                });
        }
        catch (error) {
            getError("Failed to edit promotion. Please try again or contact support.");
        }
    };

    React.useEffect(() => {
        GetPromotionTypes();
        getPromotions();
    }, []);

    const getPromotions = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        let promotionsResponse: DefaultPromotion[] | null = [];
        await customPromotions.GetAllOrderedByPriority({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (promotionsResponse !== null) {
                        promotionsResponse = [...promotionsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        setPromotions(promotionsResponse);
    };


    const onDragEnd = async ({ destination, source }: DropResult) => {
        if (!destination) return;

        if (promotions === undefined) return;

        const reorderedPromotions = reorder(promotions, source.index, destination.index);

        setPromotions(reorderedPromotions);

    };

    const AutomaticCheckboxOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutomaticChecked(event.target.checked);
    };

    const AllowOverlapCheckboxOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllowOverlapChecked(event.target.checked);
    };

    const EnabledCheckboxOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabledChecked(event.target.checked);
    };

    const SavePriorityButtonOnClick = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        if (promotions !== undefined)
            customPromotions.EditPromotionsPriority({
                token: `Bearer ${token}`,
                data: { promotions: promotions }
            })
                .then((response) => {
                    getPromotions();
                })
                .catch((error) => {
                    console.log(error);
                });
    };

    return (
        <Container>
            <ConfirmCancelDialogComponent
                IsOpen={showConfirmCancelDialog}
                Title={confirmCancelDialogProps.Title}
                MessageDialog={confirmCancelDialogProps.MessageDialog}
                CloseDialog={confirmCancelDialogProps.CloseDialog}
                ConfirmOption={confirmCancelDialogProps.ConfirmOption}
                CancelOption={confirmCancelDialogProps.CancelOption}
            />

            <ModalComponent
                Title={promotionCrudModalProps.title}
                IsOpen={showPromotionCrudModal}
                CloseModal={promotionCrudModalProps.closeModal}
                MaxWidth={promotionCrudModalProps.maxWidth}
                ModalContent={

                    <Box component={"form"} onSubmit={promotionCrudModalProps.isNew ? AddPromotionFormOnSubmit : EditPromotionFormOnSubmit}>
                        <Grid container spacing={1.5}>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={automaticChecked}
                                            onChange={AutomaticCheckboxOnChange}
                                        />} label="Automatic" />

                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={allowOverlapChecked}
                                            onChange={AllowOverlapCheckboxOnChange}
                                        />} label="Allow overlap" />

                                    {
                                        !promotionCrudModalProps.isNew ? <FormControlLabel control={
                                            <Checkbox
                                                checked={enabledChecked}
                                                onChange={EnabledCheckboxOnChange}
                                            />} label="Enabled" /> : <></>
                                    }


                                </Box>
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={promotionData.name}
                                    label="Name"
                                    name="name"
                                    onChange={updatePromotionData}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Apply to</InputLabel>
                                    <Select
                                        value={selectedApplyToType.toString()}
                                        onChange={ApplyToTypeSelectOnChange}
                                        label="Apply to"
                                        inputProps={!promotionCrudModalProps.isNew ? { readOnly: true } : {}}
                                    >
                                        <MenuItem value={""}><em>None</em></MenuItem>
                                        <MenuItem value={"1"}>Menu</MenuItem>
                                        <MenuItem value={"2"}>Category</MenuItem>
                                        <MenuItem value={"3"}>Product</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Promotion type</InputLabel>
                                    <Select
                                        value={selectedPromotionType.toString()}
                                        onChange={PromotionTypeSelectOnChange}
                                        label="Promotion type"
                                        inputProps={!promotionCrudModalProps.isNew ? { readOnly: true } : {}}
                                    >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {
                                            promotionTypes?.map((promotionType) => (
                                                <MenuItem key={promotionType.id} value={promotionType.index}>{promotionType.name}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            {
                                selectedPromotionType.toString() !== "3" ?
                                    <Grid item xs={12}>
                                        <TextField
                                            type={"number"}
                                            value={promotionData.discountValue}
                                            fullWidth
                                            label={`Discount ${selectedPromotionType.toString() === "2" ? "$" : "%"}`}
                                            name="discountValue"
                                            onChange={updatePromotionData}
                                        />
                                    </Grid> :
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                value={promotionData?.amountProductsToPay}
                                                type={"number"}
                                                label="Amount products to pay"
                                                name="amountProductsToPay"
                                                onChange={updatePromotionData}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                value={promotionData?.amountProductsToGet}
                                                type={"number"}
                                                label="Amount products to get"
                                                name="amountProductsToGet"
                                                onChange={updatePromotionData}
                                            />
                                        </Grid>
                                    </>
                            }

                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Starts"
                                        value={promotionStartsDate}
                                        onChange={(newValue) => {
                                            setPromotionStartsDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Ends"
                                        value={promotionEndsDate}
                                        onChange={(newValue) => {
                                            setPromotionEndsDate(newValue);
                                        }}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider >days of the week</Divider>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1}>
                                    <ToggleButtonGroup
                                        fullWidth
                                        value={daysOfTheWeek}
                                        onChange={DaysOfTheWeekToggleButtonOnChange}
                                        aria-label="daysOfTheWeek"
                                    >
                                        <ToggleButton value="1" aria-label="day1">
                                            1
                                        </ToggleButton>
                                        <ToggleButton value="2" aria-label="day2">
                                            2
                                        </ToggleButton>
                                        <ToggleButton value="3" aria-label="day3">
                                            3
                                        </ToggleButton>
                                        <ToggleButton value="4" aria-label="day4">
                                            4
                                        </ToggleButton>
                                        <ToggleButton value="5" aria-label="day5">
                                            5
                                        </ToggleButton>
                                        <ToggleButton value="6" aria-label="day6">
                                            6
                                        </ToggleButton>
                                        <ToggleButton value="7" aria-label="day7">
                                            7
                                        </ToggleButton>
                                    </ToggleButtonGroup>

                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider >Hours of the day</Divider>
                            </Grid>

                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    fullWidth
                                    size="small"
                                    value={hoursOfTheDay}
                                    onChange={HoursOfTheDayToggleButtonOnChange}
                                    aria-label="hoursOfTheDay"
                                >
                                    <ToggleButton value="0" aria-label="hour0">
                                        00
                                    </ToggleButton>
                                    <ToggleButton value="1" aria-label="hour0">
                                        01
                                    </ToggleButton>
                                    <ToggleButton value="2" aria-label="hour0">
                                        02
                                    </ToggleButton>
                                    <ToggleButton value="3" aria-label="hour0">
                                        03
                                    </ToggleButton>
                                    <ToggleButton value="4" aria-label="hour0">
                                        04
                                    </ToggleButton>
                                    <ToggleButton value="5" aria-label="hour0">
                                        05
                                    </ToggleButton>
                                    <ToggleButton value="6" aria-label="hour0">
                                        06
                                    </ToggleButton>
                                    <ToggleButton value="7" aria-label="hour0">
                                        07
                                    </ToggleButton>
                                    <ToggleButton value="8" aria-label="hour0">
                                        08
                                    </ToggleButton>
                                    <ToggleButton value="9" aria-label="hour0">
                                        09
                                    </ToggleButton>
                                    <ToggleButton value="10" aria-label="hour0">
                                        10
                                    </ToggleButton>
                                    <ToggleButton value="11" aria-label="hour0">
                                        11
                                    </ToggleButton>
                                    <ToggleButton value="12" aria-label="hour0">
                                        12
                                    </ToggleButton>
                                    <ToggleButton value="13" aria-label="hour0">
                                        13
                                    </ToggleButton>
                                    <ToggleButton value="14" aria-label="hour0">
                                        14
                                    </ToggleButton>
                                    <ToggleButton value="15" aria-label="hour0">
                                        15
                                    </ToggleButton>
                                    <ToggleButton value="16" aria-label="hour0">
                                        16
                                    </ToggleButton>
                                    <ToggleButton value="17" aria-label="hour0">
                                        17
                                    </ToggleButton>
                                    <ToggleButton value="18" aria-label="hour0">
                                        18
                                    </ToggleButton>
                                    <ToggleButton value="19" aria-label="hour0">
                                        19
                                    </ToggleButton>
                                    <ToggleButton value="20" aria-label="hour0">
                                        20
                                    </ToggleButton>
                                    <ToggleButton value="21" aria-label="hour0">
                                        21
                                    </ToggleButton>
                                    <ToggleButton value="22" aria-label="hour0">
                                        22
                                    </ToggleButton>
                                    <ToggleButton value="23" aria-label="hour0">
                                        23
                                    </ToggleButton>

                                </ToggleButtonGroup>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    type='submit'
                                    variant='contained'
                                >
                                    Save
                                </Button>
                            </Grid>
                            
                        </Grid>
                    </Box>
                }

            />

            <Grid container justifyContent="flex-end">
                <Button onClick={NewPromotionButtonOnClick} ><AddIcon /> New</Button>
            </Grid>

            {
                promotions.length > 0 ?
                    <Grid container justifyContent="flex-end">
                        <Button onClick={SavePriorityButtonOnClick} >Save priority</Button>
                    </Grid> : <></>
            }

            <DragDropContext onDragEnd={onDragEnd} >
                <Box sx={{ mt: 2 }}>
                    <Droppable droppableId="droppable-list">
                        {(provided) => (
                            <Grid container direction={"column"} ref={provided.innerRef} {...provided.droppableProps}>
                                {
                                    promotions?.map((promotion, index) => (
                                        <Draggable key={promotion.id} draggableId={promotion.id + index} index={index}>
                                            {(provided, snapshot) => (
                                                <Grid item
                                                    sx={{ mb: 1 }}
                                                    key={promotion.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <Card sx={snapshot.isDragging ? { background: '#8E64FF' } : {}}>
                                                        <CardContent>
                                                            <Grid container justifyContent="flex-end">
                                                                <Grid item xs={11}>
                                                                    <Typography variant='h5'>{promotion.promotionName}</Typography>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Box display="flex" justifyContent="flex-end">
                                                                        <IconButton onClick={() => { EditPromotionButtonOnClick(promotion) }}><UpdateIcon /></IconButton>
                                                                        <IconButton onClick={() => { DeletePromotionButtonOnClick(promotion) }}><DeleteIcon /></IconButton>
                                                                    </Box>
                                                                </Grid>

                                                            </Grid>
                                                            <Typography
                                                                sx={
                                                                    promotion.priority != (index + 1) ?
                                                                        {
                                                                            color: "#8E64FF"
                                                                        }
                                                                        :
                                                                        {}
                                                                }
                                                            >
                                                                {`Priority: ${promotion.priority}`}
                                                            </Typography>
                                                            <Typography>Apply to {promotion.toApplyId === 1 ? "menu" : promotion.toApplyId === 2 ? "category" : promotion.toApplyId === 3 ? "product" : "undefined"}</Typography>

                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>

                </Box>
            </DragDropContext>
            
        </Container>
    );
};