import { instance } from "./base.api";

const endponit = {
    signIn: "api/Auth/SignIn",
    signUp: "api/Auth/SignUp",
    refreshToken: "api/Auth/RefreshToken",
    ForgotPassword: "api/Auth/ForgotPassword",
    ResetPasswordWithConfirmationCode: "api/Auth/ResetPasswordWithConfirmationCode"
}

interface SignInProps {
    username: string,
    password: string
}

interface SignUpProps {
    username: string
    address: string
    birthdate: string
    email: string
    familyName: string
    name: string
    business: string
    password: string
}

interface ResetPasswordProps {
    password: string;
    confirmationCode: string;
    username: string;
}

export const auth = {
    signIn: function (signInProps: SignInProps) {
        return instance.post(endponit.signIn, signInProps);
    },
    signUp: function (signUp: SignUpProps) {
        return instance.post(endponit.signUp, signUp);
    },
    refreshToken: function ({ token }: { token: string }) {
        return instance.get(endponit.refreshToken, {
            headers: {
                Authorization: token
            }
        });
    },
    ForgotPassword: function (email: string) {
        return instance.post(endponit.ForgotPassword + `/${email}`,);
    },
    ResetPassword: function (data: ResetPasswordProps) {
        return instance.post(endponit.ResetPasswordWithConfirmationCode, JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
};