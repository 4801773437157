import { instance } from "./base.api";

const endponit = {
    Add: "api/Product/Custom/Add",
    GetAll: "api/Product/Custom/GetAllByAccountId",
    Delete: "api/Product/Custom/Delete"
}

interface CustomProductProp{
    productName: string
}

export const customProducts = {
    Add: function ({ token, data }: { token: string, data: CustomProductProp }) {
        return instance.post(endponit.Add, JSON.stringify(data) ,{
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
    GetAll: function ({ token }: { token: string }) {
        return instance.get(endponit.GetAll, {
            headers: {
                Authorization: token
            }
        });
    },
    Delete: function ({ token, id }: { token: string, id: string }) {
        return instance.delete(endponit.Delete + `/${id}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            }
        });
    },
};