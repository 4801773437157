import { Button, Card, CardActions, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ModalComponent } from '../../components/Modal';
import Box from '@mui/material/Box';
import { CreateCustomProductVariantValidate } from '../../utils/formValidation';
import { getAccessToken } from '../../utils/authHelper';
import { customProductVariants } from '../../api/customProductVariant';
import { useNotification } from '../../context/notification.context';
import { DefaultProductVariant } from '../../types/defaultProductVariant';
import { defaultProdcutVariant } from '../../api/defaultProductVariant';
import { ConfirmCancelDialogComponent, ConfirmCancelDialogProps } from '../../components/ConfirmCancelDialog';

interface ProductVariantTabProps {

}

interface ProductVariantData {
    name: string
}

export const ProductVariantTab: React.FC<ProductVariantTabProps> = ({ }) => {
    const { getError, getSuccess } = useNotification();
    const [showCreateProductVariantModal, setShowCreateProductVariantModal] = React.useState<boolean>(false);
    const [productVariants, setProductVariants] = React.useState<DefaultProductVariant[] | undefined>(undefined);
    const [productVariantData, setProductVariantData] = React.useState<ProductVariantData>({
        name: ""
    });
    const [showConfirmCancelDialog, setShowConfirmCancelDialog] = React.useState<boolean>(false);
    const [confirmCancelDialogProps, setconfirmCancelDialogProps] = React.useState<ConfirmCancelDialogProps>(
        {
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        }
    );

    React.useEffect(() => {
        GetProductVariants();
    }, []);

    const GetProductVariants = async () => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        let productVariantsResponse: DefaultProductVariant[] | null = [];

        await defaultProdcutVariant.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (productVariantsResponse !== null) {
                        productVariantsResponse = [...productVariantsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        await customProductVariants.GetAll({ token: `Bearer ${token}` }).
            then((response) => {
                if (response.data.statusCode === 200) {
                    if (productVariantsResponse !== null) {
                        productVariantsResponse = [...productVariantsResponse, ...response.data.data]
                    }
                }
            }).
            catch((error) => {
                console.log(error);
            });

        setProductVariants(productVariantsResponse);
    };

    const CreateProductVarianModalOnClose = () => {
        setShowCreateProductVariantModal(false);
    };

    const ProductVariantDataOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProductVariantData({ ...productVariantData, [e.target.name]: e.target.value });
    };

    const CreateProductVarianFormOnSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
        try {
            e.preventDefault();
            CreateCustomProductVariantValidate.validate(productVariantData).
                then(async () => {
                    const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
                    customProductVariants.Add({
                        token: `Bearer ${token}`,
                        data: {
                            variantName: productVariantData.name.trim()
                        }
                    }).
                        then((response) => {
                            if (response.data.statusCode === 200) {
                                getSuccess("Product variant created successfully.");
                                GetProductVariants();
                                CreateProductVarianModalOnClose();
                            }
                            else {
                                getError("Failed to create product variant. Please try again or contact support.");
                            }
                        }).
                        catch((error) => {
                            getError("Failed to create product variant. Please try again or contact support.");
                        });

                }).
                catch((error) => {
                    getError(error.message);
                });

        }
        catch (error) {
            getError("Failed to create product variant. Please try again or contact support.");
        }
    };

    const DeleteProductVariantButtonOnClick = (productVariant: DefaultProductVariant) => {
        if (!productVariant.isCustom) {
            getError("This product variant can't be deleted");
            return;
        }

        setShowConfirmCancelDialog(true);
        confirmCancelDialogProps.Title = "Delete product variant";
        confirmCancelDialogProps.MessageDialog = "Are you sure you want to delete that product variant?";
        confirmCancelDialogProps.CancelOption = () => { CloseConfirmCancelDialog(); };
        confirmCancelDialogProps.ConfirmOption = () => { DeleteProductVariant(productVariant.id) };

    };

    const DeleteProductVariant = async (id: string) => {
        const token: string | null = await getAccessToken().then((response) => { return response }).catch((error) => { return null });
        customProductVariants.DeleteCustomProductVariant({
            token: `Bearer ${token}`,
            id: id
        })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    getSuccess("Product variant deleted successfully.");
                    GetProductVariants();
                    CloseConfirmCancelDialog();
                }
                else {
                    getError("Failed to delete product variant. Please try again or contact support.");
                }
            })
            .catch((error) => {
                getError("Failed to delete product variant. Please try again or contact support.");
            });

    };

    const CloseConfirmCancelDialog = () => {
        setconfirmCancelDialogProps({
            IsOpen: false,
            Title: "",
            MessageDialog: "",
            CloseDialog: () => { },
            ConfirmOption: () => { },
            CancelOption: () => { },
        });

        setShowConfirmCancelDialog(false);
    };

    return (
        <Container>
            <ConfirmCancelDialogComponent
                IsOpen={showConfirmCancelDialog}
                Title={confirmCancelDialogProps.Title}
                MessageDialog={confirmCancelDialogProps.MessageDialog}
                CloseDialog={confirmCancelDialogProps.CloseDialog}
                ConfirmOption={confirmCancelDialogProps.ConfirmOption}
                CancelOption={confirmCancelDialogProps.CancelOption}
            />

            <ModalComponent
                Title='Create product variant'
                IsOpen={showCreateProductVariantModal}
                CloseModal={CreateProductVarianModalOnClose}
                MaxWidth={"md"}
                ModalContent={
                    <Box component={"form"} onSubmit={CreateProductVarianFormOnSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={"Name"}
                                    name={"name"}
                                    onChange={ProductVariantDataOnChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth variant='contained' type='submit'>Save</Button>
                            </Grid>
                        </Grid>

                    </Box>
                }
            />
            <Grid container justifyContent="flex-end">
                <Button onClick={() => { setShowCreateProductVariantModal(true) }}><AddIcon /> New</Button>
            </Grid>

            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Search"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} direction="row" alignItems="stretch" sx={{ mt: 0.5 }}>
                {
                    productVariants?.map((productVariant) => (
                        <Grid key={productVariant.id} item xs={12} sm={6} md={3} sx={{ display: "flex" }}>
                            <Card sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",

                            }}>
                                <CardContent sx={{ height: "100%" }}>
                                    <Typography sx={{ mb: 1.5 }}>{productVariant.variantName}</Typography>
                                    <Divider />
                                    {productVariant.isCustom ? <Typography>Custom</Typography> : <></>}
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => { DeleteProductVariantButtonOnClick(productVariant) }}
                                                fullWidth
                                                variant="outlined"
                                                size="small"

                                            >
                                                Delete
                                            </Button>
                                        </Grid>

                                    </Grid>

                                </CardActions>

                            </Card>


                        </Grid>
                    ))
                }
            </Grid>
        </Container>
    );
};