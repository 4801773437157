import React from "react";
import { Route, Routes } from "react-router-dom";
import { AccountInformationCompleted } from "./common/AccountInformationCompleted";
import { ProtectedRoute } from "./common/ProtectedRoute";
import { RouterLayout } from "./common/RouterLayout";
import { AccountMemberPage, HomePage, LoginPage, SignUp} from "./pages";
import { AccountPage } from "./pages/Account";
import { DashboardPage } from "./pages/Dashboard";
import { DevicePage } from "./pages/Device";
import { UserMenuPage } from "./pages/UserMenu";

export const AppRouter: React.FC<{}> = () => {
    return (
        <Routes>
            <Route path="/" element={<RouterLayout />} >
                <Route path="/" element={<HomePage />} />
                <Route element={<ProtectedRoute />}>
                    <Route element={<AccountInformationCompleted />}>
                        <Route path="/Dashboard" element={<DashboardPage />} />
                        
                        <Route path="/Device" element={<DevicePage />} />

                        <Route path="/AccountMember" element={<AccountMemberPage />} />

                        <Route path="/Menu" element={<UserMenuPage />} />

                        <Route path="/Account" element={<AccountPage />} />
                    </Route>
                </Route>
            </Route>
            <Route path="/SignIn" element={<LoginPage />} />
            <Route path="/SignUp" element={<SignUp />} />
            {/* <Route path="/LogOut" element={ <div>Hola</div> } /> */}
        </Routes>
    );
};